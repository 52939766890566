import { Card, Divider, useMediaQuery } from '@material-ui/core';
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import {
  Button,
  Switch,
  Typography,
  IconButton,
  ScrollBar,
} from '@thingsw/pitta-design-system';
import clsx from 'clsx';
import _ from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import {
  CAMERA,
  loadNotification,
  updateNotification,
} from '../../features/Camera/slice';
import { PAYMENT } from '../../features/Payment/slice';
import { RootState } from '../../features/store';
import { getPlanFromServiceID } from '../../utils/Service';
import {
  LightColors,
  Webviewer,
  IEmailNotiSettings,
  OnOffType,
  DMC100_MODELS,
  DMC200_MODELS,
  BOXPRO_MODELS,
  LOW_VOLTAGE_MODELS,
  ICameraInfo,
} from '@thingsw/pitta-modules';
import InfoIcon from '@material-ui/icons/Info';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { NotificationSetTimeModal } from './NotificationSetTimeModal';
import { NotificationInfoModal } from './NotificationInfoModal';
import ableExtendBoxPro from '../../utils/ableExtendBoxPro';

const useStyles = makeStyles((theme: Theme) => ({
  cardWrap: {
    borderRadius: 12,
    boxShadow:
      '0px 2px 2px rgba(0, 0, 0, 0.06), 0px 6px 40px rgba(0, 0, 0, 0.05);',
    marginBottom: 15,
    color: (props: any) => (props.darkMode ? '#FFFFFF' : '#1E1E23'),
    backgroundColor: (props: any) => (props.darkMode ? '#1E1E23' : '#FFFFFF'),
  },
  firstCardWrap: {
    marginBottom: 14,
  },
  cardDiv: {
    display: 'flex',
    padding: '12px 0',
    margin: '0 12px',
    justifyContent: (props: PushNotificationSettngPanelProps) =>
      props.app ? 'space-between' : 'flex-start',
    flexDirection: (props: PushNotificationSettngPanelProps) =>
      props.app ? 'row-reverse' : 'row',
    '&.disabled': {
      opacity: 0.35,
      cursor: 'auto !important',
    },
  },
  switch: {
    marginRight: (props: PushNotificationSettngPanelProps) =>
      props.app ? 0 : 19,
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.up(Webviewer.mobile)]: {
      marginRight: 12,
      width: 48,
    },
    '& .MuiSwitch-switchBase': {
      color: (props: any) =>
        props.darkMode ? '#121216 !important' : '#FFFFFF',
    },
  },
  cardDivider: {
    margin: '0 0 16px',
    [theme.breakpoints.up(Webviewer.mobile)]: {
      margin: '0 21px 16px',
    },
  },
  subTitle: {
    margin: '0 21px 8px',
    color: (props: any) => (props.darkMode ? '#0095E0' : '#13131C'),
  },
  saveBtn: {
    marginTop: 16,
  },
  disabledText: {
    opacity: 0.35,
  },
  flex1: {
    flex: 1,
  },
  infoIcon: {
    color: LightColors.primary['3'],
    marginLeft: 8,
    '&:active': {
      color: LightColors.primary['1'],
    },
    '&:hover': {
      cursor: 'pointer',
    },
  },
  cardRow: {
    display: 'flex',
    flexDirection: 'column',
  },
  arrowCardRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },
  arrowIcon: {
    color: LightColors.primary['3'],
  },
  contDiv: {
    position: 'absolute',
    padding: '16px 15px',
    overflow: 'auto',
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
    transition: theme.transitions.create('transform'),
  },
  contentDivider: {
    margin: '3px 20px',
  },
  appOnly: {
    display: (props: PushNotificationSettngPanelProps) =>
      props.app ? 'block' : 'none',
  },
}));

export interface PushNotificationSettngPanelProps {
  back?: boolean;
  app?: boolean;
  currentCam?: ICameraInfo;
  darkMode?: boolean;
}

export const PushNotificationSettngPanel = (
  props: PushNotificationSettngPanelProps
) => {
  const classes = useStyles(props);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { back, app, currentCam, darkMode } = props;

  const theme = useTheme() as Theme;
  const mobile = useMediaQuery(theme.breakpoints.down(Webviewer.mobile));

  const { loading, type, camera, firmwareConfig } = useSelector(
    (state: RootState) => state[CAMERA]
  );
  const phoneSettings = useSelector(
    (state: RootState) => state[CAMERA].notificationSettings?.PhoneSettings
  );
  const phoneSend = useSelector(
    (state: RootState) => state[CAMERA].notificationSettings?.PhoneSend
  );
  const { subscriptionInfo } = useSelector(
    (state: RootState) => state[PAYMENT]
  );

  const [current, setCurrent] = useState<IEmailNotiSettings>();
  const [push, setPush] = useState<OnOffType>('off');
  const [fatigueTime, setFatigueTime] = useState<number | undefined>();
  const [fatigueSend, setFatigueSend] = useState<OnOffType | undefined>();
  const [disableSave, setDisableSave] = useState(true);
  const [infoTitle, setInfoTitle] = useState('');
  const [infoDescription, setInfoDescription] = useState('');
  const [openInfoModal, setOpenInfoModal] = useState(false);
  const [openSetTimeModal, setOpenSetTimeModal] = useState(false);

  const isExtendBoxPro = useMemo(() => {
    return ableExtendBoxPro(camera?.model, camera?.fw_ver);
  }, [camera]);

  const isFree = useMemo(() => {
    if (subscriptionInfo) {
      return (
        getPlanFromServiceID(subscriptionInfo.servicePlanID) === 'Free plan'
      );
    }
    return false;
  }, [subscriptionInfo]);

  const getBoolean = useCallback((onOff?: OnOffType) => {
    return onOff === 'on' ? true : false;
  }, []);

  useEffect(() => {
    dispatch(loadNotification());
  }, [dispatch]);

  // mantis - 10636 뒤로가기 클릭시 저장 되었던 값 적용 (Leehj)
  useEffect(() => {
    if (back && phoneSend && phoneSettings) {
      setCurrent(phoneSettings);
      setPush(phoneSend);
    }
  }, [back, phoneSend, phoneSettings]);

  // mantis - 13586 이전 page 이동 시 팝업 사라짐 (hongcs)
  useEffect(() => {
    if (!back) return;
    setOpenInfoModal(false);
    setOpenSetTimeModal(false);
  }, [back]);

  useEffect(() => {
    if (!isExtendBoxPro) return;

    if (!current?.['ALARM_FATIGUE']) {
      setFatigueTime(135);
      setFatigueSend('off');
      return;
    }
    setFatigueTime(current['ALARM_FATIGUE'].SETTIME || 135);
    setFatigueSend(current['ALARM_FATIGUE'].SEND);
  }, [current, camera, isExtendBoxPro]);

  useEffect(() => {
    const predicts = _.keys(current).map((k) => {
      const key = k as keyof IEmailNotiSettings;
      if (key === 'ALARM_FATIGUE' && phoneSettings?.['ALARM_FATIGUE']) {
        if (
          (phoneSettings?.[key].SEND ?? 'off') === current?.[key].SEND &&
          phoneSettings?.[key].SETTIME === current?.[key].SETTIME
        ) {
          return true;
        } else {
          return false;
        }
      } else {
        return (phoneSettings?.[key] ?? 'off') === current?.[key];
      }
    });
    setDisableSave(_.every(predicts) && phoneSend === push);
  }, [current, phoneSend, phoneSettings, push]);

  const handleChange = useCallback(
    (
      key: keyof IEmailNotiSettings,
      checked: boolean,
      key2?: keyof IEmailNotiSettings
    ) => {
      let newCurrent = {
        ...current,
        [key]: checked ? 'on' : 'off',
      } as IEmailNotiSettings;
      if (key2) {
        newCurrent = {
          ...newCurrent,
          [key2]: checked ? 'on' : 'off',
        };
      }
      setCurrent(newCurrent);
      if (app) {
        dispatch(
          updateNotification({
            setType: 'phone',
            settings: newCurrent,
            fatigueSend,
            fatigueTime,
            send: push,
            // mantis - 11079, app에서 토스트팝업 가운데로 나오도록 수정 (Leehj)
            positionCenter: true,
          })
        );
      }
    },
    [app, current, dispatch, push, fatigueSend, fatigueTime]
  );

  const handleFatigueRiskChange = useCallback(
    (checked: boolean, time?: number) => {
      let newCurrent = {
        ...current,
        ALARM_FATIGUE: checked
          ? { SEND: 'on', SETTIME: time || 135 }
          : { SEND: 'off', SETTIME: time || 135 },
      } as IEmailNotiSettings;
      setCurrent(newCurrent);
      if (app) {
        dispatch(
          updateNotification({
            setType: 'phone',
            settings: newCurrent,
            send: push,
            fatigueSend: checked ? 'on' : 'off',
            fatigueTime: time || fatigueTime,
            positionCenter: true,
          })
        );
      }
    },
    [app, current, dispatch, fatigueTime, push]
  );

  const renderSimpleSwitch = useCallback(
    (
      label: string,
      key: keyof IEmailNotiSettings,
      defaultVal: OnOffType,
      key2?: keyof IEmailNotiSettings
    ) => {
      return (
        <div className={classes.cardDiv}>
          <div className={classes.switch}>
            <Switch
              checked={
                key2
                  ? getBoolean(current?.[key] ?? defaultVal) &&
                    getBoolean(current?.[key2] ?? defaultVal)
                  : getBoolean(current?.[key] ?? defaultVal)
              }
              onChange={(e: any) => {
                handleChange(key, e.target.checked, key2);
              }}
              disabled={!getBoolean(push)}
            />
          </div>
          <Typography
            category='Default'
            variant='Body'
            // 비활성화인 경우 텍스트도 비활성화 (Leehj) 23.01.12
            className={clsx({
              [classes.disabledText]: !getBoolean(push),
              [classes.flex1]: app,
            })}
          >
            {t(label)}
          </Typography>
        </div>
      );
    },
    [
      app,
      classes.cardDiv,
      classes.disabledText,
      classes.flex1,
      classes.switch,
      current,
      getBoolean,
      handleChange,
      push,
      t,
    ]
  );

  const fastiguRiskTime = useMemo(() => {
    if (!fatigueTime) return;
    let time: string | number;
    time =
      Math.floor(fatigueTime / 60) < 10
        ? `0${Math.floor(fatigueTime / 60)}h:`
        : `${Math.floor(fatigueTime / 60)}h:`;
    time =
      fatigueTime % 60 < 10
        ? time + `0${fatigueTime % 60}m`
        : time + `${fatigueTime % 60}m`;
    return time;
  }, [fatigueTime]);

  return (
    <>
      <div
        className={clsx(classes.contDiv, {
          // [classes.menuClose]: subMenu !== 0,
        })}
      >
        <ScrollBar>
          <Card
            variant='outlined'
            className={clsx(classes.cardWrap, classes.firstCardWrap)}
          >
            <div className={classes.cardDiv}>
              <div className={classes.switch}>
                <Switch
                  checked={getBoolean(push ?? 'on')}
                  onChange={(e: any) => {
                    const newPush = e.target.checked ? 'on' : 'off';
                    // mantis - 11059, save버튼 누르면 push notification항목 저장되도록 수정 (Leehj)
                    // mantis - 11079, app은 save버튼이 없으므로 바로 적용되도록 수정 (Leehj)
                    if (current && app) {
                      dispatch(
                        updateNotification({
                          setType: 'phone',
                          settings: current,
                          fatigueSend,
                          fatigueTime,
                          send: newPush,
                          // mantis - 11079, app에서 토스트팝업 가운데로 나오도록 수정 (Leehj)
                          positionCenter: true,
                        })
                      );
                    }

                    setPush(newPush);
                  }}
                />
              </div>
              <Typography
                category='Default'
                variant='BodyBold'
                className={clsx({
                  [classes.flex1]: app,
                })}
              >
                {t('Push Notifications_2')}
              </Typography>
            </div>
          </Card>
          <Card variant='outlined' className={classes.cardWrap}>
            <div className={classes.cardDiv}>
              <div className={classes.switch}>
                <Switch
                  checked={getBoolean(current?.ALARM_MANUAL ?? 'on')}
                  onChange={(e: any) => {
                    handleChange('ALARM_MANUAL', e.target.checked);
                  }}
                  disabled={!getBoolean(push)}
                />
              </div>
              <Typography
                category='Default'
                variant='BodyBold'
                // 비활성화인 경우 텍스트도 비활성화 (Leehj) 23.01.12
                className={clsx({
                  [classes.disabledText]: !getBoolean(push),
                  [classes.flex1]: app,
                })}
              >
                {t('Manual recording')}
              </Typography>
            </div>
          </Card>
          <Divider className={classes.cardDivider} />

          <div className={classes.subTitle}>
            <Typography category='Default' variant='SmallBold'>
              {t('Normal event recording')}
            </Typography>
          </div>

          <Card variant='outlined' className={classes.cardWrap}>
            {/* 디자인 수정요청건 반영 - 운행중, 주차중 어휘로 변경, 띄어쓰기 다음 단어는 필터와 같이 대문자로 변경. (Leehj) */}
            {renderSimpleSwitch('Driving Impact', 'ALARM_EVENT', 'on')}
            {renderSimpleSwitch('Overspeed', 'ALARM_SPEED', 'off')}
            {renderSimpleSwitch('Hard Braking', 'ALARM_HARSHBRAKING', 'off')}
            {renderSimpleSwitch(
              'Hard Acceleration',
              'ALARM_ACCELERATION',
              'off'
            )}
            {renderSimpleSwitch('Hard Cornering', 'ALARM_SHARPTURN', 'off')}
          </Card>

          <Divider className={classes.cardDivider} />

          <div className={classes.subTitle}>
            <Typography category='Default' variant='SmallBold'>
              {t('Parking event recording')}
            </Typography>
          </div>

          <Card variant='outlined' className={classes.cardWrap}>
            {renderSimpleSwitch('Parking impact', 'ALARM_PARK_EVENT', 'on')}
            {renderSimpleSwitch('Motion Detection', 'ALARM_PARK_MOTION', 'off')}
            {renderSimpleSwitch(
              'Parking mode enter_',
              'ALARM_PARK_IN',
              'off',
              'ALARM_PARK_OUT'
            )}
          </Card>

          {/* Geofencing항목은 Fleet Plan사용자에게만 보이는 항목 */}
          {!isFree && (
            <>
              <Divider className={classes.cardDivider} />

              <div className={classes.subTitle}>
                <Typography category='Default' variant='SmallBold'>
                  {t('Geofencing')}
                </Typography>
              </div>
              <Card variant='outlined' className={classes.cardWrap}>
                {renderSimpleSwitch('Entering', 'ALARM_GEOFENCE_ENTER', 'off')}
                {renderSimpleSwitch('Exiting', 'ALARM_GEOFENCE_EXIT', 'off')}
                {renderSimpleSwitch('Passing', 'ALARM_GEOFENCE_PASS', 'off')}
                {isExtendBoxPro &&
                  renderSimpleSwitch(
                    'Speed Alert',
                    'ALARM_GEOFENCE_SPEED',
                    'off'
                  )}
              </Card>
            </>
          )}

          {/* mantis- 10584 시그마모델에서는 DR770X모델만 DMS항목 보이도록 수정 /
          DR770X에만 표시됨 (2023.01.27 sigma DMS적용모델 정책 기준) (Leehj) */}
          {/* 코드 단순화 수정 (2024.04.30, hongcs) */}
          {_.includes(
            [...DMC100_MODELS, ...DMC200_MODELS, ...BOXPRO_MODELS],
            camera?.model
          ) &&
            firmwareConfig?.['DMS'] && (
              <>
                <Divider className={classes.cardDivider} />

                <div className={classes.subTitle}>
                  <Typography category='Default' variant='SmallBold'>
                    {t('Driver Monitoring System (DMS)')}
                  </Typography>
                </div>

                <Card variant='outlined' className={classes.cardWrap}>
                  {renderSimpleSwitch('Drowsiness', 'ALARM_DROWSY', 'on')}
                  {_.includes(
                    [...DMC200_MODELS, ...BOXPRO_MODELS],
                    camera?.model
                  ) && (
                    <>
                      <div className={classes.cardDiv}>
                        <div className={classes.switch}>
                          <Switch
                            checked={getBoolean(
                              current?.['ALARM_DROWSY_SECOND'] ?? 'off'
                            )}
                            onChange={(e: any) => {
                              handleChange(
                                'ALARM_DROWSY_SECOND',
                                e.target.checked
                              );
                            }}
                            disabled={!getBoolean(push)}
                          />
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <Typography
                            category='Default'
                            variant='Body'
                            className={clsx({
                              [classes.disabledText]: !getBoolean(push),
                              [classes.flex1]: app,
                            })}
                          >
                            {t('Second Drowsiness')}
                          </Typography>
                          <InfoIcon
                            className={classes.infoIcon}
                            onClick={(e) => {
                              e.stopPropagation();
                              setInfoTitle(t('Second Drowsiness'));
                              setInfoDescription(
                                t('Sends an alert when drowsiness is_')
                              );
                              setOpenInfoModal(true);
                            }}
                          />
                        </div>
                      </div>
                    </>
                  )}
                  {renderSimpleSwitch('Distracted', 'ALARM_DISTRACTED', 'on')}
                  {_.includes(BOXPRO_MODELS, camera?.model) &&
                    renderSimpleSwitch('Seat Belt', 'ALARM_SEATBELT', 'on')}
                  {renderSimpleSwitch('Detected', 'ALARM_DETECTED', 'on')}
                  {renderSimpleSwitch('Undetected', 'ALARM_UNDETECTED', 'on')}
                  {_.includes(DMC200_MODELS, camera?.model) &&
                    renderSimpleSwitch(
                      'Hand Distraction',
                      'ALARM_CALLING',
                      'off',
                      'ALARM_SMOKING'
                    )}
                  {_.includes(DMC200_MODELS, camera?.model) &&
                    renderSimpleSwitch('Mask', 'ALARM_MASK_OFF', 'off')}
                </Card>
              </>
            )}

          <Divider className={classes.cardDivider} />

          <div className={classes.subTitle}>
            <Typography category='Default' variant='SmallBold'>
              {t('Others')}
            </Typography>
          </div>

          <Card variant='outlined' className={classes.cardWrap}>
            {_.includes(LOW_VOLTAGE_MODELS, camera?.model) &&
              // mantis - 10258  Low voltage warning 항목 문구 수정 (Leehj)
              renderSimpleSwitch(
                'Low voltage warning',
                'ALARM_LOW_VOLTAGE',
                'on'
              )}
            {renderSimpleSwitch(
              'Cloud Connectivity',
              'DEVICE_CONNECT',
              'off',
              'DEVICE_DISCONNECT'
            )}
            {renderSimpleSwitch('Cloud Storage', 'ALARM_CLOUDSTORAGE', 'on')}
          </Card>

          {currentCam &&
            currentCam.battery &&
            currentCam?.battery.ssid.startsWith('130X') && (
              <>
                <Divider className={classes.cardDivider} />
                {/* 
                <div className={classes.subTitle}>
                  <Typography category="Default" variant="SmallBold">
                    {t("Battery Cloud")}
                  </Typography>
                </div> */}

                <Card variant='outlined' className={classes.cardWrap}>
                  {renderSimpleSwitch(
                    'Battery Capacity',
                    'ALARM_BATTERY_DISCHARGE',
                    'off'
                  )}
                </Card>
              </>
            )}

          {isExtendBoxPro && (
            <>
              <Divider className={classes.cardDivider} />
              <Card
                variant='outlined'
                className={classes.cardWrap}
                style={{ marginBottom: 0 }}
              >
                <div className={classes.cardDiv}>
                  <div className={classes.switch}>
                    <Switch
                      checked={getBoolean(
                        current?.ALARM_FATIGUE?.SEND ?? 'off'
                      )}
                      onChange={(e: any) => {
                        handleFatigueRiskChange(
                          e.target.checked,
                          current?.ALARM_FATIGUE?.SETTIME
                        );
                      }}
                      disabled={!getBoolean(push)}
                    />
                  </div>
                  <div className={classes.arrowCardRow}>
                    <div className={classes.cardRow}>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Typography
                          category='Default'
                          variant='Body'
                          className={clsx({
                            [classes.disabledText]: !getBoolean(push),
                            [classes.flex1]: app,
                          })}
                        >
                          {t('Fatigue Risk Alert')}
                        </Typography>
                        <InfoIcon
                          className={classes.infoIcon}
                          onClick={(e) => {
                            e.stopPropagation();
                            setInfoTitle(t('Fatigue Risk Alert'));
                            setInfoDescription(t('Provides a voice alert_'));
                            setOpenInfoModal(true);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className={clsx(classes.cardDiv, {
                    disabled:
                      !getBoolean(current?.ALARM_FATIGUE?.SEND ?? 'off') ||
                      !getBoolean(push),
                  })}
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    if (
                      !getBoolean(current?.ALARM_FATIGUE?.SEND ?? 'off') ||
                      !getBoolean(push)
                    )
                      return;
                    setOpenSetTimeModal(true);
                  }}
                >
                  <div className={classes.arrowCardRow}>
                    <div className={classes.cardRow}>
                      <Typography
                        category='Default'
                        variant='Body'
                        className={clsx({
                          [classes.flex1]: app,
                        })}
                      >
                        {t('Set Time')}
                      </Typography>
                      {fastiguRiskTime && (
                        <Typography
                          category='Default'
                          variant='Small'
                          htmlColor={LightColors.primary['7']}
                        >
                          {fastiguRiskTime}
                        </Typography>
                      )}
                    </div>
                    <IconButton>
                      <ChevronRightIcon className={classes.arrowIcon} />
                    </IconButton>
                  </div>
                </div>
              </Card>

              <div className={classes.appOnly} style={{ padding: 8 }}>
                <Typography
                  category='Default'
                  variant='Small'
                  htmlColor={LightColors.primary['3']}
                >
                  {t('The time is synchronized_Email')}
                </Typography>
              </div>
            </>
          )}

          {!app && (
            <Button
              className={classes.saveBtn}
              color='primary'
              disabled={disableSave}
              loading={
                loading &&
                (type === loadNotification.type ||
                  type === updateNotification.type)
              }
              onClick={() => {
                if (current) {
                  dispatch(
                    updateNotification({
                      setType: 'phone',
                      settings: current,
                      send: push,
                      fatigueSend,
                      fatigueTime,
                      // mantis - 11079, app과 동일하게 모바일에서도 토스트팝업 가운데로 나오도록 수정 (Leehj)
                      positionCenter: mobile,
                    })
                  );
                }
              }}
            >
              {t('Save')}
            </Button>
          )}
        </ScrollBar>
      </div>

      <NotificationSetTimeModal
        open={openSetTimeModal}
        onClose={() => setOpenSetTimeModal(false)}
        app={app}
        fatigueTime={current?.ALARM_FATIGUE?.SETTIME}
        setFatigueTime={(min: number) => {
          handleFatigueRiskChange(
            getBoolean(current?.ALARM_FATIGUE?.SEND ?? 'off'),
            min
          );
        }}
        type={'push'}
        darkMode={darkMode}
      />

      <NotificationInfoModal
        open={openInfoModal}
        onClose={() => setOpenInfoModal(false)}
        title={infoTitle}
        description={infoDescription}
        darkMode={darkMode}
      />
    </>
  );
};
