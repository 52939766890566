import React, {
  Dispatch,
  MouseEvent,
  SetStateAction,
  useMemo,
  useState,
} from 'react';
import {
  BatteryConnect,
  BatteryError,
  BatteryAdd,
  BatteryDisconnect,
  Typography,
  IconButton,
  DashcamEventHandler,
} from '@thingsw/pitta-design-system';
import {
  BatteryManualCommand,
  DarkColors,
  ICameraInfo,
} from '@thingsw/pitta-modules';
import DownLoadAppAddBatteryModal from '../modals/DownLoadAppAddBatteryModal';
import {
  loadBatteryManual,
  updateBatteryInfo,
} from '../../features/Battery/slice';
import { RootState } from '../../features/store';
import { useDispatch, useSelector } from 'react-redux';
import useCalculateBatteryPercent from '../../hooks/useCalculateBatteryPercent';
import { THEME } from '../../features/Theme/slice';
import useAbleBatteryCloud from '../../hooks/useAbleBatteryCloud';

interface BatteryStatusProps {
  camera: ICameraInfo;
  setPanelState: Dispatch<SetStateAction<'battery' | 'list' | 'setting'>>;
  onClick: DashcamEventHandler | undefined;
  isLottie?: boolean;
  batteryState?: string;
  batteryPercent?: number;
}

export const BatteryStatusButton = (props: BatteryStatusProps) => {
  const {
    camera,
    setPanelState,
    onClick,
    isLottie,
    batteryState,
    batteryPercent,
  } = props;
  const battery = camera?.battery;
  const dispatch = useDispatch();
  const { batteryInfo } = useSelector((state: RootState) => state.battery);
  const { colors } = useSelector((root: RootState) => root[THEME]);

  const isAbleBatteryCloud = useAbleBatteryCloud(camera);

  const calculateBatteryPercent = useCalculateBatteryPercent({
    battery,
    batteryInfo,
  });

  const [openDownloadModal, setOpenDownloadModal] = useState<boolean>(false);

  // 배터리 클라우드 모델이 아니면서, 배터리 정보가 있는 경우 앱-배터리 페어링 상태
  const isAppPairedBattery = useMemo(() => {
    return !isAbleBatteryCloud && camera.battery;
  }, [camera.battery, isAbleBatteryCloud]);

  const handleOpenDownloadModal = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setOpenDownloadModal(true);
  };

  const handleChangeBetteryPanel = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();

    dispatch(
      updateBatteryInfo({
        percent: calculateBatteryPercent,
        temperature: camera.battery.celsius,
      })
    );

    dispatch(
      loadBatteryManual({
        camera: camera,
        command: BatteryManualCommand.START,
      })
    );
    onClick?.(camera);
    setPanelState('battery');
  };

  const state = useMemo(() => {
    // 앱-배터리 페어링 상태에서 앱으로부터 전달 받은 배터리 정보가 없는 경우
    // lateup2 상태로 아이콘을 표시한다.
    if (isAppPairedBattery && !batteryPercent && !batteryState) {
      return 'lateup2';
    }

    return batteryState || battery?.state;
  }, [battery?.state, batteryPercent, batteryState, isAppPairedBattery]);

  const percent = useMemo(() => {
    return batteryPercent || calculateBatteryPercent;
  }, [batteryPercent, calculateBatteryPercent]);

  const batteryStatusMarkup = () => {
    // 배터리 등록 전 | 카메라 클라우드 연결 X, 카메라 연결 기록 없음, 정상 연결 카메라
    if (
      !state &&
      (!battery ||
        camera.reg_category === 'wifi' ||
        (!camera.login_date && !battery))
    ) {
      return (
        <IconButton onClick={handleOpenDownloadModal}>
          <BatteryAdd htmlColor='white' />
          <Typography
            category='Default'
            variant='Caption'
            style={{
              marginLeft: 4,
              height: 16,
            }}
            htmlColor={colors.primary['3']}
          >
            Battery
          </Typography>
        </IconButton>
      );
    }

    // 배터리 연결 완료, 데이터 수신 내역 없음 (초기 연결)
    if (state === 'pending' && camera.active === 'on') {
      return (
        <IconButton onClick={handleChangeBetteryPanel}>
          {isLottie ? (
            <BatteryDisconnect />
          ) : (
            <svg
              width='24'
              height='24'
              viewBox='0 0 24 24'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M3.87378 10.555C3.87378 9.63479 4.61892 8.88843 5.53909 8.88693L11.1609 8.87773C12.0832 8.87622 12.8316 9.62347 12.8316 10.5458V13.4597C12.8316 14.3799 12.0865 15.1262 11.1663 15.1277L5.54454 15.1369C4.62225 15.1384 3.87378 14.3912 3.87378 13.4689V10.555Z'
                fill={DarkColors.primary['3']}
              />
              <rect
                x='2.66543'
                y='7.66665'
                width='16.9991'
                height='8.6667'
                rx='2.6666'
                stroke={DarkColors.primary['3']}
                strokeWidth='1.3333'
              />
              <path
                d='M22.4529 12.1676C22.4529 13.1308 22.0355 13.5373 21.6831 13.7089C21.4349 13.8299 21.203 13.6105 21.203 13.3343V11.001C21.203 10.7248 21.4349 10.5054 21.6831 10.6263C22.0355 10.798 22.4529 11.2045 22.4529 12.1676Z'
                fill={DarkColors.primary['3']}
              />
            </svg>
          )}

          <img
            style={{
              width: 34,
              height: 24,
              marginLeft: 4,
            }}
            src={
              isLottie
                ? '/images/Loading-battery-dark.gif'
                : '/images/Loading-battery.gif'
            }
            alt='lottie'
          />
        </IconButton>
      );
    }

    // 배터리 정상 연결
    switch (state) {
      case 'latest':
        return (
          <IconButton onClick={handleChangeBetteryPanel}>
            <BatteryConnect percent={percent} />
            <Typography
              category='Default'
              variant='Caption'
              style={{
                marginLeft: 4,
                height: 12,
                lineHeight: 1,
              }}
              htmlColor={colors.primary['8']}
            >
              {percent}%
            </Typography>
          </IconButton>
        );
      // 10분 이상 업데이트 없음
      case 'lateup1':
        return (
          <IconButton onClick={handleChangeBetteryPanel}>
            <BatteryError htmlColor='white' />
            <Typography
              category='Default'
              variant='Caption'
              style={{
                marginLeft: 4,
                height: 12,
              }}
              htmlColor={colors.primary['3']}
            >
              {percent}%
            </Typography>
          </IconButton>
        );
      // 30분 이상 업데이트 없음
      case 'lateup2':
        return (
          <IconButton onClick={handleChangeBetteryPanel}>
            <BatteryError htmlColor='white' />
            <Typography
              category='Default'
              variant='Caption'
              style={{
                marginLeft: 4,
                height: 12,
              }}
              htmlColor={colors.primary['3']}
            >
              --%
            </Typography>
          </IconButton>
        );
      default:
        return <></>;
    }
  };

  return (
    <>
      {batteryStatusMarkup()}

      <DownLoadAppAddBatteryModal
        openDownloadModal={openDownloadModal}
        setOpenDownloadModal={setOpenDownloadModal}
      />
    </>
  );
};
