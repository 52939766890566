import { Link, useMediaQuery } from "@material-ui/core";
import { makeStyles, Theme, useTheme } from "@material-ui/core/styles";
import { Modal, Typography } from "@thingsw/pitta-design-system";
import {
  Webviewer,
  TimeZoneList,
  SERVER_MAINTENANCE_NOTICE_URI,
} from "@thingsw/pitta-modules";
import clsx from "clsx";
import moment from "moment";
import React, { useMemo } from "react";
import { Trans, useTranslation } from "react-i18next";
import { THEME } from "../../features/Theme/slice";
import { useSelector } from "react-redux";
import { RootState } from "../../features/store";

const useStyles = makeStyles((theme: Theme) => ({
  modalDiv: {
    maxWidth: 500,
  },
  modalAppDiv: {
    border: "none",
    maxWidth: 500,
    "& > :first-child": {
      display: "none",
    },
  },
  modalContentDiv: {
    padding: "56px 16px 0",
    textAlign: "center",
    alignSelf: "center",
    border: "none",
    [theme.breakpoints.up("md")]: {
      padding: "40px 24px 22px",
      alignSelf: "normal",
    },
  },

  contentCard: {
    width: "100%",
    maxWidth: 454,
    maxHeight: 205,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    borderRadius: 12,
    padding: "24px 0 24px",
    marginBottom: 16,
  },

  contentTop: {
    margin: "16px 0px 24px",
    [theme.breakpoints.up("sm")]: {
      margin: "16px 0px 22px",
    },
  },

  contentBottom: {
    padding: "0px 26px 6px",

    [theme.breakpoints.up("sm")]: {
      padding: "0px 64px 6px",
    },
    [theme.breakpoints.up("md")]: {
      padding: "0px 80px 6px",
    },
  },

  link: {
    cursor: "pointer",
    color: (props: any) => props.colors.primary["7"],
    "&:hover": {
      color: (props: any) => props.colors.primary["8"],
    },
    "&:visited": {
      color: (props: any) => props.colors.primary["7"],
    },
  },

  modalBtn: {
    padding: "14px 14px 16px",
    [theme.breakpoints.up("md")]: {
      alignSelf: "center",
      padding: "0px 22px 24px 26px",
    },
    "&>div": {
      display: "flex",
    },
  },
  lBtn: {
    color: (props: any) => props.colors.primary["1"],
    backgroundColor: "#323236",
    borderColor: "#636367",
  },
}));

// Date 미 지정시 피그마 날짜로 지정됨.
// StartTime은 점검 시작 시간, EndTime은 점검 끝나는 시간
interface MaintenanceNoticeProps {
  start: moment.Moment;
  end: moment.Moment;
  open: boolean;
  url?: string;
  close?: boolean;
  app?: boolean;
  onClose: () => void;
  onRemindClose: () => void;
  onOpenLink?: ({ link }: { link: string }) => void;
}

export const MaintenanceNoticeModal = (props: MaintenanceNoticeProps) => {
  const {
    start,
    end,
    close,
    app,
    onClose,
    onOpenLink,
    open,
    onRemindClose,
  } = props;
  const theme = useTheme() as Theme;
  const { t } = useTranslation();
  const { colors, color } = useSelector((state: RootState) => state[THEME]);
  const classes = useStyles({ ...props, colors });

  const mobile = useMediaQuery(theme.breakpoints.down(Webviewer.mobile));
  const tz = useMemo(() => moment.tz.guess(), []);

  const dateMarkup = useMemo(() => {
    const s = moment(start).tz(tz);
    const e = moment(end).tz(tz);
    if (s.isSame(e, "D")) {
      return (
        <Typography
          category="Default"
          variant="BodyBold"
          htmlColor={colors.primary["1"]}
        >
          {moment(start).tz(tz).format("dddd, MMMM D, YYYY")}
        </Typography>
      );
    } else {
      return (
        <>
          <Typography
            category="Default"
            variant="BodyBold"
            htmlColor={colors.primary["1"]}
          >
            {moment(start).tz(tz).format("dddd, MMMM D, YYYY")}
          </Typography>
          <br />
          <Typography
            category="Default"
            variant="BodyBold"
            htmlColor={colors.primary["1"]}
          >
            - {moment(end).tz(tz).format("dddd, MMMM D, YYYY")}
          </Typography>
        </>
      );
    }
  }, [colors.primary, end, start, tz]);

  return (
    <Modal
      className={clsx({
        [classes.modalDiv]: !app,
        [classes.modalAppDiv]: app,
      })}
      contentClassName={classes.modalContentDiv}
      open={open}
      fullWidthBtn={app}
      onClose={onClose}
      content={
        <div>
          <div>
            <Typography
              category="Default"
              variant="H3"
              htmlColor={colors.primary["1"]}
            >
              {t("Scheduled maintenance Notice")}
            </Typography>
          </div>
          <div className={classes.contentTop}>
            <div>
              <Typography
                category="Default"
                variant="Body"
                htmlColor={colors.primary["1"]}
              >
                {t("Our Cloud server_")}
              </Typography>
            </div>
            <div>
              <Typography
                category="Default"
                variant="Body"
                htmlColor={colors.primary["1"]}
              >
                {t("During that time_")}
              </Typography>
            </div>
          </div>
          <div
            className={classes.contentCard}
            style={{
              backgroundColor:
                color === "light"
                  ? "rgba(231, 245, 252, 1)"
                  : "rgba(55, 183, 255, 0.15)",
            }}
          >
            <div>
              <Typography
                category="Default"
                variant="Body"
                htmlColor={colors.primary["7"]}
              >
                {t("Expected maintenance start_")}
              </Typography>
            </div>
            <div style={{ margin: "12px 0 16px" }}>
              <div>{dateMarkup}</div>
              <div style={{ padding: "6px 0px 2px" }}>
                <Typography
                  category="Default"
                  variant="H3"
                  htmlColor={colors.primary["1"]}
                >
                  {moment(start).tz(tz).format("HH:mm A")} -{" "}
                  {moment(end).tz(tz).format("HH:mm A")}
                </Typography>
              </div>
              <div>
                <Typography
                  category="Default"
                  variant="Caption"
                  htmlColor={colors.primary["3"]}
                >
                  {TimeZoneList[moment.tz(tz).zoneAbbr()]} (
                  {moment.tz(tz).zoneAbbr()})
                </Typography>
              </div>
            </div>
          </div>
          <div>
            <div className={classes.contentBottom}>
              <Typography
                category="Default"
                variant="Body"
                htmlColor={colors.primary["1"]}
              >
                <Trans
                  t={t}
                  components={{
                    a: (
                      <Link
                        href={SERVER_MAINTENANCE_NOTICE_URI}
                        target="_blank"
                        onClick={() =>
                          app &&
                          onOpenLink?.({
                            link: SERVER_MAINTENANCE_NOTICE_URI,
                          })
                        }
                        className={classes.link}
                      ></Link>
                    ),
                  }}
                >
                  For more information_
                </Trans>
              </Typography>
            </div>
          </div>
        </div>
      }
      heading=""
      close={close !== false}
      fullSize={mobile}
      actionClassName={classes.modalBtn}
      RButton={t("OK")}
      LButtonClassName={color === "dark" ? classes.lBtn : ""}
      onClickPositive={onClose}
      onClickNegative={onRemindClose}
      LButton={t("Do not remind_")}
    />
  );
};
