import {
  actionChannel,
  call,
  cancel,
  cancelled,
  fork,
  put,
  select,
  take,
  takeLatest,
} from 'redux-saga/effects';
import Stomp from 'stompjs';
import { eventChannel } from 'redux-saga';
import { RootState } from '../store';
import { setError, USER } from '../User/slice';
import {
  connectWebPush,
  disconnectWebPush,
  loadPushToken,
  loadStoredEvent,
  PUSH_EVENT,
  setNewEvent,
  successLoadPushToken,
  updateEvent,
} from './slice';

import * as Api from '../../apis';
import { updateCameras } from '../Camera/slice';
import moment from 'moment';
import _ from 'lodash';
import { WS_SERVER_URI, RESULT_CODE } from '@thingsw/pitta-modules';

interface WebPushClients {
  ws: WebSocket;
  client: Stomp.Client;
}

const ON_CLOSE = 'onClose';

function createWebSocketConnection(email: string, mobileToken: string) {
  return new Promise<WebPushClients>((resolve, reject) => {
    const ws = new WebSocket(WS_SERVER_URI);

    const client = Stomp.over(ws);
    client.heartbeat.incoming = 10000;
    client.heartbeat.outgoing = 10000;
    client.connect(
      email,
      mobileToken,
      //connect
      () => {
        resolve({ ws, client });
      },
      //error
      (err) => {
        console.log('Web socket error', err);
        put(setError((err as Stomp.Frame).body));
        reject(err);
      },
      '/'
    );

    ws.onerror = function (evt) {
      console.log('Web socket onerror', evt);
      reject(evt);
    };
  });
}

function createSocketChannel(email: string, wpc: WebPushClients) {
  return eventChannel((emit) => {
    const subscription = wpc.client.subscribe('/queue/' + email, (msg) => {
      console.log('createSocketChannel', 'subscribe');
      emit(msg);
    });

    wpc.ws.onclose = () => {
      console.log('createSocketChannel', 'onclose');
      emit({ status: ON_CLOSE });
    };

    return () => {
      console.log('createSocketChannel', 'unsubscribe');
      wpc.ws.onmessage = null;
      subscription.unsubscribe();
    };
  });
}

function* handleLoadPushToken() {
  try {
    const { email, mobile_uuid } = yield select(
      (state: RootState) => state[USER]
    );
    const { user_token } = yield select(
      (state: RootState) => state[USER].loginInfo
    );

    const resp = yield call(
      Api.getPushToken,
      email,
      user_token,
      mobile_uuid,
      'eng'
    );

    const {
      resultcode,
      response: { mobile_token },
    } = resp.data as {
      resultcode: RESULT_CODE;
      response: {
        mobile_token: string;
      };
    };

    if (resultcode === 'BC_ERR_OK') {
      // 8776 - 로그인시, 저장되어있는 push event 초기화 (mckim)
      const storedNotifications = JSON.parse(
        localStorage.getItem('pitta-webviewer:notification-history') ?? '{}'
      );
      console.log(
        'PushEvent',
        'handleLoadPushToken',
        storedNotifications,
        email,
        storedNotifications[email]
      );
      yield put(
        loadStoredEvent(
          _.map(storedNotifications[email] ?? [], (e) => ({
            ...e,
            date: moment(e.date),
          }))
        )
      );
      yield put(successLoadPushToken(mobile_token));
      yield put(connectWebPush());
    }
  } catch (err) {}
}

function* handleConnectWebPush() {
  let socket;
  let socketChannel;
  try {
    const { email } = yield select((state: RootState) => state[USER]);
    const { mobileToken } = yield select(
      (state: RootState) => state[PUSH_EVENT]
    );
    socket = yield call(createWebSocketConnection, email, mobileToken);
    socketChannel = yield call(createSocketChannel, email, socket);

    while (true) {
      const msgText = yield take(socketChannel);

      if (msgText.status === ON_CLOSE) {
        yield put(connectWebPush());
      }

      if (msgText.command === 'MESSAGE') {
        const msg = JSON.parse(msgText.body);
        console.log('subscribe', msg);
        if (
          msg.msg_code === 'DEVICE_CONNECT' ||
          msg.msg_code === 'DEVICE_DISCONNECT'
        ) {
          yield put(updateCameras());
        }
        yield put(updateEvent(msg));
        yield put(setNewEvent());
      }
    }
  } catch (err) {
    console.error(err);
  } finally {
    if (yield cancelled()) {
      console.log('handleConnectWebPush', 'cancelled');
      socketChannel?.close();
      socket?.ws.close();
    }
  }
}

function* handleDeregisterAlarm() {
  try {
    const { email, mobile_uuid, tokenType } = yield select(
      (state: RootState) => state[USER]
    );
    const { user_token } = yield select(
      (state: RootState) => state[USER].loginInfo
    );

    yield call(Api.deregisterAlarm, email, user_token, mobile_uuid, tokenType);
  } catch (err) {}
}

export function* watchPushEvent() {
  yield takeLatest(loadPushToken, handleLoadPushToken);
  // yield takeLatest(connectWebPush, handleConnectWebPush);

  const requestChan = yield actionChannel([connectWebPush, disconnectWebPush]);
  let lastTask;
  while (true) {
    const { type } = yield take(requestChan);
    switch (type) {
      case connectWebPush.type:
        if (lastTask) {
          yield cancel(lastTask);
        }
        lastTask = yield fork(handleConnectWebPush);
        break;
      case disconnectWebPush.type:
        console.log('disconnectWebPush', lastTask);
        if (lastTask) {
          yield cancel(lastTask);
        }
        yield call(handleDeregisterAlarm);
        break;
    }
  }
}
