import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { push, replace } from 'connected-react-router';

import {
  confirm,
  create,
  loadUserProfile,
  logout,
  saveEmail,
  successLoadUserProfile,
  successLogin,
  USER,
  userLogin,
  sendResetMail,
  successSignup,
  uploadImage,
  loadProfileImage,
  changeName,
  successChangeName,
  changePasswd,
  successChangePasswd,
  deleteAccountMail,
  failChangePasswd,
  loadUsageInfo,
  successLoadUsageInfo,
  successLoadUserSettings,
  loadUserSettings,
  updateUserSettings,
  linkSocialAccount,
  clearLoading,
  clearUser,
  setError as setUserError,
  successActivate,
  loadUserPermissions,
  successLoadUserPermissions,
  // saveOauthToken,
  // loadSubscription,
  // userSubscription,
  needToConfirm,
  setUserNotification,
  loadUserNotification,
  successLoadUserNotification,
  updateLiveviewUsage,
  setJwtInterceptorId,
  setEventmapJwtInterceptorId,
  setEventmapInterceptorId,
} from './slice';
import * as Api from '../../apis';
import { RootState } from '../store';

import firebase from 'firebase/app';
import 'firebase/auth';
import { clearError, setError } from '../Error/slice';
import { resetCamera } from '../Camera/slice';
import { clearPayment } from '../Payment/slice';
import { clearVOD } from '../VOD/slice';
import { clearSocial } from '../Social/slice';
import { clearReport } from '../Report/slice';
import { clearGroup } from '../Group/slice';
import { clearGPS } from '../GPS/slice';
import { clearGeofence } from '../Geofence/slice';
import { resetEvent } from '../Event/slice';
import { resetMember } from '../Member/slice';
import { PUSH_EVENT, resetPushEvent } from '../PushEvent/slice';
import { closeToast, openToast } from '../Toast/slice';
import moment from 'moment';
import { clearPermissions } from '../Permission/slice';
import { setNeedToChangeLang } from '../Theme/slice';
import _ from 'lodash';
import { detect } from 'detect-browser';
import { eventmapAxiosInst, eventmapJwtAxiosInst } from '../../utils';
import { closeGPSWs } from '../LocationWS/slice';
import {
  IUserLoginInfo,
  jwtAxiosInst,
  ILogin,
  IUserProfile,
  ISendMail,
  ISignup,
  IUserConfirm,
  IUploadImage,
  ILoadProfileImage,
  IChangeName,
  IChangePasswd,
  IUserUsageInfo,
  IUserSettingsOrig,
  IUserSettings,
  ISetNotification,
  ILoadNotification,
  AuthType,
  APP_VERSION,
  DMS_EVENTS,
  IPermissions,
  MOBILE_NAME,
  MobileLang,
  RESULT_CODE,
  TOKEN_TYPE,
} from '@thingsw/pitta-modules';

function* handleLogout({ payload }: PayloadAction<boolean | undefined>) {
  const notifications = yield select(
    (state: RootState) => state[PUSH_EVENT].webPushEvents2
  );
  const {
    email,
    jwtInterceptorId,
    eventmapJwtInterceptorId,
    eventmapInterceptorId,
  } = yield select((state: RootState) => state[USER]);
  const loginInfo = (yield select(
    (state: RootState) => state[USER].loginInfo
  )) as IUserLoginInfo;
  try {
    yield call(Api.logout, email, loginInfo.user_token);
  } catch (err) {
    console.log(err);
  } finally {
    console.log(
      'UserSaga',
      'handleLogout',
      'jwtInterceptorId',
      jwtInterceptorId
    );
    if (jwtInterceptorId !== undefined) {
      jwtAxiosInst.interceptors.request.eject(jwtInterceptorId);
      yield put(setJwtInterceptorId());
    }
    if (eventmapJwtInterceptorId !== undefined) {
      eventmapJwtAxiosInst.interceptors.request.eject(eventmapJwtInterceptorId);
      yield put(setEventmapJwtInterceptorId());
    }
    if (eventmapInterceptorId !== undefined) {
      eventmapAxiosInst.interceptors.request.eject(eventmapInterceptorId);
      yield put(setEventmapInterceptorId());
    }

    // 8776 - 로그아웃시, 열려있는 push event 저장 초기화 (mckim)
    const storedNotifications = JSON.parse(
      localStorage.getItem('pitta-webviewer:notification-history') ?? '{}'
    );
    localStorage.setItem(
      'pitta-webviewer:notification-history',
      JSON.stringify({
        ...storedNotifications,
        [email]: _.filter(notifications, (n) =>
          _.includes(DMS_EVENTS, n.msg_code)
        ),
      })
    );
    localStorage.setItem('PITTA_SESSION_CLEAR', Date.now().toString());
    localStorage.removeItem('PITTA_SESSION_CLEAR');
    yield firebase.auth().signOut();

    console.log('UserSaga', 'handleLogout', 'payload', payload);
    if (payload !== false) {
      console.log('UserSaga', 'handleLogout', 'try to send logout message');
      // webviewer에서 로그인한 경우에는 메인 화면으로
      const browser = detect();
      console.log('UserSaga', 'handleLogout', browser);
      if (browser?.name === 'ios-webview') {
        console.log('UserSaga', 'handleLogout', 'send logout to ios');
        //@ts-ignore
        webkit.messageHandlers.tokenExpired.postMessage({});
      } else if (browser?.name === 'chromium-webview') {
        console.log('UserSaga', 'handleLogout', 'send logout to android');
        //@ts-ignore
        window.Webviewer?.tokenExpired?.();
      } else {
        //mantis - 8463 , push("/")가 clearPermissions()보다 먼저 실행될 수 있도록 위치를 수정합니다.
        console.log('UserSaga', 'handleLogout', 'redirect to root');
        yield put(replace('/'));
      }

      yield put(clearPermissions());
      yield put(resetCamera());
      yield put(clearError());
      yield put(resetEvent());
      yield put(clearGPS());
      yield put(clearGeofence());
      yield put(clearGroup());
      //reset member
      yield put(resetMember());
      yield put(clearPayment());
      //pushevent
      yield put(resetPushEvent());
      yield put(clearReport());
      yield put(clearSocial());
      yield put(clearUser());
      yield put(clearVOD());
      yield put(closeToast());
      yield put(closeGPSWs());
    }
  }
}

function* handleUserLogin({
  payload,
}: PayloadAction<
  ILogin & {
    goToCamera?: boolean;
    remember?: boolean;
    returnTo?: string;
    fleetaPromo?: boolean;
    cameraCnt?: number;
  }
>) {
  try {
    const {
      goToCamera,
      remember,
      returnTo,
      fleetaPromo,
      email: eemail,
      ...restPayload
    } = payload;
    const email = _.toLower(eemail);
    console.log('handleUserLogin', '1');
    const res = yield call(Api.userLogin, { email, ...restPayload });
    console.log('handleUserLogin', '2');
    const { resultcode, message, response } = res.data as {
      resultcode: RESULT_CODE;
      message: string;
      response: IUserLoginInfo;
    };
    console.log('handleUserLogin', '3');
    if (resultcode === 'BC_ERR_OK') {
      yield put(saveEmail(email));
      console.log('handleUserLogin', '4');
      yield put(successLogin(response));
      console.log('handleUserLogin', '5');
      if (remember) {
        localStorage.setItem('pitta:email', email);
      }
      console.log('handleUserLogin', '6');

      if (returnTo) {
        return (window.location.href = `${returnTo}?email=${email}&user_token=${response.user_token}&token_type=web&jwt_token=${response.jsonwebtoken}`);
      }
      console.log('handleUserLogin', '7');

      if (fleetaPromo && response.userType === 'Etc') {
        const fleeta = yield call(Api.checkFleetaPromo, email);
        console.log('handleUserLogin', 'feelta', fleeta.data);
        if (fleeta.data.response) {
          yield put(
            push('/billing', {
              mode: 'fleet',
              fleetaPromo: payload.fleetaPromo,
              cameraCnt: payload.cameraCnt,
            })
          );
          return;
        }

        console.log('handleUserLogin', '8');
      }
      console.log('handleUserLogin', '9', goToCamera);

      if (goToCamera !== false) {
        yield put(push('/cameras'));
      }
      yield put(setNeedToChangeLang(true));
    } else if (resultcode === 'BC_ERR_INVALID_PARAMETER') {
      yield put(setError('Incorrect email or_'));
      yield put(clearLoading());
    } else if (resultcode === 'BC_ERR_AUTHENTICATION') {
      yield put(setError(message));
      yield put(clearLoading());
    } else if (resultcode === 'BC_ERR_NEED_TO_CONFIRM') {
      yield put(push('/signup'));
      yield put(needToConfirm());
      // mantis - 11834, 서버에러인 경우 버튼 로딩 취소하고 다시 클릭할 수 있게 예외처리 (Leehj)
    } else if (resultcode === 'BC_ERR_INTERNAL_ERROR') {
      yield put(clearLoading());
    }
  } catch (err) {
    console.log('handleUserLogin', err.message);
    yield put(clearLoading());
    yield put(push('/Err500'));
  }
}

function* handleLoadProfileImg({
  payload,
}: PayloadAction<'signup' | undefined>) {
  try {
    const { email, loginInfo, tokenType } = yield select(
      (state: RootState) => state[USER]
    );

    const res = yield call(
      Api.getProfile,
      email,
      loginInfo.user_token,
      tokenType
    );
    const { resultcode, response } = res.data as {
      resultcode: RESULT_CODE;
      message: string;
      response: IUserProfile;
    };
    if (resultcode === 'BC_ERR_OK') {
      yield put(successLoadUserProfile(response));
      if (payload === 'signup') {
        if (response.userType) {
          yield put(push('/billing'));
        } else {
          yield put(push('/cameras'));
        }
      }
    } else if (resultcode === 'BC_ERR_AUTHENTICATION') {
      yield put(logout());
    }
  } catch (err) {
    console.log(err.response);
  }
}

function* handleSendPasswdResetMail({ payload }: PayloadAction<ISendMail>) {
  try {
    const { email, lang } = payload;
    const res = yield call(Api.getMailAddress, email, lang);
    const { resultcode, message } = res.data as {
      resultcode: RESULT_CODE;
      message: string;
    };
    if (resultcode === 'BC_ERR_OK') {
      yield put(clearLoading());
      yield put(push('/reset', { sendMail: true }));
    } else if (resultcode === 'BC_ERR_INTERNAL_ERROR') {
      yield put(push('/Err500'));
    } else if (resultcode === 'BC_ERR_INVALID_DATA') {
      yield put(setError('There isn’t an_'));
      yield put(clearLoading());
    } else if (resultcode === 'BC_ERR_NOTBLACKVUELINKAGE') {
      yield put(setError('Please return to_'));
      yield put(clearLoading());
    } else {
      yield put(clearLoading());
      yield put(setError(message));
    }
  } catch (err) {
    console.log(`handleSendPasswdResetMail: ${err}`);
  }
}

function* handleCreate({
  payload,
}: PayloadAction<
  ISignup & {
    fleetaPromo?: boolean;
    cameraCnt?: number;
  }
>) {
  try {
    const { mobile_uuid } = yield select((state: RootState) => state[USER]);
    const res = yield call(Api.createUser, payload);
    const prefLang = localStorage.getItem('pitta-webviewer-pref-lang');
    const lang = prefLang || navigator.language.toLowerCase().substring(0, 2);

    const { resultcode } = res.data as {
      resultcode: RESULT_CODE;
      message: string;
      response: IUserLoginInfo;
    };
    if (resultcode === 'BC_ERR_OK' || resultcode === 'BC_ERR_NEED_TO_CONFIRM') {
      if (payload.inviteToken) {
        yield put(successActivate());
      } else {
        yield put(successSignup());
        if (payload.signupType === 'apple' || payload.signupType === 'google') {
          let goToCamera = true;
          if (payload.signupMode === 'free') {
          } else if (payload.signupMode === 'fleet') {
            goToCamera = false;
          }
          yield put(
            userLogin({
              email: payload.email,
              oauthToken: payload.oauthToken,
              mobile_uuid,
              mobile_name: MOBILE_NAME,
              mobile_os_type: 'macos',
              mobileLang: MobileLang[lang],
              app_ver: APP_VERSION,
              time_interval: moment().utcOffset(),
              tokenType: TOKEN_TYPE,
              loginType: payload.signupType,
              goToCamera,
              fleetaPromo: payload.fleetaPromo,
              cameraCnt: payload.cameraCnt,
            })
          );
        }
      }
    } else if (resultcode === 'BC_ERR_DUPLICATED') {
      yield put(setError('This e-mail address_'));
      yield put(clearLoading());
    } else if (resultcode === 'BC_ERR_UNAVAILABLE') {
      yield put(setError('You have already_'));
      yield put(clearLoading());
    } else if (resultcode === 'BC_ERR_INTERNAL_ERROR') {
      yield put(setError('Internal server error_'));
      yield put(clearLoading());
    }
  } catch (err) {
    // console.log("error", err.response.status);
    // const errorCode = err.response.status;
  } finally {
    yield put(clearLoading());
  }
}

function* handleConfirm({ payload }: PayloadAction<IUserConfirm>) {
  try {
    const res = yield call(Api.userConfirm, payload);
    const { resultcode, response } = res.data as {
      resultcode: RESULT_CODE;
      response: IUserLoginInfo;
    };
    if (resultcode === 'BC_ERR_OK') {
      yield put(successLogin(response));
      if (payload.signupMode === 'fleet') {
        yield put(
          push('/billing', { mode: 'fleet', fleetaPromo: payload.fleetaPromo })
        );
      } else if (payload.signupMode === 'free') {
        yield put(push('/cameras'));
      } else {
        yield put(loadUserProfile('signup'));
      }
    } else if (resultcode === 'BC_ERR_INVALID_DATA') {
      yield put(setUserError('Code you entered_'));
      yield put(clearLoading());
    }
  } catch (err) {
    // console.log("error", err.response.status);
    // const errorCode = err.response.status;
  }
}

function* handleUploadImage({ payload }: PayloadAction<IUploadImage>) {
  try {
    const email = yield select((state: RootState) => state[USER].email);
    const loginInfo = (yield select(
      (state: RootState) => state[USER].loginInfo
    )) as IUserLoginInfo;

    //console.log(email, loginInfo.user_token);
    //console.log(payload.file, payload.fileName);
    const res = yield call(
      Api.uploadImage,
      email,
      loginInfo.user_token,
      'web',
      payload
    );

    const { resultcode, response } = res.data as {
      resultcode: RESULT_CODE;
      message: string;
      response: ILoadProfileImage;
    };
    if (resultcode === 'BC_ERR_OK') {
      yield put(loadProfileImage(response));
      //console.log(response.profilePath);
    } else if (resultcode === 'BC_ERR_AUTHENTICATION') {
      yield put(logout());
    } else {
      yield put(setError('An error occurred_again'));
      yield put(clearLoading());
    }
  } catch (err) {
    console.log(err);
  }
}

function* handleChangeName({ payload }: PayloadAction<IChangeName>) {
  try {
    const email = yield select((state: RootState) => state[USER].email);
    const { user_token } = (yield select(
      (state: RootState) => state[USER].loginInfo
    )) as IUserLoginInfo;
    const res = yield call(Api.changeName, email, user_token, payload);
    const { resultcode } = res.data as {
      resultcode: RESULT_CODE;
      message: string;
    };
    if (resultcode === 'BC_ERR_OK') {
      yield put(successChangeName(payload));
      yield put(openToast({ message: 'Change saved' }));
    } else if (resultcode === 'BC_ERR_AUTHENTICATION') {
      yield put(logout());
    } else {
      yield put(setError('An error occurred_again'));
      yield put(clearLoading());
    }
  } catch (err) {
    console.log(err);
  }
}

function* handleChangePasswd({ payload }: PayloadAction<IChangePasswd>) {
  try {
    const email = yield select((state: RootState) => state[USER].email);
    const res = yield call(
      Api.changePasswd,
      email,
      payload.old_passwd,
      payload.new_passwd
    );
    const { resultcode, response } = res.data as {
      resultcode: RESULT_CODE;
      message: string;
      response: { user_token: string };
    };
    if (resultcode === 'BC_ERR_OK') {
      yield put(successChangePasswd(response.user_token));
      yield put(openToast({ message: 'Change saved' }));
    } else if (resultcode === 'BC_ERR_AUTHENTICATION') {
      yield put(failChangePasswd());
    } else {
      yield put(setError('An error occurred_again'));
      yield put(clearLoading());
    }
  } catch (err) {
    console.log(err);
  }
}

function* handleDeleteAccountMail() {
  try {
    const email = yield select((state: RootState) => state[USER].email);
    const { user_token } = (yield select(
      (state: RootState) => state[USER].loginInfo
    )) as IUserLoginInfo;

    const res = yield call(Api.doDeleteUser, email, user_token);
    const { resultcode } = res.data as {
      resultcode: RESULT_CODE;
      message: string;
      response: string;
    };
    if (resultcode === 'BC_ERR_OK') {
      yield put(logout(false));
      yield put(push('/delete-check'));
    } else if (resultcode === 'BC_ERR_AUTHENTICATION') {
      yield put(logout());
    } else if (resultcode === 'BC_ERR_NEED_TO_CONFIRM') {
      yield put(logout(false));
      yield put(push('/delete-check'));
    } else {
      yield put(setError('An error occurred_again'));
      yield put(clearLoading());
    }
  } catch (err) {
    console.log(err);
  }
}

function* handleUserUsage() {
  const { email, tokenType } = yield select((state: RootState) => state[USER]);
  const loginInfo = (yield select(
    (state: RootState) => state[USER].loginInfo
  )) as IUserLoginInfo;
  try {
    const res = yield call(
      Api.userUsage,
      email,
      loginInfo.user_token,
      tokenType
    );
    const { resultcode, response } = res.data as {
      resultcode: RESULT_CODE;
      message: string;
      response: IUserUsageInfo;
    };

    if (resultcode === 'BC_ERR_OK') {
      yield put(successLoadUsageInfo(response));
    } else if (resultcode === 'BC_ERR_AUTHENTICATION') {
      yield put(logout());
    } else if (resultcode === 'BC_ERR_UNAVAILABLE') {
    } else {
      yield put(setError('An error occurred_again'));
      yield put(clearLoading());
    }
  } catch (err) {
    console.log(err.response);
  }
}

function* handleLoadUserSettings() {
  try {
    const { email, tokenType } = yield select(
      (state: RootState) => state[USER]
    );
    const { user_token } = (yield select(
      (state: RootState) => state[USER].loginInfo
    )) as IUserLoginInfo;
    const res = yield call(Api.getUserSetting, email, user_token, tokenType);
    const { resultcode, response } = res.data as {
      resultcode: RESULT_CODE;
      response: IUserSettingsOrig;
    };
    if (resultcode === 'BC_ERR_OK') {
      yield put(
        successLoadUserSettings({
          mapType: response.mapType.toString(),
          velocityUnit: response.velocityUnit,
        })
      );
    } else if (resultcode === 'BC_ERR_AUTHENTICATION') {
      yield put(logout());
    } else {
      yield put(setError('An error occurred_again'));
      yield put(clearLoading());
    }
  } catch (err) {
    console.error(err);
  }
}

function* handleUpdateUserSettings({ payload }: PayloadAction<IUserSettings>) {
  try {
    const email = yield select((state: RootState) => state[USER].email);
    const { user_token } = (yield select(
      (state: RootState) => state[USER].loginInfo
    )) as IUserLoginInfo;
    const res = yield call(
      Api.updateUserSetting,
      email,
      user_token,
      payload.mapType,
      payload.velocityUnit
    );
    const { resultcode } = res.data as {
      resultcode: RESULT_CODE;
    };
    if (resultcode === 'BC_ERR_OK') {
      yield put(openToast({ message: 'Change saved' }));
      yield put(successLoadUserSettings(payload));
    } else if (resultcode === 'BC_ERR_AUTHENTICATION') {
      yield put(logout());
    } else if (resultcode === 'BC_ERR_INTERNAL_ERROR') {
      yield put(push('/Err500'));
    }
  } catch (err) {
    console.error(err);
  }
}

function* handleSetUserNotification({
  payload,
}: PayloadAction<ISetNotification>) {
  try {
    const { email, mobile_uuid } = yield select(
      (state: RootState) => state[USER]
    );
    const { user_token } = (yield select(
      (state: RootState) => state[USER].loginInfo
    )) as IUserLoginInfo;
    const res = yield call(
      Api.setUserNotification,
      email,
      user_token,
      mobile_uuid,
      payload
    );
    const { resultcode } = res.data as {
      resultcode: RESULT_CODE;
    };
    if (resultcode === 'BC_ERR_OK') {
      yield put(loadUserNotification());
      yield put(openToast({ message: 'Change saved' }));
    } else if (resultcode === 'BC_ERR_AUTHENTICATION') {
      yield put(logout());
    } else if (resultcode === 'BC_ERR_INTERNAL_ERROR') {
      yield put(push('/Err500'));
    }
  } catch (err) {
    console.error(err);
  }
}

function* handleLoadUserNotification() {
  try {
    const { email, mobile_uuid } = yield select(
      (state: RootState) => state[USER]
    );
    const { user_token } = (yield select(
      (state: RootState) => state[USER].loginInfo
    )) as IUserLoginInfo;
    const res = yield call(
      Api.getUserNotification,
      email,
      user_token,
      mobile_uuid
    );
    const { resultcode, response } = res.data as {
      resultcode: RESULT_CODE;
      response: ILoadNotification;
    };
    if (resultcode === 'BC_ERR_OK') {
      yield put(successLoadUserNotification(response));
    } else if (resultcode === 'BC_ERR_AUTHENTICATION') {
      yield put(logout());
    }
  } catch (err) {
    console.error(err);
  }
}

function* handleLinkSocialAccount({
  payload: { email, passwd, oauthToken, signupType },
}: PayloadAction<{
  email: string;
  passwd: string;
  oauthToken: string;
  signupType: AuthType;
}>) {
  try {
    const { mobile_uuid } = yield select((state: RootState) => state[USER]);
    const resp = yield call(
      Api.linkSocialAccount,
      email,
      passwd,
      oauthToken,
      signupType
    );
    const prefLang = localStorage.getItem('pitta-webviewer-pref-lang');
    const lang = prefLang || navigator.language.toLowerCase().substring(0, 2);

    const { resultcode } = resp.data as {
      resultcode: RESULT_CODE;
      response: ILogin;
    };

    if (resultcode === 'BC_ERR_OK' || resultcode === 'BC_ERR_DUPLICATED') {
      yield put(
        userLogin({
          email,
          oauthToken,
          mobile_uuid,
          mobile_name: MOBILE_NAME,
          mobile_os_type: 'macos',
          mobileLang: MobileLang[lang],
          app_ver: APP_VERSION,
          time_interval: moment().utcOffset(),
          tokenType: TOKEN_TYPE,
          loginType: signupType,
        })
      );
    } else if (resultcode === 'BC_ERR_INVALID_DATA') {
      yield put(clearLoading());
      yield put(setError('Password is incorrect'));
    }
  } catch (err) {
    console.error(err);
  }
}

function* handleLoadUserPermissions() {
  try {
    const { email, loginInfo, tokenType, userType } = yield select(
      (state: RootState) => state[USER]
    );
    let resp;

    if (!userType) {
      resp = yield call(
        Api.loadUserPermissions,
        email,
        loginInfo.user_token,
        tokenType
      );
    } else {
      resp = yield call(
        Api.loadUserRole,
        email,
        loginInfo.user_token,
        tokenType
      );
    }

    const { resultcode, response } = resp.data as {
      resultcode: RESULT_CODE;
      response: { permissions: IPermissions };
    };

    if (resultcode === 'BC_ERR_OK') {
      yield put(successLoadUserPermissions(response.permissions));
    }
  } catch (err) {
    console.error(err);
  }
}

function* handleUpdateLiveviewUsage({
  payload,
}: PayloadAction<{ psn: string; usage: number }>) {
  try {
    const { email, tokenType } = yield select(
      (state: RootState) => state[USER]
    );
    const loginInfo = (yield select(
      (state: RootState) => state[USER].loginInfo
    )) as IUserLoginInfo;

    yield call(
      Api.updateLiveviewUsage,
      email,
      loginInfo.user_token,
      payload.psn,
      payload.usage,
      tokenType
    );

    yield put(loadUsageInfo());
  } catch (err) {}
}

export function* watchUser() {
  yield takeLatest(logout, handleLogout);
  yield takeLatest(userLogin, handleUserLogin);
  yield takeLatest(create, handleCreate);
  yield takeLatest(confirm, handleConfirm);
  yield takeLatest(loadUserProfile, handleLoadProfileImg);
  yield takeLatest(sendResetMail, handleSendPasswdResetMail);
  yield takeLatest(uploadImage, handleUploadImage);
  yield takeLatest(changeName, handleChangeName);
  yield takeLatest(changePasswd, handleChangePasswd);
  yield takeLatest(deleteAccountMail, handleDeleteAccountMail);
  yield takeLatest(loadUsageInfo, handleUserUsage);
  yield takeLatest(loadUserSettings, handleLoadUserSettings);
  yield takeLatest(updateUserSettings, handleUpdateUserSettings);
  yield takeLatest(linkSocialAccount, handleLinkSocialAccount);
  yield takeLatest(loadUserPermissions, handleLoadUserPermissions);
  yield takeLatest(setUserNotification, handleSetUserNotification);
  yield takeLatest(loadUserNotification, handleLoadUserNotification);
  yield takeLatest(updateLiveviewUsage, handleUpdateLiveviewUsage);
  // yield takeLatest(loadSubscription, handleUserSubscription);
}
