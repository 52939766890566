import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { makeStyles, Theme, useTheme } from "@material-ui/core/styles";
import {
  DateSelector,
  IconButton,
  Menu,
  MobileMenu,
  Modal,
  Tooltip,
  Typography,
  WebMenuItem,
  Alerts,
  CameraInfoRev,
} from "@thingsw/pitta-design-system";
import moment from "moment";
import clsx from "clsx";
import { useTranslation } from "react-i18next";

import { Doughnut, Pie, Bar } from "react-chartjs-2";

import SaveAltIcon from "@material-ui/icons/SaveAlt";
import InfoIcon from "@material-ui/icons/Info";

import { useDispatch, useSelector } from "react-redux";
import {
  clearReport,
  EventType,
  ITotalLog,
  loadDailyReport,
  loadMonthlyReport,
  REPORT,
  setShownAlert,
} from "../../features/Report/slice";
import { CAMERA } from "../../features/Camera/slice";
import { RootState } from "../../features/store";
import _ from "lodash";
import { downloadCSVFile, durationToHMS } from "../../utils/Report";
import { Selector } from "@thingsw/pitta-design-system/dist/components/Selector";
import Input from "@thingsw/pitta-design-system/dist/components/Input";
import { CameraListPopper } from "../CameraListPopper";

import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";

import ChartDataLabels from "chartjs-plugin-datalabels";
import { loadUserSettings, USER } from "../../features/User/slice";
import { getGPSTrackingData } from "../../apis";
import {
  GPS,
  ITrackInfoOrig,
  loadGPSDriveData,
} from "../../features/GPS/slice";

import useMediaQuery from "@material-ui/core/useMediaQuery";
import { ScreenDefaultProps } from "../../hoc/withViewerTemplate";
import { setError } from "../../features/Error/slice";
import { CircularProgress } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { NoResults } from "../NoResults";
import {
  DMC100_MODELS,
  DMC200_MODELS,
  BOXPRO_MODELS,
  ICameraInfo,
  jwtAxiosInst,
  LightColors,
  MCODE_TO_TEXT,
  PermissionProps,
  PSN650,
  RESULT_CODE,
  Webviewer,
} from "@thingsw/pitta-modules";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: "relative",
  },
  dateDiv: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    padding: theme.spacing(2),
    [theme.breakpoints.up(Webviewer.reportMobileView)]: {
      padding: theme.spacing(3, 4, 0),
    },
  },
  dateDiv2: {
    display: "flex",
    marginBottom: theme.spacing(2),
  },
  selectorDiv: {
    width: "100%",
    [theme.breakpoints.up(Webviewer.reportMobileView)]: {
      width: 202,
    },
  },
  graphRootDiv: {
    display: "flex",
    flexDirection: "column",
  },
  graphRootOpenDiv: {
    [theme.breakpoints.up(Webviewer.reportMobile)]: {
      flexDirection: "row",
    },
  },
  graphRootCloseDiv: {
    [theme.breakpoints.up(Webviewer.reportMobileClose)]: {
      flexDirection: "row",
    },
  },
  graphOuterDiv: {
    display: "flex",
    flexDirection: "column",
  },

  graphOuterOpenDiv: {
    [theme.breakpoints.up(Webviewer.reportMobile)]: {
      "&:first-child": {
        ...(theme.direction === "rtl"
          ? { marginLeft: theme.spacing(2) }
          : { marginRight: theme.spacing(2) }),
      },
      "&:last-child": {
        ...(theme.direction === "rtl"
          ? { marginRight: theme.spacing(2) }
          : { marginLeft: theme.spacing(2) }),
      },
      width: `calc(50% - ${theme.spacing(2)}px)`,
    },
  },

  graphOuterCloseDiv: {
    [theme.breakpoints.up(Webviewer.reportMobileClose)]: {
      "&:first-child": {
        marginRight: theme.spacing(2),
      },
      "&:last-child": {
        marginLeft: theme.spacing(2),
      },
      width: `calc(50% - ${theme.spacing(2)}px)`,
    },
  },
  graphDiv: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    padding: theme.spacing(2, 3),
    border: `1px solid ${LightColors.primary["5"]}`,
    borderRadius: 4,
    flex: 1,
    [theme.breakpoints.up(Webviewer.reportMobileView)]: {
      padding: theme.spacing(3),
    },
  },
  mb4: {
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up(Webviewer.reportMobile)]: {
      marginBottom: theme.spacing(4),
    },
  },
  drivingChartDiv: {
    // mantis-8539
    // 브라우저 zoom out 하였을 때, 도형 위치 중앙정렬 되도록 display, justifyContent 값 추가
    display: "flex",
    justifyContent: "center",
    maxWidth: 322,
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    width: "100%",
    [theme.breakpoints.up(Webviewer.reportMobileView)]: {
      marginTop: theme.spacing(4.875),
      marginBottom: theme.spacing(4),
    },
  },
  // mantis-9111 모바일 차트 타원형 수정
  drivingChart: {
    aspectRatio: 1,
    maxHeight: 322,
    maxWidth: 322,
    [theme.breakpoints.up(Webviewer.reportMobile)]: {
      minHeight: 321,
      minWidth: 321,
    },
  },
  drivingChartTextDiv: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    position: "absolute",
    top: "calc(50% + 10px)",
    transform: "translateY(-50%)",
  },
  drivingChartLabelDiv: {
    // mantis - 8360
    // width를 지정하면 모바일에서 언어변경 시 ui겹침 문제가 발생하므로 -webkit-fill-available로 사용 가능한 모든 공간을 차지하도록 한다.
    width: "-webkit-fill-available",
    display: "flex",
    justifyContent: "space-between",
    marginBottom: theme.spacing(1),
    "&:last-child": {
      marginBottom: 0,
    },
    [theme.breakpoints.up(Webviewer.reportMobileView)]: {
      width: "auto",
      // mantis - 8360 figma에 맞추어 margin값 추가
      ...(theme.direction === "rtl"
        ? { marginLeft: theme.spacing(4) }
        : { marginRight: theme.spacing(4) }),
      marginBottom: 0,
      flexDirection: "column",
    },
  },
  centering: {
    display: "flex",
    justifyContent: "center",
    position: "relative",
  },
  labelDiv: {
    // mantis - 8360 언어변경 시 ui겹침 해결을 위한 width값 제거
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    [theme.breakpoints.up(Webviewer.reportMobileView)]: {
      flexDirection: "row",
      justifyContent: "space-between",
    },
  },
  labelTitleDiv: {
    // mantis - 8360 figma에 맞추어 margin값 추가
    ...(theme.direction === "rtl"
      ? { marginLeft: theme.spacing(2) }
      : { marginRight: theme.spacing(2) }),
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.up(Webviewer.reportMobileView)]: {
      ...(theme.direction === "rtl" ? { marginLeft: 0 } : { marginRight: 0 }),
    },
  },
  labelMarker: {
    width: 10,
    height: 10,
    borderRadius: "50%",

    ...(theme.direction === "rtl"
      ? { marginLeft: theme.spacing(1) }
      : { marginRight: theme.spacing(1) }),
  },
  labelValueDiv: {
    [theme.breakpoints.up(Webviewer.reportMobileView)]: {
      marginTop: theme.spacing(1),
      marginLeft: theme.spacing(2.25),
    },
  },
  pieRootDiv: {
    margin: theme.spacing(3, 0),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    [theme.breakpoints.up(Webviewer.reportMobileView)]: {
      flexDirection: "row",
    },
  },
  pieChartDiv: {
    maxWidth: 322,
    display: "flex",
    justifyContent: "center",
    width: "100%",
    marginBottom: theme.spacing(3),
    [theme.breakpoints.up(Webviewer.reportMobileView)]: {
      width: 181,

      marginBottom: theme.spacing(0),
      ...(theme.direction === "rtl"
        ? { marginLeft: theme.spacing(8) }
        : { marginRight: theme.spacing(8) }),
    },
  },
  pieLabelRootDiv: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    // mantis - 9128, 줄바꿈되는 이슈 수정
    maxWidth: "fit-content",
    minWidth: 205,
    [theme.breakpoints.up(Webviewer.reportMobileView)]: {
      maxWidth: "fit-content",
    },
  },
  pieLabelDiv: {
    display: "flex",
    justifyContent: "space-between",
    margin: theme.spacing(0.5, 0),
    "&:first-child": {
      marginTop: 0,
    },
    "&:last-child": {
      marginBottom: 0,
    },
  },
  disablePieLabel: {
    opacity: 0.35,
  },
  pieLabelTitleDiv: {
    display: "flex",
    alignItems: "center",
    marginRight: theme.spacing(2),
    // mantis - 9128, 줄바꿈되는 이슈 수정
    whiteSpace: "nowrap",
  },
  // mantis - 9128, 줄바꿈되는 이슈 수정
  percent: {
    whiteSpace: "nowrap",
  },
  statTextDiv: {
    marginTop: theme.spacing(3),
    display: "flex",
    justifyContent: "center",

    [theme.breakpoints.up(Webviewer.reportMobileView)]: {
      justifyContent: "flex-end",
    },
  },
  noDataPieDiv: {
    width: 240,
    height: 240,
    borderRadius: "50%",
    backgroundColor: LightColors.primary["6"],
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    // marginBottom: theme.spacing(3),
    [theme.breakpoints.up(Webviewer.reportMobileView)]: {
      width: 181,
      height: 181,
      borderBottom: 0,
    },
  },
  detailBodyDiv: {
    // minWidth: 507,
    display: "flex",
    padding: theme.spacing(2, 0),
    borderBottom: `1px solid ${LightColors.primary["6"]}`,
    "&:last-child": {
      borderBottom: 0,
      paddingBottom: 0,
    },
  },
  detailItemDiv: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    margin: theme.spacing(1, 0, 0),
    "&:first-child": {
      ...(theme.direction === "rtl"
        ? { marginLeft: theme.spacing(1.5) }
        : { marginRight: theme.spacing(1.5) }),
    },
    "&:last-child": {
      ...(theme.direction === "rtl"
        ? { marginRight: theme.spacing(1.5) }
        : { marginLeft: theme.spacing(1.5) }),
    },
    [theme.breakpoints.up(Webviewer.reportMobileView)]: {
      margin: theme.spacing(1, 0, 0),
    },
  },
  detailItem: {
    display: "flex",
    flexDirection: "column",
    margin: theme.spacing(1, 0, 0),
  },
  eventsTrendTitleDiv: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    [theme.breakpoints.up(Webviewer.reportMobileView)]: {
      flexDirection: "row",
      alignItems: "center",
    },
  },
  eventsTrendSelector: {
    width: "100%",
    marginTop: theme.spacing(3),
    [theme.breakpoints.up(Webviewer.reportMobileView)]: {
      width: 187,
      marginTop: theme.spacing(0),
    },
  },
  dateCamDiv: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    [theme.breakpoints.up(Webviewer.reportMobileView)]: {
      flexDirection: "row",
    },
  },
  camTextDiv: {
    width: "100%",
    marginTop: theme.spacing(2),
    [theme.breakpoints.up(Webviewer.reportMobileView)]: {
      margin: theme.spacing(0, 2),
      width: 320,
    },
  },
  printContainer: {
    padding: theme.spacing(2, 2),

    [theme.breakpoints.up(Webviewer.reportMobileView)]: {
      padding: theme.spacing(3, 4),
    },
  },
  downloadBtnDiv: {
    display: "flex",
    justifyContent: "flex-end",
    padding: theme.spacing(2, 2, 0),
    borderTop: `1px solid ${LightColors.primary["6"]}`,
  },
  mobileMemuItem: {
    padding: theme.spacing(1.5, 2),
    [theme.breakpoints.up(Webviewer.reportMobileView)]: {
      padding: theme.spacing(0.75, 2),
    },
  },
  infoIcon: {
    color: LightColors.primary["3"],
    "&:hover, &:active": {
      color: LightColors.primary["1"],
    },
  },
  infoTitleDiv: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  drivingTrendsDiv: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    margin: theme.spacing(7.5, 0),
  },
  loadingDiv: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    height: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(19, 19, 28, 0.45)",
  },
  circularLoading: {
    color: LightColors.primary["8"],
  },

  tooltipReck: {
    display: "flex",
    alignContent: "center",
    flexDirection: "column",
    [theme.breakpoints.up(321)]: {
      flexDirection: "row",
    },
  },
  modalContentWrap: {
    padding: theme.spacing(2.5, 3, 3.25),
    display: "flex",
    flexDirection: "column",
  },
  searchInputDiv: {
    width: "-webkit-fill-available",
    ...(theme.direction === "rtl"
      ? { padding: theme.spacing(0, 0.75, 0, 1.875) }
      : { padding: theme.spacing(0, 1.875, 0, 0.75) }),
    display: "flex",
    alignItems: "center",
    height: 44,
    position: "fixed",
    top: 62,
  },
  camListtDiv: {
    marginTop: 65,
    overflowY: "auto",
    display: "flex",
    height: "calc(100% - 120px)",
    justifyContent: "center",
  },
  cameraListDiv: {
    width: "100%",
    // maxHeight: "50vh",
    overflowY: "auto",
    display: "flex",
    flexDirection: "column",
  },
}));

const INATTENTIVE_COLOR = "rgba(138, 63, 252, 1)";
const SAFE_COLOR = "rgba(51, 177, 255, 1)";
const RACKLESS_COLOR = "rgba(0, 125, 121, 1)";

const CALLING_COLOR = "rgba(138, 63, 252, 1)";
const DISTRACTED_COLOR = "rgba(51, 177, 255, 1)";
const DROWSY_COLOR = "rgba(0, 125, 121, 1)";
const SMOKING_COLOR = "rgba(255, 126, 182, 1)";

const OVERSPEED_COLOR = "rgba(138, 63, 252, 1)";
const HARD_CORNERING_COLOR = "rgba(51, 177, 255, 1)";
const HARD_BRAKING_COLOR = "rgba(0, 125, 121, 1)";
const HARD_ACCELERATION_COLOR = "rgba(255, 126, 182, 1)";

const INATTENTIVE_ORDERS: { [key in string]: number } = {
  Calling: 2,
  Drowsy: 0,
  Distracted: 1,
  Smoking: 3,
};

const RACKLESS_ORDERS: { [key in string]: number } = {
  Overspeed: 2,
  SharpTurn: 3,
  HarshBraking: 1,
  Acceleration: 0,
};

const EVENT_TO_LABEL: { [key in EventType]: string } = {
  Acceleration: "Acceleration",
  Calling: "Hand distraction",
  Distracted: "Distracted",
  Drowsy: "Drowsiness",
  HarshBraking: "Hard braking",
  Overspeed: "Overspeed",
  SharpTurn: "Sharp turn",
  Smoking: "Hand distraction",
};

const MONTH_ABBR: { [key: number]: string } = {
  1: "Jan",
  2: "Feb",
  3: "Mar",
  4: "Apr",
  5: "May",
  6: "June",
  7: "July",
  8: "Aug",
  9: "Sept",
  10: "Oct",
  11: "Nov",
  12: "Dec",
};

type EventStatType = { [key in EventType]: number };

export interface ReportPanelProps {
  selectCam?: boolean;
}

export const ReportPanel = ({
  selectCam,
  openMenu,
  downloadCSVReportPerm,
}: ReportPanelProps & ScreenDefaultProps & PermissionProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const theme = useTheme();
  const reportMobile = useMediaQuery(
    theme.breakpoints.down(Webviewer.reportMobile)
  );
  const reportMobileClose = useMediaQuery(
    theme.breakpoints.down(Webviewer.reportMobileClose)
  );
  const mobileView = useMediaQuery(
    theme.breakpoints.down(Webviewer.reportMobileView)
  );

  const mobile = useMemo(() => reportMobile || reportMobileClose, [
    reportMobile,
    reportMobileClose,
  ]);

  const mobileTooltip = useMediaQuery(theme.breakpoints.down(Webviewer.mobile));

  const reportRef = useRef<HTMLDivElement>(null);

  const { email, loginInfo, userSettings } = useSelector(
    (state: RootState) => state[USER]
  );
  const { driveList } = useSelector((state: RootState) => state[GPS]);
  const cam = useSelector((state: RootState) => state[CAMERA].camera);
  const cameraList = useSelector(
    (state: RootState) => state[CAMERA].cameraList
  );
  const { monthly, daily, monthlyScores, totalLog, loading } = useSelector(
    (state: RootState) => state[REPORT]
  );
  const anchorRef = useRef<HTMLButtonElement>(null);
  const cameraAnchorRef = useRef<HTMLDivElement>(null);

  const [day, setDay] = useState<moment.Moment>(moment());
  const [driveDuration, setDriveDuration] = useState<moment.Duration>(
    moment.duration(0, "m")
  );
  const [open, setOpen] = useState(false);
  const [totalEvents, setTotalEvents] = useState<
    { key: string; value: number }[]
  >([]);
  const [inattentiveEvents, setInattentiveEvents] = useState<EventStatType>();
  const [racklessEvents, setRecklessEvents] = useState<EventStatType>();
  const [drivingDetail, setDrivingDetail] = useState<ITotalLog>();
  const [mode, setMode] = useState<"day" | "month">("day");
  const [shownEvents, setShownEvents] = useState<
    EventType | "All" | "Hand distraction"
  >("All");
  const [camera, setCamera] = useState<ICameraInfo>();
  const [cameraName, setCameraName] = useState("");
  const [openCamera, setOpenCamera] = useState(false);
  const [monthlyMaxNum, setMonthlyMaxNum] = useState<number>();
  const [dailyMaxNum, setDailyMaxNum] = useState<number>();
  const [totalEventCount, setTotalEventCount] = useState<number>(0);
  const [dates, setDates] = useState<string[]>([]);

  // 리포트 있는 날짜 조회기능 추가
  useEffect(() => {
    const getReportDate = async (
      email: string,
      psn: string,
      interval: number
    ) => {
      const resp = await jwtAxiosInst.get(
        `/report/dms/calendar?email=${email}&psn=${psn}&interval=${interval}`
      );
      if (resp.data.resultcode === "BC_ERR_OK") {
        const list = resp.data.response.drive_list;
        const d = _.map(list, (l) => moment.unix(l).format("YYYYMMDD"));
        setDates(d);
      }
    };

    if (email && camera?.psn) {
      getReportDate(email, camera.psn, moment().utcOffset());
    }
  }, [camera?.psn, email]);

  useEffect(() => {
    dispatch(loadUserSettings());
    return () => {
      dispatch(setShownAlert(true));
    };
  }, [dispatch]);

  useEffect(() => {
    const orientationHandler = (e: any) => {
      setOpen(false);
      setOpenCamera(false);
    };
    if ("onorientationchange" in window) {
      window.addEventListener("orientationchange", orientationHandler, false);
    }
    if (!mobile) {
      window.addEventListener("resize", orientationHandler, false);
    }
    return () => {
      if ("onorientationchange" in window) {
        window.removeEventListener("orientationchange", orientationHandler);
      }
      if (!mobile) {
        window.removeEventListener("resize", orientationHandler);
      }
    };
  }, [mobile]);

  useEffect(() => {
    return () => {
      dispatch(clearReport());
    };
  }, [dispatch]);

  useEffect(() => {
    if (cam && !selectCam) {
      const found = _.find(
        cameraList?.deviceListInfo,
        (dev) => dev.device.psn === cam.psn
      )?.device;
      setCamera((c) => {
        if (c?.psn !== found?.psn) {
          return found;
        }
        return c;
      });
    } else {
      const found = _.sortBy(cameraList?.deviceListInfo, (dev) =>
        moment(dev.device.reg_date)
      )
        .reverse()
        .filter(
          (d) =>
            !_.includes(PSN650, d.device.psn.substr(0, 4)) &&
            // report 바로 진입시 default 카메라 선택에서 cloud 카메라만 선택
            d.device.reg_category === "cloud"
        )
        // mantis - 12422, 비활성화된 카메라 노출되지 않도록 적용 (Leehj)
        .filter((dev) => dev.device.valid !== "invalid")[0]?.device;

      if (found) {
        setCamera((c) => {
          if (c?.psn !== found?.psn) {
            return found;
          }
          return c;
        });
      }
    }
  }, [cam, cameraList?.deviceListInfo, selectCam]);

  useEffect(() => {
    if (camera) {
      dispatch(loadGPSDriveData(camera.psn));
      setCameraName(camera.dev_name);
    }
  }, [camera, dispatch]);

  useEffect(() => {
    console.log("ReportPanel", camera);
    if (camera) {
      const startTime = moment(day).utc(true).startOf(mode);
      // const endTime = moment(day).utc(true).endOf(mode);
      if (mode === "month") {
        dispatch(
          loadMonthlyReport({
            psn: camera.psn,
            date: startTime.format("YYYYMMDD"),
            interval: moment().utcOffset(),
            period: "monthly",
          })
        );
      } else {
        dispatch(
          loadDailyReport({
            psn: camera.psn,
            date: startTime.format("YYYYMMDD"),
            interval: moment().utcOffset(),
            period: "daily",
          })
        );
      }
      // setAnimation(false);
    }
  }, [camera, mode, dispatch, day]);

  useEffect(() => {
    let nIn = 0;
    let nRack = 0;
    let safeRatio = 0;
    let durH = 0;

    if (totalLog) {
      setDrivingDetail(totalLog);

      setDriveDuration(moment.duration(totalLog.driving_duration, "s"));
      durH = totalLog.driving_duration / 60 / 60;

      if (totalLog.driving_distance >= 5) {
        if (durH > 0) {
          nIn =
            ((totalLog.distracted_count ?? 0) +
              (totalLog.drowsy_count ?? 0) +
              (totalLog.smoking_count ?? 0) +
              (totalLog.calling_count ?? 0)) /
            durH;
          nRack =
            ((totalLog.harsh_braking_count ?? 0) +
              (totalLog.sharp_turn_count ?? 0) +
              (totalLog.speed_over_count ?? 0) +
              (totalLog.acceleration_count ?? 0)) /
            durH;

          nIn = (nIn / 60) * 100;
          nRack = (nRack / 60) * 100;
          safeRatio = 100 - nIn - nRack;
        }
      }
    } else {
      return;
    }

    const data = _.sortBy(
      [
        { key: "Inattentive", value: parseFloat(nIn.toFixed(1)), order: 1 },
        {
          key: "Safe",
          value: safeRatio,
          order: 0,
        },
        { key: "Reckless", value: parseFloat(nRack.toFixed(1)), order: 2 },
      ],
      (i) => (safeRatio === 0 ? i.order : -i.value)
    );
    setTotalEvents(data);

    // durH = Math.max(durH, 1);

    setInattentiveEvents({
      Calling: totalLog?.calling_count ?? 0,
      Distracted: totalLog?.distracted_count ?? 0,
      Drowsy: totalLog?.drowsy_count ?? 0,
      Smoking: totalLog?.smoking_count ?? 0,
    } as EventStatType);
    setRecklessEvents({
      Overspeed: totalLog?.speed_over_count ?? 0,
      HarshBraking: totalLog?.harsh_braking_count ?? 0,
      SharpTurn: totalLog?.sharp_turn_count ?? 0,
      Acceleration: totalLog?.acceleration_count ?? 0,
    } as EventStatType);
    // setAnimation({});
  }, [totalLog]);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const handleListKeyDown = useCallback((event: React.KeyboardEvent) => {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
      // setClickMenu(false);
    }
  }, []);

  const alertMarkup = useMemo(() => {
    let isToday = false;
    if (mode === "day") {
      isToday = moment().isSame(day, "day");
    } else if (mode === "month") {
      isToday = moment().isSame(day, "month");
    }
    if ((!totalLog || drivingDetail?.driving_distance === 0) && isToday) {
      return (
        <Alerts mode="web" severity="warning">
          <div
            dangerouslySetInnerHTML={{
              __html:
                mode === "month"
                  ? t("You haven’t driven_Month")
                  : t("You haven’t driven_Today"),
            }}
          />
        </Alerts>
      );
    }
  }, [totalLog, day, drivingDetail?.driving_distance, mode, t]);

  const DrivingTrendChart = useMemo(() => {
    const month = moment().get("month") + 1;
    const months = _.range(0, 4)
      .reverse()
      .map((r) => {
        let pmonth = month - r;
        if (pmonth < 1) {
          pmonth = pmonth + 12;
        }
        const found = _.find(monthlyScores, (s) => s.month === pmonth);
        if (found) {
          return found;
        }
        return {
          month: pmonth,
          value: 0,
        };
      });

    return (
      <Bar
        type="bar"
        data={{
          labels: _.map(months, (score) => MONTH_ABBR[score.month]),
          datasets: [
            {
              barThickness: 15,
              data: _.map(months, (score) => score.value),
              // data: _.map(months, () => 100),
              backgroundColor: ["rgba(17, 146, 232, 1)"],
            },
          ],
        }}
        plugins={[ChartDataLabels]}
        options={{
          indexAxis: "y",
          maintainAspectRatio: false,
          responsive: true,

          layout: {
            padding: {
              right: 30,
            },
          },
          plugins: {
            legend: {
              display: false,
            },
            datalabels: {
              align: "end",
              anchor: "end",
              // clamp: true,
              color: LightColors.primary["2"],
              padding: 6,
              formatter: (value: any) => {
                return value === 0 ? "" : `${value}`;
              },
            },
            tooltip: {
              // Disable the on-canvas tooltip
              enabled: false,
            },
          },
          scales: {
            yAxes: {
              grid: { display: false },
            },
            xAxes: {
              min: 0,
              max: 100,
              ticks: {
                stepSize: 20,
                callback: (v: any) => `${v}`,
              },
            },
          },
        }}
      />
    );
  }, [monthlyScores]);

  const getMonthlyYaxisMaxNum = useMemo(() => {
    if (monthlyMaxNum) {
      // mantis - 9154, Driving events trend y축횟수가 0~10 인 경우 : 2,4,6,8,10(max)로 수정 (Leehj)
      if (monthlyMaxNum <= 10) {
        return 10;
      } else if (monthlyMaxNum <= 12) {
        return 12;
      } else {
        return undefined;
      }
    } else {
      return undefined;
    }
  }, [monthlyMaxNum]);

  const getDailyYaxisMaxNum = useMemo(() => {
    if (dailyMaxNum) {
      // mantis - 9154, Driving events trend y축횟수가 0~10 인 경우 : 2,4,6,8,10(max)로 수정 (Leehj)
      if (dailyMaxNum <= 10) {
        return 10;
      } else if (dailyMaxNum <= 12) {
        return 12;
      } else {
        return undefined;
      }
    } else {
      return undefined;
    }
  }, [dailyMaxNum]);

  const MonthlyDrivingEventChart = useMemo(() => {
    // maintis - 8614, 현재 달이 아닌 선택된 달로 변경
    const now = day;
    const startDay = moment(now).startOf("month");
    const endDay = moment(now).endOf("month");
    const dayRange = _.range(startDay.get("D"), endDay.get("D") + 1);
    let values: { day: number; value: number }[] = [];
    if (shownEvents === "All") {
      values = _.map(monthly, (d) => ({
        day: d.day,
        value: d.totalEvents,
      }));
    } else {
      values = _.map(monthly, (d) => {
        if (shownEvents === "Hand distraction") {
          return {
            day: d.day,
            value:
              (d.eventsPerDrivingDay["Calling"] ?? 0) +
              (d.eventsPerDrivingDay["Smoking"] ?? 0),
          };
        }
        return {
          day: d.day,
          value: d.eventsPerDrivingDay[shownEvents] ?? 0,
        };
      });
    }

    const total = _.sumBy(values, (v) => v.value);
    setMonthlyMaxNum(_.maxBy(values, "value")?.value);

    const totalMonthly = _.sumBy(monthly, (v) => v.totalEvents);
    setTotalEventCount(totalMonthly);

    if (total === 0) {
      return (
        <div className={classes.drivingTrendsDiv}>
          <Typography
            category="Default"
            variant="BodyBold"
            htmlColor={LightColors.primary[2]}
          >
            {t("No event data_")}
          </Typography>
          <Typography
            category="Default"
            variant="Small"
            htmlColor={LightColors.primary[2]}
          >
            {t("Please select another_")}
          </Typography>
        </div>
      );
    }

    return (
      <>
        <Typography
          category="Default"
          variant="CaptionBold"
          htmlColor={LightColors.primary["2"]}
        >
          {t("Event count")}
        </Typography>
        <div style={{ height: 240 }}>
          <Bar
            type="bar"
            data={{
              labels: dayRange,
              datasets: [
                {
                  data: _.map(
                    dayRange,
                    // (d) => _.find(values, (m) => m.day === d - 1)?.value
                    // mantis 9154 - 데이터 일자가 그래프에 잘못들어가서 y축범위가 정상적으로 나오지 않는 이슈 수정 (Leehj)
                    (d) => _.find(values, (m) => m.day === d)?.value
                  ),
                  backgroundColor: ["rgba(17, 146, 232, 1)"],
                },
              ],
            }}
            options={{
              maintainAspectRatio: false,
              responsive: true,
              plugins: {
                legend: {
                  display: false,
                },
                tooltip: {
                  // Disable the on-canvas tooltip
                  enabled: false,
                  external: (context: any) => {
                    // Tooltip Element
                    const { chart, tooltip } = context;
                    const { dataPoints } = tooltip;
                    // 9134 - report 백화현상 수정
                    if (!dataPoints) return;
                    const { dataIndex } = dataPoints[0];
                    // mantis 9154 - 그래프에 data값 수정하면서 툴팁도 맞추어 수정
                    const data = _.find(
                      monthly,
                      (m) => m.day === dataIndex + 1
                    );

                    if (data) {
                      let tooltipEl = document.getElementById(
                        "chartjs-tooltip"
                      );
                      let events = _.chain(data.eventsPerDrivingDay)
                        .map((val, k) => ({
                          key: EVENT_TO_LABEL[k as EventType],
                          value: val,
                        }))
                        .groupBy((k) => k.key)
                        .map((v, k) => {
                          return {
                            key: k,
                            value: _.sumBy(v, (val) => val.value),
                          };
                        })
                        .sortBy((d) => -d.value)
                        // .take(3)
                        .value();

                      if (shownEvents !== "All") {
                        events = _.chain(
                          Object.fromEntries(
                            Object.entries(data.eventsPerDrivingDay).filter(
                              ([key, value]) => {
                                if (shownEvents === "Hand distraction") {
                                  return key === "Calling" || key === "Smoking";
                                }
                                return key === shownEvents;
                              }
                            )
                          )
                        )
                          .map((val, k) => ({
                            key: EVENT_TO_LABEL[k as EventType],
                            value: val,
                          }))
                          .groupBy((k) => k.key)
                          .map((v, k) => {
                            return {
                              key: k,
                              value: _.sumBy(v, (val) => val.value),
                            };
                          })
                          .sortBy((d) => -d.value)
                          // .take(3)
                          .value();
                      }

                      // Create element on first render
                      if (!tooltipEl) {
                        tooltipEl = document.createElement("div");
                        tooltipEl.id = "chartjs-tooltip";
                        document.body.appendChild(tooltipEl);
                      }

                      // Hide if no tooltip
                      const tooltipModel = tooltip;
                      if (tooltipModel.opacity === 0) {
                        tooltipEl.style.opacity = "0";
                        return;
                      }

                      // const totalDiv = `<div style="font-weight:500;">${t(
                      //   "Total"
                      // )}: ${data.totalEvents}</div>`;
                      const totalDiv = `<div style="font-weight:500;">${t(
                        "Total"
                      )}: ${_.sumBy(events, "value")}</div>`;

                      const eventsDiv = _.map(
                        events,
                        (e) => `<div>${t(e.key)}: ${e.value}</div>`
                      ).join("");

                      tooltipEl.innerHTML = `<div>${totalDiv}${eventsDiv}</div>`;

                      var position = chart.canvas.getBoundingClientRect();

                      // Display, position, and set styles for font
                      tooltipEl.style.opacity = "1";
                      tooltipEl.style.backgroundColor = "rgba(19, 19, 28, 1)";
                      tooltipEl.style.color = "#fff";
                      tooltipEl.style.position = "absolute";
                      tooltipEl.style.left =
                        position.left +
                        window.pageXOffset +
                        tooltipModel.caretX +
                        "px";
                      tooltipEl.style.top =
                        position.top +
                        window.pageYOffset +
                        tooltipModel.caretY +
                        "px";
                      tooltipEl.style.padding = "4px 8px";
                      tooltipEl.style.borderRadius = "4px";
                      tooltipEl.style.boxShadow =
                        "0px 0px 1px rgba(0, 0, 0, 0.14), 0px 1px 1px rgba(0, 0, 0, 0.12), 0px 0px 3px rgba(0, 0, 0, 0.2)";
                      tooltipEl.style.pointerEvents = "none";
                      tooltipEl.style.fontFamily = "Roboto";
                      tooltipEl.style.fontSize = "12px";
                      tooltipEl.style.lineHeight = "16px";
                    }
                  },
                },
              },
              scales: {
                xAxes: {
                  grid: { display: false },
                },
                yAxes: {
                  max: getMonthlyYaxisMaxNum,
                  ticks: {
                    stepSize: monthlyMaxNum
                      ? monthlyMaxNum <= 4
                        ? 2
                        : Math.ceil(monthlyMaxNum / 4)
                      : undefined,
                    autoSkip: false,
                  },
                },
              },
            }}
          />
        </div>
        <Typography
          category="Default"
          variant="CaptionBold"
          htmlColor={LightColors.primary["2"]}
        >
          {t("Days_")}
        </Typography>
      </>
    );
  }, [
    classes.drivingTrendsDiv,
    day,
    getMonthlyYaxisMaxNum,
    monthly,
    monthlyMaxNum,
    shownEvents,
    t,
  ]);

  const DailyDrivingEventChart = useMemo(() => {
    const now = moment();
    const startDay = moment(now).startOf("day");
    const endDay = moment(now).endOf("day");
    const dayRange = _.range(startDay.get("hour"), endDay.get("hour") + 1);
    let values: { hour: number; value: number }[] = [];
    if (shownEvents === "All") {
      values = _.map(daily, (d) => ({ hour: d.hour, value: d.totalEvents }));
    } else {
      values = _.map(daily, (d) => {
        if (shownEvents === "Hand distraction") {
          return {
            hour: d.hour,
            value:
              (d.eventsPerDrivingHour["Calling"] ?? 0) +
              (d.eventsPerDrivingHour["Smoking"] ?? 0),
          };
        }
        return {
          hour: d.hour,
          value: d.eventsPerDrivingHour[shownEvents] ?? 0,
        };
      });
    }

    const total = _.sumBy(values, (v) => v.value);
    setDailyMaxNum(_.maxBy(values, "value")?.value);

    const totalDaily = _.sumBy(daily, (v) => v.totalEvents);
    setTotalEventCount(totalDaily);

    if (total === 0) {
      return (
        <div className={classes.drivingTrendsDiv}>
          <Typography
            category="Default"
            variant="BodyBold"
            htmlColor={LightColors.primary[2]}
          >
            {t("No event data_")}
          </Typography>
          <Typography
            category="Default"
            variant="Small"
            htmlColor={LightColors.primary[2]}
          >
            {t("Please select another_")}
          </Typography>
        </div>
      );
    }

    return (
      <>
        <Typography
          category="Default"
          variant="CaptionBold"
          htmlColor={LightColors.primary["2"]}
        >
          {t("Event count")}
        </Typography>
        <div style={{ height: 240 }}>
          <Bar
            type="bar"
            data={{
              labels: dayRange,
              datasets: [
                {
                  data: _.map(
                    dayRange,
                    (d) => _.find(values, (m) => m.hour === d)?.value
                  ),
                  backgroundColor: ["rgba(17, 146, 232, 1)"],
                },
              ],
            }}
            // mantis - 8603
            // zoom in/out 한 뒤, Bar그래프에 마우스 hover하는 경우 크기가 줄어들거나 늘어나지 않도록 최대/최소값 수정함.
            style={{
              maxWidth: "100%",
              minWidth: "100%",
              maxHeight: 240,
              minHeight: "100%",
              position: "relative",
            }}
            options={{
              maintainAspectRatio: false,
              responsive: true,
              plugins: {
                legend: {
                  display: false,
                },
                tooltip: {
                  // Disable the on-canvas tooltip
                  enabled: false,
                  external: (context: any) => {
                    // Tooltip Element
                    const { chart, tooltip } = context;
                    // mantis - 8603
                    // zoom in/out 하고 마우스 hover하였을때, type error 발생하여 ignore 추가함.
                    // @ts-ignore
                    const { dataPoints } = tooltip;
                    // 9134 - report 백화현상 수정
                    if (!dataPoints) return;
                    // @ts-ignore
                    const { dataIndex } = dataPoints[0];
                    const data = _.find(daily, (m) => m.hour === dataIndex);

                    if (data) {
                      let tooltipEl = document.getElementById(
                        "chartjs-tooltip"
                      );

                      let events = _.chain(data.eventsPerDrivingHour)
                        .map((val, k) => ({
                          key: EVENT_TO_LABEL[k as EventType],
                          value: val,
                        }))
                        .groupBy((k) => k.key)
                        .map((v, k) => {
                          return {
                            key: k,
                            value: _.sumBy(v, (val) => val.value),
                          };
                        })
                        .sortBy((d) => -d.value)
                        // .take(3)
                        .value();

                      if (shownEvents !== "All") {
                        events = _.chain(
                          Object.fromEntries(
                            Object.entries(data.eventsPerDrivingHour).filter(
                              ([key, value]) => {
                                if (shownEvents === "Hand distraction") {
                                  return key === "Calling" || key === "Smoking";
                                }
                                return key === shownEvents;
                              }
                            )
                          )
                        )
                          .map((val, k) => ({
                            key: EVENT_TO_LABEL[k as EventType],
                            value: val,
                          }))
                          .groupBy((k) => k.key)
                          .map((v, k) => {
                            return {
                              key: k,
                              value: _.sumBy(v, (val) => val.value),
                            };
                          })
                          .sortBy((d) => -d.value)
                          // .take(3)
                          .value();
                      }

                      // Create element on first render
                      if (!tooltipEl) {
                        tooltipEl = document.createElement("div");
                        tooltipEl.id = "chartjs-tooltip";
                        document.body.appendChild(tooltipEl);
                      }

                      // Hide if no tooltip
                      const tooltipModel = tooltip;
                      if (tooltipModel.opacity === 0) {
                        tooltipEl.style.opacity = "0";
                        return;
                      }

                      // const totalDiv = `<div style="font-weight:500;">${t(
                      //   "Total"
                      // )}: ${data.totalEvents}</div>`;
                      const totalDiv = `<div style="font-weight:500;">${t(
                        "Total"
                      )}: ${_.sumBy(events, "value")}</div>`;
                      const eventsDiv = _.map(
                        events,
                        (e) => `<div>${t(e.key)}: ${e.value}</div>`
                      ).join("");
                      tooltipEl.innerHTML = `<div>${totalDiv}${eventsDiv}</div>`;

                      var position = chart.canvas.getBoundingClientRect();

                      // Display, position, and set styles for font
                      tooltipEl.style.opacity = "1";
                      tooltipEl.style.backgroundColor = "rgba(19, 19, 28, 1)";
                      tooltipEl.style.color = "#fff";
                      tooltipEl.style.position = "absolute";
                      tooltipEl.style.left =
                        position.left +
                        window.pageXOffset +
                        tooltipModel.caretX +
                        "px";
                      tooltipEl.style.top =
                        position.top +
                        window.pageYOffset +
                        tooltipModel.caretY +
                        "px";
                      tooltipEl.style.padding = "4px 8px";
                      tooltipEl.style.borderRadius = "4px";
                      tooltipEl.style.boxShadow =
                        "0px 0px 1px rgba(0, 0, 0, 0.14), 0px 1px 1px rgba(0, 0, 0, 0.12), 0px 0px 3px rgba(0, 0, 0, 0.2)";
                      tooltipEl.style.pointerEvents = "none";
                      tooltipEl.style.fontFamily = "Roboto";
                      tooltipEl.style.fontSize = "12px";
                      tooltipEl.style.lineHeight = "16px";
                    }
                  },
                },
              },
              scales: {
                xAxes: {
                  grid: { display: false },
                },
                yAxes: {
                  max: getDailyYaxisMaxNum,
                  ticks: {
                    stepSize: dailyMaxNum
                      ? dailyMaxNum <= 4
                        ? 2
                        : Math.ceil(dailyMaxNum / 4)
                      : undefined,
                    autoSkip: false,
                  },
                },
              },
            }}
          />
        </div>

        <Typography
          category="Default"
          variant="CaptionBold"
          htmlColor={LightColors.primary["2"]}
        >
          {t("Hours")}
        </Typography>
      </>
    );
  }, [
    classes.drivingTrendsDiv,
    daily,
    dailyMaxNum,
    getDailyYaxisMaxNum,
    shownEvents,
    t,
  ]);

  const DrivingStyleChart = useMemo(() => {
    console.log(
      "DrivingStyleChart",
      "totalEvents",
      totalEvents
      // "animation",
      // animation
    );
    const tLabels = _.map(totalEvents, (d) => t(d.key));
    const tValues = _.map(totalEvents, (d) => d.value);
    const empty = _.sum(tValues) === 0;
    return (
      <Doughnut
        type="doughnut"
        data={{
          labels: empty ? [] : tLabels,
          datasets: [
            {
              data: empty ? [100] : tValues,
              backgroundColor: empty
                ? [LightColors.primary["6"]]
                : _.map(tLabels, (tLabel) => {
                    if (tLabel === t("Inattentive")) {
                      return INATTENTIVE_COLOR;
                    } else if (tLabel === t("Reckless")) {
                      return RACKLESS_COLOR;
                    } else {
                      return SAFE_COLOR;
                    }
                  }),
            },
          ],
        }}
        // mantis - 8603
        // report chart 에 마우스 hover 하였을 때, 크기 변화가 되지 않도록 width, height 설정값 수정
        // mantis - 9111 모바일 차트 타원형 수정 (hongcs)
        className={classes.drivingChart}
        options={{
          // animation,
          cutout: "60%",
          responsive: true,
          // mantis - 8603
          // 브라우져 크기를 조정하고 report chart에 마우스 hover 하였을 때, 자동으로 종횡비 유지 비활성화.
          maintainAspectRatio: false,
          plugins: {
            legend: {
              display: false,
            },
            tooltip: {
              // Disable the on-canvas tooltip
              enabled: false,
              external: (context: any) => {
                // Tooltip Element
                var tooltipEl = document.getElementById("chartjs-tooltip");

                // Create element on first render
                if (!tooltipEl) {
                  tooltipEl = document.createElement("div");
                  tooltipEl.id = "chartjs-tooltip";
                  document.body.appendChild(tooltipEl);
                }

                // Hide if no tooltip
                var tooltipModel = context.tooltip;
                if (tooltipModel.opacity === 0) {
                  tooltipEl.style.opacity = "0";
                  return;
                }

                function getBody(bodyItem: any) {
                  return bodyItem.lines;
                }

                // Set Text
                if (tooltipModel.body) {
                  var bodyLines = _.flattenDeep(tooltipModel.body.map(getBody));

                  const bodies = _.map(bodyLines, (body: string, i: number) => {
                    var colors = tooltipModel.labelColors[i];
                    var style = `background-color:${colors.backgroundColor};width:8px;height:8px;border: 1px solid ${colors.borderColor};border-radius: 50%;margin-right: 4px;`;
                    var span = '<div style="' + style + '"></div>';
                    return `<div style="display:flex;align-items:center;">${span}${body}%</div>`;
                  });

                  tooltipEl.innerHTML = `<div>${_.join(bodies, "")}</div>`;
                }

                var position = context.chart.canvas.getBoundingClientRect();

                // Display, position, and set styles for font
                tooltipEl.style.opacity = "1";
                tooltipEl.style.backgroundColor = "rgba(19, 19, 28, 1)";
                tooltipEl.style.color = "#fff";
                tooltipEl.style.position = "absolute";
                tooltipEl.style.left =
                  position.left +
                  window.pageXOffset +
                  tooltipModel.caretX +
                  "px";
                tooltipEl.style.top =
                  position.top +
                  window.pageYOffset +
                  tooltipModel.caretY / 2 +
                  "px";
                tooltipEl.style.padding = "4px 8px";
                tooltipEl.style.borderRadius = "4px";
                tooltipEl.style.boxShadow =
                  "0px 0px 1px rgba(0, 0, 0, 0.14), 0px 1px 1px rgba(0, 0, 0, 0.12), 0px 0px 3px rgba(0, 0, 0, 0.2)";
                tooltipEl.style.pointerEvents = "none";
                tooltipEl.style.fontFamily = "Roboto";
                tooltipEl.style.fontSize = "12px";
                tooltipEl.style.lineHeight = "16px";
              },
            },
          },
        }}
      />
    );
  }, [t, totalEvents, classes.drivingChart]);

  const InattentiveDrivingChart = useMemo(() => {
    const noDMSModule = !camera?.dms_type;
    if (
      (!_.includes(DMC100_MODELS, camera?.model) &&
        !_.includes([...DMC200_MODELS, ...BOXPRO_MODELS], camera?.model)) ||
      noDMSModule
    ) {
      return (
        <div className={classes.noDataPieDiv}>
          <Typography
            category="Default"
            variant="BodyBold"
            htmlColor={LightColors.primary["2"]}
          >
            {t("No data")}
          </Typography>
        </div>
      );
    }
    const total = _.chain(inattentiveEvents)
      .map((v) => v)
      .sum()
      .value();

    const data = _.chain(inattentiveEvents)
      .map((v, k) => ({
        key: EVENT_TO_LABEL[k as EventType],
        value: v,
        k,
      }))
      .groupBy((k) => k.key)
      .map((v, k) => {
        return {
          key: k,
          value: _.sumBy(v, (val) => val.value),
          order: _.maxBy(v, (val) => INATTENTIVE_ORDERS[val.k as EventType]),
        };
      })
      .sortBy((d) => (total === 0 ? d.order : -d.value))
      .value();

    const labels = _.map(data, (d) => d.key);
    const tLabels = _.map(data, (d) => t(d.key));
    const tValues = _.map(data, (d) => (d.value / total) * 100);

    return total === 0 ? (
      <div className={classes.noDataPieDiv}></div>
    ) : (
      <Pie
        type="pie"
        data={{
          labels: tLabels,
          datasets: [
            {
              data: tValues,
              backgroundColor: _.map(labels, (t) => {
                if (t === "Smoking") {
                  return SMOKING_COLOR;
                } else if (t === "Distracted") {
                  return DISTRACTED_COLOR;
                } else if (t === "Drowsy") {
                  return DROWSY_COLOR;
                } else {
                  return CALLING_COLOR;
                }
              }),
            },
          ],
        }}
        // mantis - 8603
        // report chart 에 마우스 hover 하였을 때, 크기 변화가 되지 않도록 width, height 설정값 수정
        style={{ maxHeight: 182, maxWidth: 182, minHeight: 180, minWidth: 180 }}
        options={{
          responsive: true,
          // mantis - 8603
          // 브라우져 크기를 조정하고 report chart에 마우스 hover 하였을 때, 자동으로 종횡비 유지 비활성화.
          maintainAspectRatio: false,
          plugins: {
            legend: { display: false },
            tooltip: {
              // Disable the on-canvas tooltip
              enabled: false,
              external: (context: any) => {
                // Tooltip Element
                var tooltipEl = document.getElementById("chartjs-tooltip");

                // Create element on first render
                if (!tooltipEl) {
                  tooltipEl = document.createElement("div");
                  tooltipEl.id = "chartjs-tooltip";
                  document.body.appendChild(tooltipEl);
                }

                // Hide if no tooltip
                var tooltipModel = context.tooltip;
                if (tooltipModel.opacity === 0) {
                  tooltipEl.style.opacity = "0";
                  return;
                }

                function getBody(bodyItem: any) {
                  return bodyItem.lines;
                }

                // Set Text
                if (tooltipModel.body) {
                  var bodyLines = _.flattenDeep(tooltipModel.body.map(getBody));

                  const bodies = _.map(bodyLines, (body: string, i: number) => {
                    var colors = tooltipModel.labelColors[i];
                    var style = `background-color:${colors.backgroundColor};width:8px;height:8px;border: 1px solid ${colors.borderColor};border-radius: 50%;margin-right: 4px;`;
                    var span = '<div style="' + style + '"></div>';
                    return `<div style="display:flex;align-items:center;">${span}${body}%</div>`;
                  });

                  tooltipEl.innerHTML = `<div>${_.join(bodies, "")}</div>`;
                }

                var position = context.chart.canvas.getBoundingClientRect();

                // Display, position, and set styles for font
                tooltipEl.style.opacity = "1";
                tooltipEl.style.backgroundColor = "rgba(19, 19, 28, 1)";
                tooltipEl.style.color = "#fff";
                tooltipEl.style.position = "absolute";
                tooltipEl.style.left =
                  position.left +
                  window.pageXOffset +
                  tooltipModel.caretX +
                  "px";
                tooltipEl.style.top =
                  position.top +
                  window.pageYOffset +
                  tooltipModel.caretY +
                  "px";
                tooltipEl.style.padding = "4px 8px";
                tooltipEl.style.borderRadius = "4px";
                tooltipEl.style.boxShadow =
                  "0px 0px 1px rgba(0, 0, 0, 0.14), 0px 1px 1px rgba(0, 0, 0, 0.12), 0px 0px 3px rgba(0, 0, 0, 0.2)";
                tooltipEl.style.pointerEvents = "none";
                tooltipEl.style.fontFamily = "Roboto";
                tooltipEl.style.fontSize = "12px";
                tooltipEl.style.lineHeight = "16px";
              },
            },
          },
        }}
      />
    );
  }, [
    camera?.dms_type,
    camera?.model,
    classes.noDataPieDiv,
    inattentiveEvents,
    t,
  ]);

  const renderInattentiveLabel = useMemo(() => {
    const isDMC100 = _.includes(DMC100_MODELS, camera?.model);
    const isDMC200 = _.includes(DMC200_MODELS, camera?.model);
    const noDMSModule = !camera?.dms_type;

    if (noDMSModule) {
      return (
        <div style={mobile ? { textAlign: "center" } : { maxWidth: 214 }}>
          <Typography
            category="Default"
            variant="Body"
            htmlColor={LightColors.primary["2"]}
          >
            {t("A DMS and compatible_")}
          </Typography>
        </div>
      );
    }

    const total = _.chain(inattentiveEvents)
      .map((v) => v)
      .sum()
      .value();

    const data = _.chain(inattentiveEvents)
      .map((v, k) => ({
        key: EVENT_TO_LABEL[k as EventType],
        value: v,
        k,
      }))
      .groupBy((k) => k.key)
      .map((v, k) => {
        return {
          key: k,
          value: _.sumBy(v, (val) => val.value),
          order: _.maxBy(v, (val) => INATTENTIVE_ORDERS[val.k as EventType]),
        };
      })
      // mantis 9281 - DMC100 Inattentive driving 항목 중 Hand distraction 맨 뒤로 (Leehj)
      .sortBy((d) => {
        if (isDMC100 && _.includes(["Hand distraction"], d.key)) {
          return 99;
        }
        return total === 0 ? d.order : -d.value;
      })
      .filter((d) => {
        return !isDMC200 ? d.key !== "Hand distraction" : true;
      })
      .value();

    //default는 Drowsy,Distracted,Hand distraction 순서
    const checkData =
      _.sum(inattentiveEvents && Object.values(inattentiveEvents)) === 0
        ? data.reverse()
        : data;

    return (
      <div className={classes.pieLabelRootDiv}>
        {_.map(checkData, (d, indx) => {
          let color = CALLING_COLOR;
          if (d.key === "Smoking") {
            color = SMOKING_COLOR;
          } else if (d.key === "Distracted") {
            color = DISTRACTED_COLOR;
          } else if (d.key === "Drowsy") {
            color = DROWSY_COLOR;
          }
          let per = total === 0 ? 0 : _.round((d.value / total) * 100, 1);
          // if (isDMC100 && _.includes(["Calling", "Smoking"], d.key)) {
          // mantis 9209 - DMC100 사용자 Hand distraction 항목 비활성화 적용 (Leehj)
          if (isDMC100 && _.includes(["Hand distraction"], d.key)) {
            per = -1;
            color = LightColors.primary["1"];
          }

          return (
            <div
              key={`pie-label-inattentive-${indx}`}
              className={clsx(classes.pieLabelDiv, {
                [classes.disablePieLabel]: per === -1,
              })}
            >
              <div className={classes.pieLabelTitleDiv}>
                <div
                  className={classes.labelMarker}
                  style={{ backgroundColor: color }}
                />
                <Typography
                  category="Default"
                  variant={mobile ? "Small" : "Body"}
                >
                  {t(d.key)}
                </Typography>
              </div>
              <div>
                <Typography
                  category="Default"
                  variant={mobile ? "Small" : "Body"}
                >
                  {per === -1 ? "No Data" : `${per}%`}
                </Typography>
                {per !== -1 && (
                  <Typography
                    category="Default"
                    variant={mobile ? "Small" : "Body"}
                    htmlColor={LightColors.primary["2"]}
                  >
                    {` (${_.round(d.value, 1)})`}
                  </Typography>
                )}
              </div>
            </div>
          );
        })}
        {isDMC100 && (
          <Typography
            category="Default"
            variant="Caption"
            style={{ marginLeft: 18, marginTop: 8, opacity: 0.35 }}
          >
            {t("Requires DMC 200_")}
          </Typography>
        )}
      </div>
    );
  }, [
    camera?.dms_type,
    camera?.model,
    classes.disablePieLabel,
    classes.labelMarker,
    classes.pieLabelDiv,
    classes.pieLabelRootDiv,
    classes.pieLabelTitleDiv,
    inattentiveEvents,
    mobile,
    t,
  ]);

  const RecklessDrivingChart = useMemo(() => {
    const total = _.chain(racklessEvents)
      .map((v) => v)
      .sum()
      .value();
    const data = _.chain(racklessEvents)
      .map((v, k) => ({ key: k, value: v, order: RACKLESS_ORDERS[k] }))
      .sortBy((d) => (total === 0 ? d.order : -d.value))
      .value();

    const tLabels = _.map(data, (d) => {
      //@ts-ignore
      return t(EVENT_TO_LABEL[d.key]);
    });
    const tValues = _.map(data, (d) => (d.value / total) * 100);
    return total === 0 ? (
      <div className={classes.noDataPieDiv}>
        {/* <Typography
          category="Default"
          variant="BodyBold"
          htmlColor={LightColors.primary["2"]}
        >
          {t("No data")}
        </Typography> */}
      </div>
    ) : (
      <Pie
        type="pie"
        data={{
          labels: tLabels,
          datasets: [
            {
              data: tValues,
              backgroundColor: _.map(tLabels, (txt) => {
                if (txt === t("Overspeed")) {
                  return OVERSPEED_COLOR;
                } else if (txt === t("Sharp turn")) {
                  return HARD_CORNERING_COLOR;
                } else if (txt === t("Hard braking")) {
                  return HARD_BRAKING_COLOR;
                } else {
                  return HARD_ACCELERATION_COLOR;
                }
              }),
            },
          ],
        }}
        // mantis - 8603
        // report chart 에 마우스 hover 하였을 때, 크기 변화가 되지 않도록 width, height 설정값 수정
        style={{ maxHeight: 182, maxWidth: 182, minHeight: 180, minWidth: 180 }}
        options={{
          // mantis - 8603
          // 브라우저 zoom in/out 하였을 때, canvas가 자동으로 container안에서 resize 되도록 responsive 옵션 추가.
          responsive: true,
          // 브라우져 크기를 조정하고 report chart에 마우스 hover 하였을 때, 자동으로 종횡비 유지 비활성화.
          maintainAspectRatio: false,
          plugins: {
            legend: { display: false },
            tooltip: {
              // Disable the on-canvas tooltip
              enabled: false,
              external: (context: any) => {
                // Tooltip Element
                var tooltipEl = document.getElementById("chartjs-tooltip");

                // Create element on first render
                if (!tooltipEl) {
                  tooltipEl = document.createElement("div");
                  tooltipEl.id = "chartjs-tooltip";
                  document.body.appendChild(tooltipEl);
                }

                // Hide if no tooltip
                var tooltipModel = context.tooltip;
                if (tooltipModel.opacity === 0) {
                  tooltipEl.style.opacity = "0";
                  return;
                }

                function getBody(bodyItem: any) {
                  return bodyItem.lines;
                }

                // Set Text
                if (tooltipModel.body) {
                  var bodyLines = _.flattenDeep(tooltipModel.body.map(getBody));

                  const bodies = _.map(bodyLines, (body: string, i: number) => {
                    var colors = tooltipModel.labelColors[i];
                    var style = `background-color:${colors.backgroundColor};width:8px;height:8px;border: 1px solid ${colors.borderColor};border-radius: 50%;margin-right: 4px;`;
                    var span = '<div style="' + style + '"></div>';
                    return `<div style="display:flex;align-items:center;">${span}${body}%</div>`;
                  });

                  tooltipEl.innerHTML = `<div>${_.join(bodies, "")}</div>`;
                }

                var position = context.chart.canvas.getBoundingClientRect();

                // Display, position, and set styles for font
                tooltipEl.style.opacity = "1";
                tooltipEl.style.backgroundColor = "rgba(19, 19, 28, 1)";
                tooltipEl.style.color = "#fff";
                tooltipEl.style.position = "absolute";
                tooltipEl.style.left =
                  position.left +
                  window.pageXOffset +
                  tooltipModel.caretX +
                  "px";
                tooltipEl.style.top =
                  position.top +
                  window.pageYOffset +
                  tooltipModel.caretY +
                  "px";
                tooltipEl.style.padding = "4px 8px";
                tooltipEl.style.borderRadius = "4px";
                tooltipEl.style.boxShadow =
                  "0px 0px 1px rgba(0, 0, 0, 0.14), 0px 1px 1px rgba(0, 0, 0, 0.12), 0px 0px 3px rgba(0, 0, 0, 0.2)";
                tooltipEl.style.pointerEvents = "none";
                tooltipEl.style.fontFamily = "Roboto";
                tooltipEl.style.fontSize = "12px";
                tooltipEl.style.lineHeight = "16px";
              },
            },
          },
        }}
      />
    );
  }, [classes.noDataPieDiv, racklessEvents, t]);

  const renderRecklessLabel = useCallback(() => {
    const total = _.chain(racklessEvents)
      .map((v) => v)
      .sum()
      .value();
    let data = _.chain(racklessEvents)
      .map((v, k) => ({ key: k, value: v, order: RACKLESS_ORDERS[k] }))
      .sortBy((d) => (total === 0 ? d.order : -d.value))
      .value();

    if (data.length === 0) {
      data = [
        { key: "Acceleration", value: 0, order: 0 },
        { key: "HarshBraking", value: 0, order: 0 },
        { key: "Overspeed", value: 0, order: 0 },
        { key: "SharpTurn", value: 0, order: 0 },
      ];
    }

    return _.map(data, (d, indx) => {
      let color = HARD_ACCELERATION_COLOR;
      if (d.key === "Overspeed") {
        color = OVERSPEED_COLOR;
      } else if (d.key === "SharpTurn") {
        color = HARD_CORNERING_COLOR;
      } else if (d.key === "HarshBraking") {
        color = HARD_BRAKING_COLOR;
      }

      return (
        <div key={`pie-label-rackless-${indx}`} className={classes.pieLabelDiv}>
          <div className={classes.pieLabelTitleDiv}>
            <div
              className={classes.labelMarker}
              style={{ backgroundColor: color }}
            />
            <Typography category="Default" variant={mobile ? "Small" : "Body"}>
              {
                //@ts-ignore
                t(EVENT_TO_LABEL[d.key])
              }
            </Typography>
          </div>
          <div className={classes.percent}>
            <Typography category="Default" variant={mobile ? "Small" : "Body"}>
              {/* mantis - 8654, .toFixed(1)로 수정하여 반올림한 소수점 첫자리까지 출력 */}
              {total === 0 ? 0 : ((d.value / total) * 100).toFixed(1)}%
            </Typography>
            <Typography
              category="Default"
              variant={mobile ? "Small" : "Body"}
              htmlColor={LightColors.primary["2"]}
            >
              {` (${_.round(d.value, 1)})`}
            </Typography>
          </div>
        </div>
      );
    });
  }, [
    classes.labelMarker,
    classes.percent,
    classes.pieLabelDiv,
    classes.pieLabelTitleDiv,
    mobile,
    racklessEvents,
    t,
  ]);

  const totalLabelMarkup = useMemo(() => {
    const isDMC100 = _.includes(DMC100_MODELS, camera?.model);
    const isDMC200 = _.includes(DMC200_MODELS, camera?.model);
    const isNewDMS = _.includes(BOXPRO_MODELS, camera?.model);
    const noDMS = (!isDMC100 && !isDMC200 && !isNewDMS) || !camera?.dms_type;

    let events = [...totalEvents];

    if (noDMS) {
      const inattentive = _.find(events, (evt) => evt.key === "Inattentive");
      _.pullAllBy(events, [{ key: "Inattentive" }], "key");
      if (inattentive) {
        events = [...events, inattentive];
      }
    }

    if (events.length === 0) {
      events = [
        { key: "Safe", value: 0 },
        { key: "Inattentive", value: 0 },
        { key: "Reckless", value: 0 },
      ];
    }

    return _.map(events, (e) => {
      const value = e.value;
      let color = SAFE_COLOR;
      // if (value === 0) {
      //   color = `rgba(19, 19, 28, 0.35)`;
      // } else
      if (e.key === "Inattentive") {
        color = INATTENTIVE_COLOR;
      } else if (e.key === "Reckless") {
        color = RACKLESS_COLOR;
      }

      // 8371 - 앱과 수치를 맞추기 위해서 round로 수정
      let valueLabel = `${_.round(value, 1)}%`;
      const noData = noDMS && e.key === "Inattentive";
      if (noData) {
        color = LightColors.primary["1"];
        valueLabel = t("No data");
      }

      return (
        <div
          className={clsx(classes.drivingChartLabelDiv, {
            [classes.disablePieLabel]: noData,
          })}
          key={e.key}
        >
          <div className={clsx(classes.labelTitleDiv)}>
            <div
              className={classes.labelMarker}
              style={{ backgroundColor: color }}
            />
            <Typography
              category="Default"
              variant={mobile ? "SmallBold" : "BodyBold"}
              // htmlColor={value === 0 ? color : undefined}
            >
              {t(e.key)}
            </Typography>
          </div>
          <div className={classes.labelValueDiv}>
            <Typography
              category="Default"
              variant={mobile ? "SmallBold" : "H3"}
              // htmlColor={value === 0 ? color : undefined}
            >
              {valueLabel}
            </Typography>
          </div>
        </div>
      );
    });
  }, [
    camera?.dms_type,
    camera?.model,
    classes.disablePieLabel,
    classes.drivingChartLabelDiv,
    classes.labelMarker,
    classes.labelTitleDiv,
    classes.labelValueDiv,
    mobile,
    t,
    totalEvents,
  ]);

  const safeDrivingScore = useMemo(() => {
    const value = _.find(totalEvents, (e) => e.key === "Safe")?.value ?? 0;
    return _.floor(value, 0);
  }, [totalEvents]);

  const disableDayBefore = useMemo(() => {
    return moment().subtract(3, "month").startOf("month");
  }, []);

  const drivingDetails = useMemo(() => {
    if (mobile) {
      return (
        <>
          <div className={classes.detailBodyDiv}>
            <div className={classes.detailItemDiv}>
              <Typography category="Default" variant="BodyBold">
                {t("Driving")}
              </Typography>
              <div className={classes.detailItem}>
                <Typography
                  category="Default"
                  variant="Small"
                  htmlColor={LightColors.primary["2"]}
                >
                  {t("Distance")}
                </Typography>
                {/* 거리단위 수정 (Leehj) */}
                <Typography category="Default" variant="Body">
                  {userSettings?.velocityUnit === "0"
                    ? `${_.round(
                        (drivingDetail?.driving_distance ?? 0) / 1000,
                        2
                      )} km`
                    : `${_.round(
                        (drivingDetail?.driving_distance ?? 0) / 1000 / 1.609,
                        2
                      )} ${
                        _.round(
                          (drivingDetail?.driving_distance ?? 0) / 1000 / 1.609,
                          2
                        ) > 1
                          ? "miles"
                          : "mile"
                      }`}
                </Typography>
              </div>
              <div className={classes.detailItem}>
                <Typography
                  category="Default"
                  variant="Small"
                  htmlColor={LightColors.primary["2"]}
                >
                  {t("Time")}
                </Typography>
                <Typography category="Default" variant="Body">
                  {durationToHMS(drivingDetail?.driving_duration ?? 0, "s")}
                </Typography>
              </div>
            </div>
            <div className={classes.detailItemDiv}>
              <Typography category="Default" variant="BodyBold">
                {t("Parking")}
              </Typography>
              <div className={classes.detailItem}>
                <Typography
                  category="Default"
                  variant="Small"
                  htmlColor={LightColors.primary["2"]}
                >
                  {t("Parking sessions")}
                </Typography>
                <Typography category="Default" variant="Body">
                  {drivingDetail?.parking_mode_count ?? 0}
                </Typography>
              </div>
              <div className={classes.detailItem}>
                <Typography
                  category="Default"
                  variant="Small"
                  htmlColor={LightColors.primary["2"]}
                >
                  {t("Parked time")}
                </Typography>
                <Typography category="Default" variant="Body">
                  {durationToHMS(
                    drivingDetail?.parking_mode_duration ?? 0,
                    "s"
                  )}
                </Typography>
              </div>
            </div>
          </div>

          <div className={classes.detailBodyDiv}>
            <div className={classes.detailItemDiv}>
              <Typography category="Default" variant="BodyBold">
                {t("Idling")}
              </Typography>
              <div className={classes.detailItem}>
                <Typography
                  category="Default"
                  variant="Small"
                  htmlColor={LightColors.primary["2"]}
                >
                  {t("Idle stops")}
                </Typography>
                <Typography category="Default" variant="Body">
                  {drivingDetail?.stop_count ?? 0}
                </Typography>
              </div>
              <div className={classes.detailItem}>
                <Typography
                  category="Default"
                  variant="Small"
                  htmlColor={LightColors.primary["2"]}
                >
                  {t("Idle time")}
                </Typography>
                <Typography category="Default" variant="Body">
                  {durationToHMS(drivingDetail?.stop_duration ?? 0, "s")}
                </Typography>
              </div>
            </div>
            <div className={classes.detailItemDiv}>
              <Typography category="Default" variant="BodyBold">
                {t("Impact events")}
              </Typography>
              <div className={classes.detailItem}>
                <Typography
                  category="Default"
                  variant="Small"
                  htmlColor={LightColors.primary["2"]}
                >
                  {t("Driving")}
                </Typography>
                <Typography category="Default" variant="Body">
                  {drivingDetail?.normal_event_count ?? 0}
                </Typography>
              </div>
              <div className={classes.detailItem}>
                <Typography
                  category="Default"
                  variant="Small"
                  htmlColor={LightColors.primary["2"]}
                >
                  {t("Parked")}
                </Typography>
                <Typography category="Default" variant="Body">
                  {drivingDetail?.parking_event_count ?? 0}
                </Typography>
              </div>
            </div>
          </div>

          <div className={classes.detailBodyDiv}>
            <div className={classes.detailItemDiv}>
              <div className={classes.tooltipReck}>
                <Typography
                  category="Default"
                  variant="BodyBold"
                  style={{ marginRight: 5 }}
                >
                  {t("Speed")}
                </Typography>
                <Tooltip
                  widthLarge
                  title={
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                      dir={theme.direction}
                    >
                      <Typography
                        category="Default"
                        variant="Caption"
                        dangerouslySetInnerHTML={{
                          __html: t("Highest speed_report").replace(
                            // eslint-disable-next-line no-control-regex
                            new RegExp("\n", "g"),
                            "<br/>"
                          ),
                        }}
                      />
                    </div>
                  }
                  placement={mobileTooltip ? "left" : "right"}
                >
                  <InfoIcon className={classes.infoIcon} />
                </Tooltip>
              </div>

              <div className={classes.detailItem}>
                <Typography
                  category="Default"
                  variant="Small"
                  htmlColor={LightColors.primary["2"]}
                >
                  {t("Highest_2")}
                </Typography>
                <Typography category="Default" variant="Body">
                  {userSettings?.velocityUnit === "0"
                    ? `${_.round(
                        (drivingDetail?.max_speed ?? 0) / 1000,
                        1
                      )} km/h`
                    : `${_.round(
                        (drivingDetail?.max_speed ?? 0) / 1000 / 1.609,
                        1
                      )} MPH`}
                </Typography>
              </div>
              <div className={classes.detailItem}>
                <Typography
                  category="Default"
                  variant="Small"
                  htmlColor={LightColors.primary["2"]}
                >
                  {t("Average")}
                </Typography>
                <Typography category="Default" variant="Body">
                  {userSettings?.velocityUnit === "0"
                    ? `${_.round(
                        (drivingDetail?.avg_speed ?? 0) / 1000,
                        1
                      )} km/h`
                    : `${_.round(
                        (drivingDetail?.avg_speed ?? 0) / 1000 / 1.609,
                        1
                      )} MPH`}
                </Typography>
              </div>
            </div>
            <div className={classes.detailItemDiv}>
              <Typography category="Default" variant="BodyBold">
                {t("Geofences")}
              </Typography>
              <div className={classes.detailItem}>
                <Typography
                  category="Default"
                  variant="Small"
                  htmlColor={LightColors.primary["2"]}
                >
                  {t("Entries into a_")}
                </Typography>
                <Typography category="Default" variant="Body">
                  {drivingDetail?.geofencing_enter_count ?? 0}
                </Typography>
              </div>
              <div className={classes.detailItem}>
                <Typography
                  category="Default"
                  variant="Small"
                  htmlColor={LightColors.primary["2"]}
                >
                  {t("Exits from a_")}
                </Typography>
                <Typography category="Default" variant="Body">
                  {drivingDetail?.geofencing_exit_count ?? 0}
                </Typography>
              </div>
            </div>
          </div>
        </>
      );
    } else {
      return (
        <>
          <div className={classes.detailBodyDiv}>
            <div className={classes.detailItemDiv}>
              <Typography category="Default" variant="BodyBold">
                {t("Driving")}
              </Typography>
              <div className={classes.detailItem}>
                <Typography
                  category="Default"
                  variant="Small"
                  htmlColor={LightColors.primary["2"]}
                >
                  {t("Distance")}
                </Typography>
                {/* 거리단위 수정 (Leehj) */}
                <Typography category="Default" variant="Body">
                  {userSettings?.velocityUnit === "0"
                    ? `${_.round(
                        (drivingDetail?.driving_distance ?? 0) / 1000,
                        2
                      )} km`
                    : `${_.round(
                        (drivingDetail?.driving_distance ?? 0) / 1000 / 1.609,
                        2
                      )} ${
                        _.round(
                          (drivingDetail?.driving_distance ?? 0) / 1000 / 1.609,
                          2
                        ) > 1
                          ? "miles"
                          : "mile"
                      }`}
                </Typography>
              </div>
              <div className={classes.detailItem}>
                <Typography
                  category="Default"
                  variant="Small"
                  htmlColor={LightColors.primary["2"]}
                >
                  {t("Time")}
                </Typography>
                <Typography category="Default" variant="Body">
                  {durationToHMS(drivingDetail?.driving_duration ?? 0, "s")}
                </Typography>
              </div>
            </div>
            <div className={classes.detailItemDiv}>
              <Typography category="Default" variant="BodyBold">
                {t("Parking")}
              </Typography>
              <div className={classes.detailItem}>
                <Typography
                  category="Default"
                  variant="Small"
                  htmlColor={LightColors.primary["2"]}
                >
                  {t("Parking sessions")}
                </Typography>
                <Typography category="Default" variant="Body">
                  {drivingDetail?.parking_mode_count ?? 0}
                </Typography>
              </div>
              <div className={classes.detailItem}>
                <Typography
                  category="Default"
                  variant="Small"
                  htmlColor={LightColors.primary["2"]}
                >
                  {t("Parked time")}
                </Typography>
                <Typography category="Default" variant="Body">
                  {durationToHMS(
                    drivingDetail?.parking_mode_duration ?? 0,
                    "s"
                  )}
                </Typography>
              </div>
            </div>
            <div className={classes.detailItemDiv}>
              <Typography category="Default" variant="BodyBold">
                {t("Idling")}
              </Typography>
              <div className={classes.detailItem}>
                <Typography
                  category="Default"
                  variant="Small"
                  htmlColor={LightColors.primary["2"]}
                >
                  {t("Idle stops")}
                </Typography>
                <Typography category="Default" variant="Body">
                  {drivingDetail?.stop_count ?? 0}
                </Typography>
              </div>
              <div className={classes.detailItem}>
                <Typography
                  category="Default"
                  variant="Small"
                  htmlColor={LightColors.primary["2"]}
                >
                  {t("Idle time")}
                </Typography>
                <Typography category="Default" variant="Body">
                  {durationToHMS(drivingDetail?.stop_duration ?? 0, "s")}
                </Typography>
              </div>
            </div>
          </div>
          <div className={classes.detailBodyDiv}>
            <div className={classes.detailItemDiv}>
              <Typography category="Default" variant="BodyBold">
                {t("Impact events")}
              </Typography>
              <div className={classes.detailItem}>
                <Typography
                  category="Default"
                  variant="Small"
                  htmlColor={LightColors.primary["2"]}
                >
                  {t("Driving")}
                </Typography>
                <Typography category="Default" variant="Body">
                  {drivingDetail?.normal_event_count ?? 0}
                </Typography>
              </div>
              <div className={classes.detailItem}>
                <Typography
                  category="Default"
                  variant="Small"
                  htmlColor={LightColors.primary["2"]}
                >
                  {t("Parked")}
                </Typography>
                <Typography category="Default" variant="Body">
                  {drivingDetail?.parking_event_count ?? 0}
                </Typography>
              </div>
            </div>
            <div className={classes.detailItemDiv}>
              <div className={classes.tooltipReck}>
                <Typography
                  category="Default"
                  variant="BodyBold"
                  style={{ marginRight: 8 }}
                >
                  {t("Speed")}
                </Typography>

                <Tooltip
                  widthLarge
                  title={
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                      dir={theme.direction}
                    >
                      <Typography
                        category="Default"
                        variant="Caption"
                        dangerouslySetInnerHTML={{
                          __html: t("Highest speed_report").replace(
                            // eslint-disable-next-line no-control-regex
                            new RegExp("\n", "g"),
                            "<br/>"
                          ),
                        }}
                      />
                    </div>
                  }
                  placement="right"
                >
                  <InfoIcon className={classes.infoIcon} />
                </Tooltip>
              </div>
              <div className={classes.detailItem}>
                <Typography
                  category="Default"
                  variant="Small"
                  htmlColor={LightColors.primary["2"]}
                >
                  {t("Highest_2")}
                </Typography>
                <Typography category="Default" variant="Body">
                  {userSettings?.velocityUnit === "0"
                    ? `${_.round(
                        (drivingDetail?.max_speed ?? 0) / 1000,
                        1
                      )} km/h`
                    : `${_.round(
                        (drivingDetail?.max_speed ?? 0) / 1000 / 1.609,
                        1
                      )} MPH`}
                </Typography>
              </div>
              <div className={classes.detailItem}>
                <Typography
                  category="Default"
                  variant="Small"
                  htmlColor={LightColors.primary["2"]}
                >
                  {t("Average")}
                </Typography>
                <Typography category="Default" variant="Body">
                  {userSettings?.velocityUnit === "0"
                    ? `${_.round(
                        (drivingDetail?.avg_speed ?? 0) / 1000,
                        1
                      )} km/h`
                    : `${_.round(
                        (drivingDetail?.avg_speed ?? 0) / 1000 / 1.609,
                        1
                      )} MPH`}
                </Typography>
              </div>
            </div>
            <div className={classes.detailItemDiv}>
              <Typography category="Default" variant="BodyBold">
                {t("Geofences")}
              </Typography>
              <div className={classes.detailItem}>
                <Typography
                  category="Default"
                  variant="Small"
                  htmlColor={LightColors.primary["2"]}
                >
                  {t("Entries into a_")}
                </Typography>
                <Typography category="Default" variant="Body">
                  {drivingDetail?.geofencing_enter_count ?? 0}
                </Typography>
              </div>
              <div className={classes.detailItem}>
                <Typography
                  category="Default"
                  variant="Small"
                  htmlColor={LightColors.primary["2"]}
                >
                  {t("Exits from a_")}
                </Typography>
                <Typography category="Default" variant="Body">
                  {drivingDetail?.geofencing_exit_count ?? 0}
                </Typography>
              </div>
            </div>
          </div>
        </>
      );
    }
  }, [
    classes.detailBodyDiv,
    classes.detailItem,
    classes.detailItemDiv,
    classes.infoIcon,
    classes.tooltipReck,
    drivingDetail,
    mobile,
    mobileTooltip,
    t,
    theme.direction,
    userSettings?.velocityUnit,
  ]);

  const downloadBtn = useMemo(() => {
    return (
      <div>
        <Tooltip
          disableTouchListener={reportMobile}
          placement="top"
          PopperProps={{
            modifiers: {
              offset: {
                enabled: true,
                offset: "0, -12px",
              },
              flip: {
                enabled: false,
              },
            },
          }}
          title={
            <Typography
              category="Default"
              variant="Caption"
              htmlColor={LightColors.primary["0"]}
            >
              {t("Export CSV")}
            </Typography>
          }
        >
          <IconButton ref={anchorRef} onClick={() => setOpen(true)}>
            <SaveAltIcon />
          </IconButton>
        </Tooltip>
      </div>
    );
  }, [reportMobile, t]);

  const downloadPDF = useCallback(() => {
    if (reportRef.current) {
      html2canvas(reportRef.current).then((canvas) => {
        // base64 url 로 변환
        var imgData = canvas.toDataURL("image/jpeg");

        var imgWidth = 210; // 이미지 가로 길이(mm) A4 기준
        var pageHeight = imgWidth * 1.414; // 출력 페이지 세로 길이 계산 A4 기준
        var imgHeight = (canvas.height * imgWidth) / canvas.width;
        var heightLeft = imgHeight;
        var margin = 0;

        var doc = new jsPDF("p", "mm", "a4");
        var position = 0;

        // 첫 페이지 출력
        doc.addImage(imgData, "jpeg", margin, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;

        // 한 페이지 이상일 경우 루프 돌면서 출력
        while (heightLeft >= 20) {
          position = heightLeft - imgHeight;
          doc.addPage();
          doc.addImage(imgData, "jpeg", margin, position, imgWidth, imgHeight);
          heightLeft -= pageHeight;
        }

        // 파일 저장
        doc.save("Report.pdf");
        setOpen(false);
      });
    }
  }, []);

  const downloadCSV = useCallback(async () => {
    if (email && loginInfo && camera) {
      const cancel = new AbortController();
      const startTime = moment(day).startOf(mode);
      const endTime = moment(day).endOf(mode);
      const drive_nos = _.filter(
        driveList,
        (d) =>
          d.sdate.isSameOrAfter(startTime) && d.edate.isSameOrBefore(endTime)
      ).map((d) => d.drive_no);
      const resp = await getGPSTrackingData(
        email,
        loginInfo.user_token,
        loginInfo.jsonwebtoken,
        camera.psn,
        drive_nos,
        cancel
      );
      const data = await resp.json();

      const { resultcode, response } = data as {
        resultcode: RESULT_CODE;
        response: {
          interval: number;
          count: number;
          coords: ITrackInfoOrig[];
        };
      };

      if (resultcode === "BC_ERR_OK") {
        const { coords } = response;
        const trackData = _.chain(coords)
          .map((c) => c.data)
          .flattenDeep()
          .orderBy((d) => -d.vdate)
          .value();
        const csv =
          `${t("Event type")},${t("Event time")},${t("Speed")},${t(
            "Lat./Long."
          )}\r\n` +
          _.chain(trackData)
            .filter((f) => f.mode === "E" && f.mtype !== 0)
            .map(
              (d) =>
                `${MCODE_TO_TEXT[d.mtype]},${moment
                  .unix(d.vdate)
                  .utc(false)
                  .format("YYYY/MM/DD hh:mm:ss")},${Math.floor(
                  d.avg_speed * 1.852
                )}km/h,"${d.loc[1]}, ${d.loc[0]}"`
            )
            .join("\r\n")
            .value();
        // console.log(csv);
        downloadCSVFile(csv, "Report.csv");
        setOpen(false);
      } else if (resultcode === 400) {
        dispatch(setError("Try changing your date"));
      }
    }
  }, [camera, day, dispatch, driveList, email, loginInfo, mode, t]);

  const downloadMenu = useMemo(() => {
    if (mobileView) {
      return (
        <div data-html2canvas-ignore="true">
          <MobileMenu open={open} onClose={() => setOpen(false)}>
            <WebMenuItem
              className={classes.mobileMemuItem}
              startIcon={<SaveAltIcon fontSize="small" />}
              onClick={downloadCSV}
              disabled={!downloadCSVReportPerm}
            >
              <Typography category="Default" variant="Body">
                {t("Download CSV")}
              </Typography>
            </WebMenuItem>
            <WebMenuItem
              className={classes.mobileMemuItem}
              startIcon={<SaveAltIcon fontSize="small" />}
              onClick={downloadPDF}
            >
              <Typography category="Default" variant="Body">
                {t("Download PDF")}
              </Typography>
            </WebMenuItem>
          </MobileMenu>
        </div>
      );
    }
    return (
      <div data-html2canvas-ignore="true">
        <Menu
          open={open}
          anchorEl={anchorRef.current}
          onClickAway={handleClose}
          onKeyDown={handleListKeyDown}
          placement="bottom-end"
          modifiers={{ offset: { enabled: true, offset: "0, 2px" } }}
          dense
        >
          <WebMenuItem
            startIcon={<SaveAltIcon fontSize="small" />}
            onClick={downloadCSV}
            disabled={!downloadCSVReportPerm}
            dense
          >
            <Typography category="Default" variant="Body">
              {t("Download CSV")}
            </Typography>
          </WebMenuItem>
          <WebMenuItem
            startIcon={<SaveAltIcon fontSize="small" />}
            onClick={downloadPDF}
            dense
          >
            <Typography category="Default" variant="Body">
              {t("Download PDF")}
            </Typography>
          </WebMenuItem>
        </Menu>
      </div>
    );
  }, [
    classes.mobileMemuItem,
    downloadCSV,
    downloadCSVReportPerm,
    downloadPDF,
    handleClose,
    handleListKeyDown,
    mobileView,
    open,
    t,
  ]);

  const inattentiveDetailsMarkup = useMemo(() => {
    const isDMC200 = _.includes(DMC200_MODELS, camera?.model);
    if (camera?.dms_type) {
      return (
        <>
          <div className={classes.detailItem}>
            <Typography
              category="Default"
              variant="Small"
              htmlColor={LightColors.primary["2"]}
            >
              {t("Drowsiness")}
            </Typography>
            <Typography category="Default" variant="Body">
              {drivingDetail?.drowsy_count ?? 0}
            </Typography>
          </div>
          <div className={classes.detailItem}>
            <Typography
              category="Default"
              variant="Small"
              htmlColor={LightColors.primary["2"]}
            >
              {t("Distracted")}
            </Typography>
            <Typography category="Default" variant="Body">
              {drivingDetail?.distracted_count ?? 0}
            </Typography>
          </div>
          {isDMC200 && (
            <div className={classes.detailItem}>
              <Typography
                category="Default"
                variant="Small"
                htmlColor={LightColors.primary["2"]}
              >
                {t("Hand distraction")}
              </Typography>
              <Typography category="Default" variant="Body">
                {_.includes(DMC100_MODELS, camera.model)
                  ? t("No data")
                  : (drivingDetail?.calling_count ?? 0) +
                    (drivingDetail?.smoking_count ?? 0)}
              </Typography>
            </div>
          )}
        </>
      );
    } else {
      return (
        <Typography
          category="Default"
          variant="Small"
          htmlColor={LightColors.primary["2"]}
          style={{ marginTop: 8 }}
        >
          {t("No data")}
        </Typography>
      );
    }
  }, [
    camera,
    classes.detailItem,
    drivingDetail?.calling_count,
    drivingDetail?.distracted_count,
    drivingDetail?.drowsy_count,
    drivingDetail?.smoking_count,
    t,
  ]);

  const filteredCams = useMemo(() => {
    // mantis - 11836, Reports에서 카메라 리스트의 경우 wifi 카메라는 아예 미노출로 수정 (Leehj)
    if (cameraList?.deviceListInfo) {
      const wifiCamFilter = _.filter(cameraList.deviceListInfo, (dev) => {
        const loggined = !dev.device.login_date
          ? false
          : !moment(dev.device.login_date).isBefore(moment.utc(0));

        if (
          !(
            _.includes(PSN650, dev.device.psn.substr(0, 4)) ||
            dev.device.reg_category === "wifi" ||
            (dev.device.reg_category === "cloud" && !loggined)
          )
        ) {
          return dev;
        }
      }) as any;

      return (
        _.chain(wifiCamFilter)
          .filter(
            (dev) =>
              dev.device.dev_name
                .toLowerCase()
                .indexOf(cameraName.toLowerCase()) > -1 ||
              dev.device.model.toLowerCase().indexOf(cameraName.toLowerCase()) >
                -1
          )
          // mantis - 12376, report페이지 비활성화 Cloud camera 미노출 적용 (Leehj)
          .filter((dev) => dev.device.valid !== "invalid")
          .map((dev) => {
            const loggined = !dev.device.login_date
              ? false
              : !moment(dev.device.login_date).isBefore(moment.utc(0));

            return (
              <CameraInfoRev
                // mantis - 11482 ,login_date가 없는 경우 비활성화되도록 수정 (Leehj)
                disabled={
                  _.includes(PSN650, dev.device.psn.substr(0, 4)) ||
                  dev.device.reg_category === "wifi" ||
                  (dev.device.reg_category === "cloud" && !loggined)
                }
                key={dev.device.psn}
                camera={dev.device}
                onClick={(cam) => {
                  setOpenCamera(false);
                  setCamera(cam);
                  setCameraName(cam.dev_name);
                }}
                hover
                gpsMobile
              />
            );
          })
          .value()
      );
    } else {
      return [];
    }
  }, [cameraList?.deviceListInfo, cameraName]);

  const cameraListMarkup = useMemo(() => {
    return (
      <>
        {filteredCams.length > 0 ? (
          <div className={classes.cameraListDiv}>{filteredCams}</div>
        ) : (
          <div style={{ display: "flex", alignItems: "center" }}>
            <NoResults />
          </div>
        )}
      </>
    );
  }, [classes.cameraListDiv, filteredCams]);

  const trendItems = useMemo(() => {
    let items = [
      {
        key: "All",
        value: "All",
      },
      {
        key: "Drowsy",
        value: "Drowsiness",
      },
      {
        key: "Distracted",
        value: "Distracted",
      },
      {
        key: "Hand distraction",
        value: "Hand distraction",
      },
      {
        key: "Overspeed",
        value: "Overspeed",
      },
      {
        key: "SharpTurn",
        value: "Sharp turn",
      },
      {
        key: "HarshBraking",
        value: "Hard braking",
      },
      {
        key: "Acceleration",
        value: "Acceleration",
      },
    ];
    if (_.includes(BOXPRO_MODELS, camera?.model)) {
      items = _.filter(items, (item) => item.key !== "Hand distraction");
    }
    return items;
  }, [camera?.model]);

  //data-html2canvas-ignore="true"
  return (
    <div className={classes.root} ref={reportRef}>
      <div className={classes.dateDiv}>
        <div className={classes.dateDiv2}>
          <div className={classes.dateCamDiv}>
            <div className={classes.selectorDiv}>
              <DateSelector
                dense
                mode={mode}
                mobile={mobileTooltip}
                day={day}
                onClick={(d, m) => {
                  setDay(d);
                  m && setMode(m);
                  if (mode !== m) {
                    dispatch(setShownAlert(false));
                  } else {
                    dispatch(setShownAlert(true));
                  }
                }}
                enabledDays={_.uniq([...dates, moment().format("YYYYMMDD")])}
                disableAfterToday
                disableDayBefore={disableDayBefore}
              />
            </div>
            {selectCam && (
              <div className={classes.camTextDiv}>
                <Input
                  dense
                  readOnly={mobile}
                  variant="outlined"
                  placeholder={t("Camera")}
                  label={t("Camera")}
                  rootRef={cameraAnchorRef}
                  value={cameraName}
                  onChange={(e) => {
                    setCameraName(e.target.value);
                  }}
                  onClick={() => {
                    setOpenCamera(true);
                    setCameraName("");
                  }}
                />
                {!mobileTooltip && openCamera && (
                  <CameraListPopper
                    open={openCamera}
                    anchorEl={cameraAnchorRef.current}
                    onClickAway={() => setOpenCamera(false)}
                    cameraName={cameraName}
                    width={320}
                    onClick={(cam) => {
                      setOpenCamera(false);
                      setCamera(cam);
                      setCameraName(cam.dev_name);
                    }}
                  />
                )}

                {mobileTooltip && openCamera && (
                  <Modal
                    open={openCamera}
                    fullSize
                    noPadding
                    mobile
                    heading={t("Camera")}
                    close
                    noScroll
                    contentClassName={classes.modalContentWrap}
                    content={
                      <>
                        <div className={classes.searchInputDiv}>
                          <Input
                            inputID="camerasSearch"
                            placeholder={t("Search cameras")}
                            startIcon={
                              <SearchIcon
                                style={{ color: LightColors.primary["3"] }}
                              />
                            }
                            value={cameraName}
                            onChange={(e) => {
                              setCameraName(e.target.value);
                            }}
                            onCloseSearch={() => {
                              setCameraName("");
                            }}
                            variant="standard"
                            search
                          />
                        </div>
                        <div className={classes.camListtDiv}>
                          {cameraListMarkup}
                        </div>
                      </>
                    }
                    onClose={() => setOpenCamera(false)}
                  />
                )}
              </div>
            )}
          </div>
          {!mobileView && downloadBtn}
        </div>

        {alertMarkup}
      </div>

      {downloadMenu}
      {mobileView && (
        <div className={classes.downloadBtnDiv}>{downloadBtn}</div>
      )}
      <div className={classes.printContainer}>
        <div
          className={clsx(classes.graphRootDiv, classes.mb4, {
            [classes.graphRootOpenDiv]: openMenu,
            [classes.graphRootCloseDiv]: !openMenu,
          })}
        >
          <div
            className={clsx(classes.graphOuterDiv, {
              [classes.graphOuterOpenDiv]: openMenu,
              [classes.graphOuterCloseDiv]: !openMenu,
            })}
          >
            <div
              className={clsx(classes.graphDiv, {
                [classes.mb4]: mobile,
              })}
            >
              <div>
                <Typography category="Default" variant="H6">
                  {t("Driving style")}
                </Typography>
                <div className={classes.centering}>
                  <div className={classes.drivingChartDiv}>
                    {DrivingStyleChart}
                  </div>
                  <div className={classes.drivingChartTextDiv}>
                    <Typography category="Large" variant={mobile ? "H2" : "H1"}>
                      {safeDrivingScore}
                    </Typography>
                    <Typography
                      category="Default"
                      variant="Caption"
                      htmlColor={LightColors.primary["2"]}
                    >
                      {t("Safe driving")}
                    </Typography>
                  </div>
                </div>
                <div className={classes.centering}>
                  <div className={classes.labelDiv}>{totalLabelMarkup}</div>
                </div>
              </div>
              {/* 거리단위 수정 (Leehj) */}
              <div className={classes.statTextDiv}>
                <Typography
                  category="Default"
                  variant="Small"
                  htmlColor={LightColors.primary["2"]}
                >
                  {t("You drove:_", {
                    distance:
                      userSettings?.velocityUnit === "0"
                        ? `${_.round(
                            (drivingDetail?.driving_distance ?? 0) / 1000,
                            2
                          )} km`
                        : `${_.round(
                            (drivingDetail?.driving_distance ?? 0) /
                              1000 /
                              1.609,
                            2
                          )} ${
                            _.round(
                              (drivingDetail?.driving_distance ?? 0) /
                                1000 /
                                1.609,
                              2
                            ) > 1
                              ? "miles"
                              : "mile"
                          }`,
                    time: durationToHMS(driveDuration.asSeconds(), "s"),
                  })}
                </Typography>
              </div>
            </div>
          </div>
          <div
            className={clsx(classes.graphOuterDiv, {
              [classes.graphOuterOpenDiv]: openMenu,
              [classes.graphOuterCloseDiv]: !openMenu,
            })}
          >
            <div className={clsx(classes.graphDiv, classes.mb4)}>
              <div className={classes.infoTitleDiv}>
                <Typography category="Default" variant="H6">
                  {t("Inattentive driving")}{" "}
                  {!!camera?.dms_type &&
                    `(${_.chain(inattentiveEvents)
                      .map((v) => v)
                      .sum()
                      .round(1)
                      .value()})`}
                </Typography>
              </div>

              <div className={clsx(classes.centering, classes.pieRootDiv)}>
                <div className={classes.pieChartDiv}>
                  {InattentiveDrivingChart}
                </div>
                {renderInattentiveLabel}
              </div>
            </div>

            <div className={classes.graphDiv}>
              <div className={classes.infoTitleDiv}>
                <Typography category="Default" variant="H6">
                  {t("Reckless driving")} (
                  {_.chain(racklessEvents)
                    .map((v) => v)
                    .sum()
                    .round(1)
                    .value()}
                  )
                </Typography>
              </div>
              <div className={clsx(classes.centering, classes.pieRootDiv)}>
                <div className={classes.pieChartDiv}>
                  {RecklessDrivingChart}
                </div>
                <div className={classes.pieLabelRootDiv}>
                  {renderRecklessLabel()}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className={clsx(classes.graphRootDiv, classes.mb4, {
            [classes.graphRootOpenDiv]: openMenu,
            [classes.graphRootCloseDiv]: !openMenu,
          })}
        >
          <div
            className={clsx(classes.graphOuterDiv, {
              [classes.graphOuterOpenDiv]: openMenu,
              [classes.graphOuterCloseDiv]: !openMenu,
            })}
          >
            {mode === "month" && (
              <div
                className={clsx(classes.graphDiv, classes.mb4)}
                style={{ flex: "unset" }}
              >
                <Typography category="Default" variant="H6">
                  {t("Safe driving trend")}
                </Typography>
                <div style={{ height: 140, marginTop: 24 }}>
                  {DrivingTrendChart}
                </div>
              </div>
            )}
            <div
              className={clsx(classes.graphDiv, {
                [classes.mb4]: mobile,
              })}
              style={{ flex: "unset" }}
            >
              <div className={classes.eventsTrendTitleDiv}>
                <Typography category="Default" variant="H6">
                  {t("Driving events trend")}
                </Typography>
                <div className={classes.eventsTrendSelector}>
                  <Selector
                    t={t}
                    keyValue={shownEvents}
                    dense
                    mobile={mobile}
                    items={trendItems}
                    disabled={totalEventCount === 0}
                    onChange={(item) => setShownEvents(item.key as EventType)}
                  />
                </div>
              </div>

              <div style={{ marginTop: 16 }}>
                {mode === "month"
                  ? MonthlyDrivingEventChart
                  : DailyDrivingEventChart}
              </div>
            </div>
          </div>
          <div
            className={clsx(classes.graphOuterDiv, {
              [classes.graphOuterOpenDiv]: openMenu,
              [classes.graphOuterCloseDiv]: !openMenu,
            })}
          >
            <div className={classes.graphDiv} style={{ flex: "unset" }}>
              <Typography category="Default" variant="H6">
                {t("Driving details")}
              </Typography>
              {drivingDetails}
              <div className={classes.detailBodyDiv}>
                <div className={classes.detailItemDiv}>
                  <Typography category="Default" variant="BodyBold">
                    {t("Inattentive driving")}
                  </Typography>
                  {inattentiveDetailsMarkup}
                </div>
                <div className={classes.detailItemDiv}>
                  <div className={classes.tooltipReck}>
                    <Typography
                      category="Default"
                      variant="BodyBold"
                      style={{ marginRight: 5 }}
                    >
                      {t("Reckless driving")}
                    </Typography>
                    <Tooltip
                      widthLarge
                      title={
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                          dir={theme.direction}
                        >
                          <Typography
                            category="Default"
                            variant="Caption"
                            style={{ marginBottom: 13 }}
                          >
                            {t("Hard acceleration event_")}
                          </Typography>
                          <Typography
                            category="Default"
                            variant="Caption"
                            style={{ marginBottom: 13 }}
                          >
                            {t("Hard braking event_")}
                          </Typography>
                          <Typography
                            category="Default"
                            variant="Caption"
                            style={{ marginBottom: 13 }}
                          >
                            {t("Hard cornering event_")}
                          </Typography>
                          <Typography category="Default" variant="Caption">
                            {t("Overspeed event occurs_")}
                          </Typography>
                        </div>
                      }
                      placement="right"
                    >
                      <InfoIcon className={classes.infoIcon} />
                    </Tooltip>
                  </div>
                  <div className={classes.detailItem}>
                    <Typography
                      category="Default"
                      variant="Small"
                      htmlColor={LightColors.primary["2"]}
                    >
                      {t("Acceleration")}
                    </Typography>
                    <Typography category="Default" variant="Body">
                      {drivingDetail?.acceleration_count ?? 0}
                    </Typography>
                  </div>
                  <div className={classes.detailItem}>
                    <Typography
                      category="Default"
                      variant="Small"
                      htmlColor={LightColors.primary["2"]}
                    >
                      {t("Hard braking")}
                    </Typography>
                    <Typography category="Default" variant="Body">
                      {drivingDetail?.harsh_braking_count ?? 0}
                    </Typography>
                  </div>
                  <div className={classes.detailItem}>
                    <Typography
                      category="Default"
                      variant="Small"
                      htmlColor={LightColors.primary["2"]}
                    >
                      {t("Overspeed")}
                    </Typography>
                    <Typography category="Default" variant="Body">
                      {drivingDetail?.speed_over_count ?? 0}
                    </Typography>
                  </div>
                  <div className={classes.detailItem}>
                    <Typography
                      category="Default"
                      variant="Small"
                      htmlColor={LightColors.primary["2"]}
                    >
                      {t("Sharp turn")}
                    </Typography>
                    <Typography category="Default" variant="Body">
                      {drivingDetail?.sharp_turn_count ?? 0}
                    </Typography>
                  </div>
                </div>
                {!mobile && <div className={classes.detailItemDiv}></div>}
              </div>
            </div>
          </div>
        </div>
      </div>
      {loading && (
        <div className={classes.loadingDiv}>
          <CircularProgress
            className={classes.circularLoading}
            size={48}
            thickness={6}
          />
        </div>
      )}
    </div>
  );
};
