import React, { useCallback, useEffect, useMemo, useState } from "react";
import { EventmapScreen } from "../screens/EventmapScreen";
import { EventShareScreen } from "../screens/app/EventShareScreen";
import { makeStyles, Theme, useTheme } from "@material-ui/core/styles";
import { Route, useLocation, useRouteMatch } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  loadUserPermissions,
  loadUserProfile,
  successLoadUserSettings,
  // setLoginInfo,
  USER,
} from "../features/User/slice";
import { IconButton, Toast, Typography } from "@thingsw/pitta-design-system";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";

import { useTranslation } from "react-i18next";
import { detect } from "detect-browser";
import { useQuery } from "../utils/Hooks";
import { changeDirection, setFontFamily, THEME } from "../features/Theme/slice";
import _ from "lodash";
import { RootState } from "../features/store";
import { closeToast, TOAST } from "../features/Toast/slice";
import { setPermission } from "../features/Permission/slice";
import { loadSubscription, PAYMENT } from "../features/Payment/slice";
import { getPlanFromServiceID } from "../utils/Service";
import { createPermissions } from "../utils/Auth";
import { EventmapExploreScreen } from "../screens/app/EventmapExploreScreen";
import { NotificationScreen } from "../screens/app/NotificationScreen";
import { EventmapVideoScreen } from "../screens/app/EventmapVideoScreen";
import { SubscriptionScreen } from "../screens/app/SubscriptionScreen";
import { Languages } from "@thingsw/pitta-modules";
import { MapViewScreen } from "../screens/app/MapViewScreen";
import { GPSTrackingScreen } from "../screens/app/GPSTrackingScreen";
import { BatteryStatusScreen } from "../screens/app/BatteryStatusScreen";
import { RenewalNotificationScreen } from "../screens/app/renewal2/RenewalNotificationScreen";
import { RenewalEventmapScreen } from "../screens/app/renewal2/RenewalEventmapScreen";
import { setOpenFilter } from "../features/Eventmap/slice";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: "calc(var(--vh, 1vh) * 100)",
    backgroundColor: (props: any) => props.colors.primary["0"],
  },
  header: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    height: 56,
    padding: theme.spacing(2),
    zIndex: 97,
    color: (props: any) => props.colors.primary["1"],
    "& svg": {
      fill: (props: any) => props.colors.primary["1"],
    },
  },
}));

const AppWitoutBottomNavRouter = (props: any) => {
  const { path, isExact } = useRouteMatch();
  const dispatch = useDispatch();
  const location = useLocation();
  const query = useQuery();
  const themeState = useSelector((state: RootState) => state[THEME]);
  const { color, colors } = themeState;
  const classes = useStyles({ color, colors });
  const theme = useTheme();
  const { t, i18n } = useTranslation();
  const {
    loginInfo,
    email,
    tokenType,
    userProfile,
    permissions: perm,
  } = useSelector((state: RootState) => state[USER]);
  const { subscriptionInfo } = useSelector(
    (state: RootState) => state[PAYMENT]
  );
  const { open, message, option, notification } = useSelector(
    (state: RootState) => state[TOAST]
  );

  const [planName, setPlanName] = useState<string>();

  useEffect(() => {
    if (loginInfo && email && tokenType) {
      dispatch(loadUserProfile());
      dispatch(loadUserPermissions());
      dispatch(loadSubscription());
    }
  }, [dispatch, email, loginInfo, tokenType]);

  useEffect(() => {
    if (subscriptionInfo) {
      setPlanName(getPlanFromServiceID(subscriptionInfo.servicePlanID));
    }
  }, [subscriptionInfo]);

  const noHeader = useMemo(() => {
    const header = JSON.parse(query.get("no_header") ?? "false");
    if (header) {
      return header;
    }

    const isExplore = location.pathname.indexOf("/explore") > -1;
    if (isExplore) {
      return true;
    }

    const isSubscription = location.pathname.indexOf("/subscription") > -1;
    if (isSubscription) {
      return true;
    }

    if (location.pathname.indexOf("/map-view") > -1) {
      return true;
    }
    if (location.pathname.indexOf("/gps-tracking") > -1) {
      return true;
    }
    const isBatteryStatus = location.pathname.indexOf("/battery-status") > -1;
    if (isBatteryStatus) {
      return true;
    }

    return false;
  }, [location.pathname, query]);

  const permissions = useMemo(() => {
    console.log(
      "Eventmap",
      "createPermissions",
      userProfile,
      perm,
      subscriptionInfo,
      planName
    );
    return createPermissions({
      userProfile,
      perm,
      subscriptionInfo,
      planName,
    });
  }, [perm, planName, subscriptionInfo, userProfile]);

  useEffect(() => {
    dispatch(setPermission(permissions));
  }, [dispatch, permissions]);

  useEffect(() => {
    let speedUnit = query.get("speed_unit") ?? "0";

    dispatch(
      successLoadUserSettings({
        velocityUnit: speedUnit,
        mapType: "0",
      })
    );
  }, [dispatch, query]);

  useEffect(() => {
    let lang = query.get("lang");
    if (!_.includes(_.keys(Languages), lang)) {
      lang = "en";
    }
    switch (lang) {
      case "ar":
        i18n.changeLanguage("ar");
        dispatch(changeDirection("rtl"));
        dispatch(setFontFamily("Noto Naskh Arabic UI"));
        break;
      case "de":
      case "es":
      case "fi":
      case "fr":
      case "id":
      case "it":
      case "nb":
      case "nl":
      case "pl":
      case "ru":
      case "vi":
        i18n.changeLanguage(lang);
        dispatch(setFontFamily("Roboto"));
        break;
      case "th":
        i18n.changeLanguage(lang);
        dispatch(setFontFamily("Noto Sans Thai UI"));
        break;
      case "ja":
        i18n.changeLanguage(lang);
        dispatch(setFontFamily("Noto Sans JP"));
        break;
      case "ko":
        i18n.changeLanguage(lang);
        dispatch(setFontFamily("Noto Sans KR"));
        break;
      case "zh":
      case "sc":
        i18n.changeLanguage("sc");
        dispatch(setFontFamily("Noto Sans SC"));
        break;
      default:
        i18n.changeLanguage("en");
        dispatch(setFontFamily("Roboto"));
    }
  }, [dispatch, i18n, query]);

  // useEffect(() => {
  //   if (
  //     query.has("email") &&
  //     query.has("user_token") &&
  //     query.has("token_type")
  //   ) {
  //     dispatch(
  //       setLoginInfo({
  //         //@ts-ignore
  //         email: query.get("email"),
  //         //@ts-ignore
  //         user_token: query.get("user_token"),
  //         //@ts-ignore
  //         token_type: query.get("token_type"),
  //       })
  //     );
  //   }
  // }, [dispatch, query]);

  const handleBack = useCallback((reason?: string) => {
    const browser = detect();
    if (browser?.name === "ios-webview") {
      //@ts-ignore
      return webkit.messageHandlers.back.postMessage({ reason });
    }
    if (browser?.name === "chromium-webview") {
      //@ts-ignore
      if (window.Sim?.back) {
        //@ts-ignore
        window.Sim.back();
      }

      //@ts-ignore
      if (window.Webviewer?.back) {
        //@ts-ignore
        window.Webviewer.back(JSON.stringify({ reason }));
      }
    }
  }, []);

  const isRenewalEventmap = useMemo(() => {
    return location.pathname.indexOf("/renewal-eventmap") > -1;
  }, [location.pathname]);

  const routes = useMemo(() => {
    console.log("routes", loginInfo, query.get("psn"));
    if (loginInfo) {
      const psn = query.get("psn");
      const rid = query.get("rid");
      const thmRid = query.get("thmRid");
      const type = query.get("type");
      const filename = query.get("filename");
      const videoId = query.get("video_id");
      const showOnboarding = query.get("onboarding");
      return (
        <div
          style={{ display: "flex", flexDirection: "column", height: "100%" }}
        >
          {!noHeader && (
            <div className={classes.header} dir={theme.direction}>
              <div style={{ display: "flex" }}>
                <IconButton onClick={() => handleBack()}>
                  {theme.direction === "rtl" ? (
                    <ArrowForwardIosIcon />
                  ) : (
                    <ArrowBackIosIcon />
                  )}
                </IconButton>
                {isRenewalEventmap && (
                  <div style={{ padding: "0 16px" }}>
                    <Typography category="Default" variant="H6">
                      {i18n.language === "ja" ? t("Event map") : "Event map"}
                    </Typography>
                  </div>
                )}
                {isExact && (
                  <div style={{ padding: "0 16px" }}>
                    <Typography category="Default" variant="H6">
                      {i18n.language === "ja" ? t("Event map") : "Event map"}
                    </Typography>{" "}
                    <span
                      style={{
                        fontFamily: "Georgia",
                        fontSize: 12,
                        fontStyle: "italic",
                        fontWeight: 400,
                        lineHeight: "13.63px",
                        letterSpacing: 0,
                        textAlign: "left",
                        color: "#0095E0",
                      }}
                    >
                      {i18n.language === "ja" ? t("Beta") : "Beta"}
                    </span>
                  </div>
                )}
              </div>
              {isRenewalEventmap && (
                <div>
                  <IconButton
                    onClick={() => {
                      dispatch(setOpenFilter(true));
                    }}
                  >
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clipPath="url(#clip0_8121_82978)">
                        <path d="M6 12.9844V11.0156H18V12.9844H6ZM3 6H21V8.01562H3V6ZM9.98438 18V15.9844H14.0156V18H9.98438Z" />
                      </g>
                      <defs>
                        <clipPath id="clip0_8121_82978">
                          <rect width="24" height="24" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </IconButton>
                </div>
              )}
            </div>
          )}
          <Route exact path={`${path}/eventmap`}>
            <EventmapScreen
              {...props}
              {...permissions}
              app
              noHeader={noHeader}
              hideTour={JSON.parse(query.get("hide_tour") ?? "false")}
            />
          </Route>
          <Route exact path={`${path}/eventmap/explore`}>
            <EventmapExploreScreen
              {...props}
              {...permissions}
              noHeader={noHeader}
              app
            />
          </Route>
          <Route exact path={`${path}/renewal-eventmap`}>
            <RenewalEventmapScreen
              {...props}
              {...permissions}
              app
              noHeader={noHeader}
              hideTour={JSON.parse(query.get("hide_tour") ?? "false")}
            />
          </Route>
          {videoId && (
            <Route exact path={`${path}/eventmap/video`}>
              <EventmapVideoScreen
                {...props}
                {...permissions}
                app
                videoId={parseInt(videoId)}
              />
            </Route>
          )}
          {psn && filename && (
            <Route path={`${path}/eventmap/event-share`}>
              <EventShareScreen
                psn={psn}
                filename={filename}
                rid={rid}
                thmRid={thmRid}
                type={type}
                onClose={handleBack}
              />
            </Route>
          )}
          {psn && (
            <Route path={`${path}/notification-history`}>
              <NotificationScreen />
            </Route>
          )}
          {psn && (
            <Route path={`${path}/renewal-notification-history`}>
              <RenewalNotificationScreen />
            </Route>
          )}

          <Route path={`${path}/subscription`}>
            <SubscriptionScreen />
          </Route>

          <Route path={`${path}/map-view`}>
            <MapViewScreen
              showOnboarding={showOnboarding === "true" ? true : false}
            />
          </Route>
          {psn && (
            <Route path={`${path}/gps-tracking`}>
              <GPSTrackingScreen psn={psn} planName={planName} />
            </Route>
          )}
          {psn && (
            <Route path={`${path}/battery-status`}>
              <BatteryStatusScreen psn={psn} />
            </Route>
          )}
        </div>
      );
    }
  }, [
    classes.header,
    dispatch,
    handleBack,
    i18n.language,
    isExact,
    isRenewalEventmap,
    loginInfo,
    noHeader,
    path,
    permissions,
    planName,
    props,
    query,
    t,
    theme.direction,
  ]);

  return (
    <div className={classes.root}>
      {routes}

      <Toast
        open={open}
        message={(message && t(message, option)) ?? ""}
        onClose={(event, reason) => {
          if (reason === "clickaway") {
            return;
          }
          dispatch(closeToast());
        }}
        // mantis - 11079, app과 모바일에서 notification설정 변경시 토스트팝업 가운데로 출력되도록 수정 (Leehj)
        notification={notification}
      />
    </div>
  );
};

export default AppWitoutBottomNavRouter;
