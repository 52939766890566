import { PortalProps } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { IconButton, Modal } from '@thingsw/pitta-design-system';
import CloseIcon from '@material-ui/icons/Close';
import React, { useEffect, useMemo } from 'react';
// import { useTranslation } from "react-i18next";
import { useSelector } from 'react-redux';
import { RootState } from '../../features/store';
import {
  Webviewer,
  ICameraInfo,
  Direction,
  enableRotate,
} from '@thingsw/pitta-modules';
import { LiveViewPanelWrapper } from '../cameras/LiveViewPanelWrapper';
import { PERMISSION } from '../../features/Permission/slice';
import { THEME } from '../../features/Theme/slice';
// import { USER } from '../../features/User/slice';

const useStyles = makeStyles((theme: Theme) => ({
  modalRoot: {
    margin: 0,
    paddingLeft: 0,
    paddingRight: 0,
    position: 'relative',
    overflow: 'hidden',
    [theme.breakpoints.up(Webviewer.mobile)]: {
      minWidth: 470,
      maxWidth: 593,
      borderRadius: 12,
      border: 'unset',
    },
  },
  modalContentDiv: {
    padding: 0,
    // 모바일에서 가로스크롤 생겨서 hidden(Leehj)
    overflowY: 'hidden',
    [theme.breakpoints.up(Webviewer.mobile)]: {
      // paddingTop: 8,
      overflowY: 'auto',
    },
  },
  modalTitle: {
    display: 'flex',
    alignItems: 'center',
    boxShadow: 'border-box',
    minHeight: 20,
    ...(theme.direction === 'rtl'
      ? { padding: theme.spacing(2, 2, 1.5, 1.625) }
      : { padding: theme.spacing(2, 1.625, 1.5, 2) }),
    textOverflow: 'ellipsis',
    [theme.breakpoints.up(Webviewer.mobile)]: {
      ...(theme.direction === 'rtl'
        ? { padding: theme.spacing(2.375, 2, 2, 2) }
        : { padding: theme.spacing(2.375, 2, 2, 2) }),
    },
  },
  iconBtn: {
    padding: '0 4px',
  },
  paperScrollPaper: {
    maxHeight: '100%',
  },
  headingText: {
    fontSize: '16px !important',
  },
  headerBtnStyle: {
    right: 10,
    [theme.breakpoints.up(Webviewer.mobile)]: {
      right: 13,
    },
  },
}));

interface LiveViewMapboxModalProps {
  open: boolean;
  camera?: ICameraInfo;
  isPublic?: boolean;
  dir?: Direction;
  notification?: boolean;
  onClose: () => void;
  container?: PortalProps['container'];
  noMargin?: boolean;
  mobile?: boolean;
  fullScreen?: boolean;
  app?: boolean;
}

export const LiveViewMapboxModal = ({
  open,
  camera,
  isPublic,
  dir,
  notification,
  onClose,
  container,
  noMargin,
  mobile,
  fullScreen = false,
  app,
}: LiveViewMapboxModalProps) => {
  // const { t } = useTranslation();
  const { permission } = useSelector((state: RootState) => state[PERMISSION]);
  const classes = useStyles();
  const themeState = useSelector((state: RootState) => state[THEME]);

  const headerBtnMarkup = useMemo(() => {
    return (
      <>
        <IconButton className={classes.iconBtn} onClick={onClose}>
          <CloseIcon htmlColor={themeState.colors.primary['1']} />
        </IconButton>
      </>
    );
  }, [classes.iconBtn, onClose, themeState.colors.primary]);

  useEffect(() => {
    if (open) {
      enableRotate();
    }
  }, [open]);

  console.log('LiveViewMapboxModal', 'open', open, 'camera', camera);
  if (open && camera) {
    console.log('open modal');
    return (
      <Modal
        open={open}
        onClose={onClose}
        fullSize={mobile}
        fullScreen={fullScreen}
        scroll='paper'
        className={classes.modalRoot}
        contentClassName={classes.modalContentDiv}
        titleClassName={classes.modalTitle}
        paperScrollPaperClassName={classes.paperScrollPaper}
        headingTextClassName={classes.headingText}
        headerBtnClassName={classes.headerBtnStyle}
        heading={
          camera.share_dev_name === 'off' && isPublic ? 'BlackVue Live' : '  '
        }
        headerBtn={headerBtnMarkup}
        content={
          <>
            <LiveViewPanelWrapper
              isEvent
              camera={camera}
              isPublic={isPublic}
              //8868 - DMC 이벤트의 경우 Interior영상 노출되도록 수정
              dir={dir}
              notification={notification}
              noMargin={noMargin}
              ignoreRemove
              modal
              // paddingTop값을 변경하기 위함
              mapboxModal={!mobile}
              app={app}
              onBack={onClose}
              {...permission}
            />
          </>
        }
        container={container}
      />
    );
  }
  return <></>;
};
