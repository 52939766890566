import CloudIcon from "@material-ui/icons/Cloud";
import CloudOffIcon from "@material-ui/icons/CloudOff";
import { Color, ICameraInfo, LightColors } from "@thingsw/pitta-modules";

import moment from "moment";
import React from "react";

export const getRenewalCloudConnectivityIcon = (
  camera: ICameraInfo,
  colors?: Color,
  reverse?: boolean,
  dark?: boolean
) => {
  const c = colors ?? LightColors;
  // let loggined = true;

  // if (!camera.login_date) {
  //   loggined = false;
  // } else {
  //   loggined = !moment(camera.login_date).isBefore(moment.utc(0));
  // }

  let htmlColor = c.primary["8"];

  if (camera.active === "off") {
    if (reverse) {
      htmlColor = dark ? c.primary["2"] : c.primary["5"];
    } else {
      htmlColor = dark ? c.primary["2"] : c.primary["2"];
    }
  }

  return <CloudIcon style={{ margin: 4 }} htmlColor={htmlColor} />;
};

export const getCloudConnectivityIcon = (
  camera: ICameraInfo,
  colors?: Color,
  reverse?: boolean
) => {
  const c = colors ?? LightColors;
  let loggined = true;

  if (!camera.login_date) {
    loggined = false;
  } else {
    loggined = !moment(camera.login_date).isBefore(moment.utc(0));
  }

  if (camera.reg_category === "wifi" || !loggined) {
    return <CloudOffIcon style={{ margin: 4 }} htmlColor={c.primary["3"]} />;
  }

  let htmlColor = c.primary["8"];

  if (camera.active === "off") {
    if (reverse) {
      htmlColor = c.primary["4"];
    } else {
      htmlColor = c.primary["2"];
    }
  }

  return <CloudIcon style={{ margin: 4 }} htmlColor={htmlColor} />;
};

export const getCloudConnectivityText = (camera: ICameraInfo) => {
  let loggined = true;

  if (!camera.login_date) {
    loggined = false;
  } else {
    loggined = !moment(camera.login_date).isBefore(moment.utc(0));
  }

  if (camera.reg_category === "wifi" || !loggined) {
    return "";
  }

  return camera.active === "on" ? "Cloud connected" : "Cloud disconnected";
};
