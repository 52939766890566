import { MapboxLocationMap } from '@thingsw/pitta-liveview-module';
import { ICameraInfo, IGPSLocation, IGeofence } from '@thingsw/pitta-modules';
import React, { Dispatch, SetStateAction, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../features/store';
import { CAMERA } from '../../features/Camera/slice';
import { LOCATION_WS } from '../../features/LocationWS/slice';
import { PUSH_EVENT } from '../../features/PushEvent/slice';
import {
  THEME,
  setExitFullscreen,
  setFullscreen,
} from '../../features/Theme/slice';
import { USER } from '../../features/User/slice';
import { GEOFENCE } from '../../features/Geofence/slice';
import { GROUP, loadGroups } from '../../features/Group/slice';

interface MapboxLocationMapWrapperProps {
  mapRef?: React.RefObject<HTMLDivElement>;
  geofences?: IGeofence[];
  filteredCams?: ICameraInfo[] | undefined;
  onLiveView?: (psn: string, isPublic: boolean) => void;
  focusCam?: ICameraInfo | undefined;
  location?: IGPSLocation;
  playback?: boolean;
  liveView?: boolean;
  app?: boolean;
  publicIcon?: boolean;
  onClose?: () => void;
  clearFocusCam?: () => void;
  onFailedLocation?: () => void;
  setIsMapboxFullscreen?: Dispatch<SetStateAction<boolean>>;
  requestZoneAllDevice?: object;
  resizeFlag?: boolean;
  renewal?: boolean;
  locPerm?: boolean;
}
export const MapboxLocationMapWrapper = ({
  mapRef,
  geofences,
  filteredCams,
  onLiveView,
  focusCam,
  location,
  playback,
  liveView,
  publicIcon = true,
  app,
  resizeFlag,
  onClose,
  onFailedLocation,
  clearFocusCam,
  setIsMapboxFullscreen,
  requestZoneAllDevice,
  renewal,
  locPerm,
}: MapboxLocationMapWrapperProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const cameraState = useSelector((state: RootState) => state[CAMERA]);
  const userState = useSelector((state: RootState) => state[USER]);
  const locationState = useSelector((state: RootState) => state[LOCATION_WS]);
  const groupState = useSelector((state: RootState) => state[GROUP]);
  const geofenceState = useSelector((state: RootState) => state[GEOFENCE]);
  const themeState = useSelector((state: RootState) => state[THEME]);
  const pushEventState = useSelector((state: RootState) => state[PUSH_EVENT]);

  useEffect(() => {
    dispatch(loadGroups());
  }, [dispatch]);

  return (
    <MapboxLocationMap
      publicIcon={publicIcon}
      fullscreenIcon={!renewal}
      app={app}
      mapRef={mapRef}
      geofences={geofences}
      filteredCams={filteredCams}
      onLiveView={onLiveView}
      focusCam={focusCam}
      clearFocusCam={clearFocusCam}
      cameraState={cameraState}
      pushEventState={pushEventState}
      userState={userState}
      locationState={locationState}
      t={t}
      onSetFullscreen={(v) => {
        dispatch(setFullscreen(v));
      }}
      onExitFullScreen={(v) => {
        dispatch(setExitFullscreen(v));
      }}
      onFailedLocation={onFailedLocation}
      setIsMapboxFullscreen={setIsMapboxFullscreen}
      geofenceState={geofenceState}
      groupState={groupState}
      themeState={themeState}
      location={location}
      playback={playback}
      liveView={liveView}
      onClose={onClose}
      requestZoneAllDevice={requestZoneAllDevice}
      resizeFlag={resizeFlag}
      renewal={renewal}
      locPerm={locPerm}
    />
  );
};
