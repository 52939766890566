import { PayloadAction } from "@reduxjs/toolkit";
import { call, put, select, takeLatest } from "redux-saga/effects";
import { RootState } from "../store";
import { USER } from "../User/slice";
import {
  IDrivingDailyReport,
  IDrivingMonthlyReport,
  loadDailyReport,
  loadMonthlyReport,
  PeriodRequest,
  successLoadDailyReport,
  successLoadMonthlyReport,
} from "./slice";
import * as API from "../../apis";
import { IUserLoginInfo, RESULT_CODE } from "@thingsw/pitta-modules";

function* handleLoadMonthlyReport({ payload }: PayloadAction<PeriodRequest>) {
  try {
    const email = yield select((state: RootState) => state[USER].email);
    const { user_token } = (yield select(
      (state: RootState) => state[USER].loginInfo
    )) as IUserLoginInfo;
    const resp = yield call(
      API.getDrivingReport,
      email,
      user_token,
      payload.psn,
      payload.date,
      payload.interval,
      payload.period
    );

    const { resultcode, response } = resp.data as {
      resultcode: RESULT_CODE;
      response: IDrivingMonthlyReport;
    };
    if (resultcode === "BC_ERR_OK") {
      yield put(successLoadMonthlyReport(response));
    }
  } catch (err) {
    console.error(err);
  }
}

function* handleLoadDailyReport({ payload }: PayloadAction<PeriodRequest>) {
  try {
    const email = yield select((state: RootState) => state[USER].email);
    const { user_token } = (yield select(
      (state: RootState) => state[USER].loginInfo
    )) as IUserLoginInfo;
    const resp = yield call(
      API.getDrivingReport,
      email,
      user_token,
      payload.psn,
      payload.date,
      payload.interval,
      payload.period
    );

    const { resultcode, response } = resp.data as {
      resultcode: RESULT_CODE;
      response: IDrivingDailyReport;
    };
    if (resultcode === "BC_ERR_OK") {
      console.log("response", response);
      yield put(successLoadDailyReport(response));
    }
  } catch (err) {
    console.error(err);
  }
}

export function* watchReport() {
  yield takeLatest(loadDailyReport, handleLoadDailyReport);
  yield takeLatest(loadMonthlyReport, handleLoadMonthlyReport);
}
