import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  IVODState,
  VODListRequest,
  IIoTVOD,
  getDirectionVOD,
  getEvent,
  IVOD,
  ICloudVODList,
  VODTokenRequest,
  IVODToken,
  IEventVODList,
  ICameraVOD,
  IFileUploadInfo,
  IDelUploadList,
  Hashtag,
  ICameraInfo,
  EventCode,
} from '@thingsw/pitta-modules';
import _ from 'lodash';
import moment from 'moment';

let initialState: IVODState = {
  loading: false,
  // deleteLoading: false,

  tokens: {},
  thm: {},
  tokenRequests: {},
  thmRequests: {},
  uploadList: [],
  removeUploadList: [],
  hashtags: [],
  selectedHashtags: [],
  cancelDelete: false,
  downloadVODList: [],
  closeDownload: false,
  closeUpload: false,
  initUpload: false,
  initDownload: false,
  requestThumb: true,
  cloudLimit: false,
};

const slice = createSlice({
  name: 'vod',
  initialState,
  reducers: {
    clearVOD: (state) => {
      state.loading = false;
      state.vodList = undefined;
      state.type = undefined;
      state.cloudVodList = undefined;
      state.eventVodList = undefined;
      state.tokens = {};
      state.thm = {};
      state.tokenRequests = {};
      state.thmRequests = {};
      state.uploadList = [];
      state.downloadVODList = [];
      state.cancelDelete = false;
      state.cloudLimit = false;
    },
    clearLoading: (state) => {
      state.loading = false;
    },
    setCancelDelete: (state, { payload }: PayloadAction<boolean>) => {
      state.cancelDelete = payload;
    },
    loadVODList: (state, _action: PayloadAction<VODListRequest>) => {
      state.loading = true;
      state.type = undefined;
    },
    successloadVODList: (state, { payload }: PayloadAction<IIoTVOD[]>) => {
      const getVODInfo = (val: IIoTVOD[], vod: string | undefined) => {
        if (!vod) {
          return;
        }
        const cvod = _.find(val, (v) => v.filename === vod);
        const filename = vod.split('.')[0];
        const names = filename.split('_');
        const time = moment(`${names[0]} ${names[1]}`);
        const ev = names[2].split('');

        const front = _.find(val, (v) => {
          const filename = v.filename.split('.')[0];
          const names = filename.split('_');
          const ev = names[2].split('');
          const dir = getDirectionVOD(ev[1]);
          return dir === 'Front';
        });

        const rear = _.find(val, (v) => {
          const filename = v.filename.split('.')[0];
          const names = filename.split('_');
          const ev = names[2].split('');
          const dir = getDirectionVOD(ev[1]);
          return dir === 'Rear';
        });

        const interior = _.find(val, (v) => {
          const filename = v.filename.split('.')[0];
          const names = filename.split('_');
          const ev = names[2].split('');
          const dir = getDirectionVOD(ev[1]);
          return dir === 'Interior';
        });

        const option = _.find(val, (v) => {
          const filename = v.filename.split('.')[0];
          const names = filename.split('_');
          const ev = names[2].split('');
          const dir = getDirectionVOD(ev[1]);
          return dir === 'Option';
        });

        return {
          filename: vod,
          direction: getDirectionVOD(ev[1]),
          event: getEvent(ev[0]),
          eventCode: ev[0] as EventCode,
          lowFilename: `${names[0]}_${names[1]}_${ev[0]}${ev[1]}S.mp4`,
          time,
          hasLow: true,
          hasOriginal: true,
          hasFront: !!front,
          hasRear: !!rear,
          hasInterior: !!interior,
          hasOption: !!option,

          mainsize: parseInt(cvod?.mainsize ?? '0'),
          subsize: parseInt(cvod?.subsize ?? '0'),
        } as IVOD;
      };
      state.vodList = _.chain(payload)
        .filter((vod) => !!vod.filename)
        .groupBy((vod) => {
          const filename = vod.filename.split('.')[0];
          const names = filename.split('_');
          return `${names[0]}_${names[1]}`;
        })
        .map((val, key) => {
          const vod = _.chain(val)
            .sortBy((v) => {
              const filename = v.filename.split('.')[0];
              const names = filename.split('_');
              const ev = names[2].split('');
              const dir = getDirectionVOD(ev[1]);
              // 10127 - option 영상이 썸네일로 나오는 문제 수정
              if (dir === 'Front') {
                return 0;
              }
              if (dir === 'Rear') {
                return 1;
              }
              if (dir === 'Interior' || dir === 'Option') {
                return 2;
              }
              return 0;
            })
            .first()
            .value().filename;
          const result = getVODInfo(val, vod);

          const front = _.find(val, (v) => {
            const filename = v.filename.split('.')[0];
            const names = filename.split('_');
            const ev = names[2].split('');
            const dir = getDirectionVOD(ev[1]);
            return dir === 'Front';
          });

          const rear = _.find(val, (v) => {
            const filename = v.filename.split('.')[0];
            const names = filename.split('_');
            const ev = names[2].split('');
            const dir = getDirectionVOD(ev[1]);
            return dir === 'Rear';
          });

          const interior = _.find(val, (v) => {
            const filename = v.filename.split('.')[0];
            const names = filename.split('_');
            const ev = names[2].split('');
            const dir = getDirectionVOD(ev[1]);
            return dir === 'Interior';
          });

          const option = _.find(val, (v) => {
            const filename = v.filename.split('.')[0];
            const names = filename.split('_');
            const ev = names[2].split('');
            const dir = getDirectionVOD(ev[1]);
            return dir === 'Option';
          });

          return {
            ...result,
            front: getVODInfo(val, front?.filename),
            rear: getVODInfo(val, rear?.filename),
            interior: getVODInfo(val, interior?.filename),
            option: getVODInfo(val, option?.filename),
          } as IVOD;
        })
        .sortBy('time')
        .value();
      state.loading = false;
    },
    loadCloudVODList: (state, _action: PayloadAction<string>) => {
      state.loading = true;
      state.type = undefined;
    },
    successloadCloudVODList: (
      state,
      { payload }: PayloadAction<ICloudVODList>
    ) => {
      state.loading = false;
      state.cloudVodList = {
        ...payload,
        filelist: _.sortBy(payload.filelist, 'time'),
      };
    },
    loadCloudVODFile: (state, _action: PayloadAction<VODTokenRequest>) => {},
    loadCloudVODThm: (
      state,
      _action: PayloadAction<{ psn: string; filename: string; thmName: string }>
    ) => {},
    deleteCloudVODFile: (
      state,
      action: PayloadAction<{
        camera: ICameraInfo;
        filenames: string[];
        vodCount?: number;
      }>
    ) => {
      state.loading = true;
      state.type = action.type;
    },
    successDeleteCloudVODFile: (
      state,
      { payload }: PayloadAction<string[]>
    ) => {
      state.loading = false;
      state.type = undefined;
      if (state.cloudVodList) {
        state.cloudVodList = {
          ...state.cloudVodList,
          filelist: _.filter(
            state.cloudVodList.filelist,
            (f) => !_.includes(payload, f.filename)
          ),
        };
      }
    },
    loadVODToken: (state, _action: PayloadAction<VODTokenRequest>) => {},
    resetTokenRequest: (state) => {
      state.tokenRequests = {};
    },
    updateTokenRequest: (
      state,
      { payload }: PayloadAction<{ filename: string; requested: boolean }>
    ) => {
      state.tokenRequests = {
        ...state.tokenRequests,
        [payload.filename]: payload.requested,
      };
    },
    loadUrgentVODToken: (
      state,
      { payload }: PayloadAction<VODTokenRequest>
    ) => {
      state.tokenRequests = {
        ...state.tokenRequests,
        [payload.filename]: true,
      };
    },
    successLoadVODToken: (
      state,
      { payload }: PayloadAction<{ filename: string; token: IVODToken }>
    ) => {
      state.tokens = { ...state.tokens, [payload.filename]: payload.token };
    },
    loadThumbnail: (
      state,
      _action: PayloadAction<VODTokenRequest & VODListRequest>
    ) => {},
    resetThmRequest: (state) => {
      state.thmRequests = {};
    },
    updateThmRequest: (
      state,
      { payload }: PayloadAction<{ filename: string; requested: boolean }>
    ) => {
      state.thmRequests = {
        ...state.thmRequests,
        [payload.filename]: payload.requested,
      };
    },
    successLoadThumbnail: (
      state,
      { payload }: PayloadAction<{ filename: string; thm: string }>
    ) => {
      state.thm = { ...state.thm, [payload.filename]: payload.thm };
    },
    loadEventVODList: (state, _action: PayloadAction<string>) => {
      state.loading = true;
      state.type = undefined;
    },
    successLoadEventVODList: (
      state,
      // mantis - 11259, playback > liveEventUpload 탭 에서도 사용량을 보여주기 위해 타입 변경 (Leehj)
      { payload }: PayloadAction<IEventVODList>
    ) => {
      state.loading = false;
      state.eventVodList = payload;
    },
    loadEventThumbnail: (
      state,
      _action: PayloadAction<{ psn: string; rid: string; filename: string }>
    ) => {},
    deleteEventVODFile: (
      state,
      action: PayloadAction<{
        psn: string;
        vods: ICameraVOD[];
        fileLength: number;
      }>
    ) => {
      state.loading = true;
      state.type = action.type;
    },
    successDeleteEventVODFile: (
      state,
      { payload }: PayloadAction<string[]>
    ) => {
      // mantis - 11259, playback > liveEventUpload 탭 에서도 사용량을 보여주기 위해 타입 변경하면서 맞추어 수정된 코드 (Leehj)
      if (state.eventVodList) {
        state.eventVodList = {
          ...state.eventVodList,
          filelist: _.filter(
            state.eventVodList?.filelist,
            (vod) => !_.includes(payload, vod.rid)
          ),
        };
      }
    },
    doneDeleteEventVODFiles: (state) => {
      state.loading = false;
      state.type = undefined;
    },
    moveEventVODFiles: (
      state,
      action: PayloadAction<{ psn: string; rids: string[] }>
    ) => {
      state.loading = true;
      state.type = action.type;
    },
    uploadVODFile: (
      state,
      action: PayloadAction<{ camera: ICameraInfo; filename: string }>
    ) => {
      // playback -> cloud로 영상 업로드시, 로딩 조건 필요없음
      // 이 조건으로 인해서 8502 이슈 발생하여 주석처리
      // state.loading = true;
      state.type = action.type;
      if (!state.progressDisplayOrder) {
        state.progressDisplayOrder = 'column-reverse';
        state.initUpload = true;
      }
    },
    // mantis - 12508, 영상 업로드시 클라우드 사용량 초과 팝업 (Leehj)
    setCloudLimit: (state, { payload }: PayloadAction<boolean>) => {
      state.cloudLimit = payload;
    },
    loadVODUploadList: (state, action: PayloadAction<ICameraInfo>) => {},
    successLoadVODUploadList: (
      state,
      { payload }: PayloadAction<IFileUploadInfo[]>
    ) => {
      if (payload.length > 0) {
        if (!state.progressDisplayOrder) {
          state.progressDisplayOrder = 'column-reverse';
        } else if (!state.initUpload) {
          state.closeDownload = true;
        }
        state.initUpload = true;
      }

      const uploadList = _.map(state.uploadList, (up) => ({
        ...up,
        error: undefined,
      }));
      const done = _.differenceBy(uploadList, payload, 'filename')
        .filter((f) => f.current !== f.total)
        .map((f) => ({
          ...f,
          current: f.total,
        }));

      const merged = _.merge(
        _.keyBy(uploadList, 'filename'),
        _.keyBy(payload, 'filename')
      );
      const merged2 = _.merge(
        _.keyBy(merged, 'filename'),
        _.keyBy(done, 'filename')
      );

      // console.log("done", uploadList, payload, done, merged, merged2);

      state.uploadList = _.values(merged2);
    },
    failLoadVODUploadList: (state) => {
      state.loading = false;
      state.uploadList = _.map(state.uploadList, (up) => {
        if (up.current !== up.total) {
          up.error = true;
        }
        return up;
      });
    },
    deleteVODUploadList: (
      state,
      action: PayloadAction<{
        camera: ICameraInfo;
        removelist: IDelUploadList[];
      }>
    ) => {
      // mantis - 12597, 업로드 취소시 빈 화면 나오는 이슈 발생하여 주석처리 (Leehj)
      // state.loading = true;
      state.type = action.type;
    },

    successDelUploadList: (state) => {
      // mantis - 12597, 업로드 취소시 빈 화면 나오는 이슈 발생하여 주석처리 (Leehj)
      // state.loading = false;
      // mantis - 12597, 업로드 도중 취소 진행 시 다시 업로드 진행시 취소된 파일 남아있던 이슈 수정 (Leehj)
      state.uploadList = [];
    },
    downloadVOD: (state, { payload }: PayloadAction<IVOD[]>) => {
      if (!state.progressDisplayOrder) {
        state.progressDisplayOrder = 'column';
      } else if (!state.initDownload) {
        state.closeUpload = true;
      }
      state.initDownload = true;
      state.downloadVODList = _.uniqBy(
        [...state.downloadVODList, ...payload],
        (vod) => `${vod.filename}_${vod.psn}_${vod.quality}`
      );
    },
    updateVOD: (state, { payload }: PayloadAction<IVOD>) => {
      state.downloadVODList = _.map(state.downloadVODList, (vod) => {
        if (
          vod.filename === payload.filename &&
          vod.psn === payload.psn &&
          vod.quality === payload.quality
        ) {
          return _.merge(vod, payload);
        }
        return vod;
      });
    },
    clearDownloadVOD: (state) => {
      state.downloadVODList = [];
    },
    clearBuffer: () => {},
    setLoading: (state) => {
      state.loading = true;
    },
    loadHashtags: () => {},
    successLoadHashtags: (state, { payload }: PayloadAction<Hashtag[]>) => {
      state.hashtags = payload;
    },
    setSelectedHashtags: (state, { payload }: PayloadAction<number[]>) => {
      state.selectedHashtags = payload;
    },
    setRequestThumb: (state, { payload }: PayloadAction<boolean>) => {
      state.requestThumb = payload;
    },
  },
});

export const {
  clearVOD,
  setCancelDelete,
  loadCloudVODFile,
  loadCloudVODThm,
  loadCloudVODList,
  successloadCloudVODList,
  deleteCloudVODFile,
  successDeleteCloudVODFile,
  loadVODList,
  successloadVODList,
  resetTokenRequest,
  updateTokenRequest,
  resetThmRequest,
  updateThmRequest,
  loadUrgentVODToken,
  loadVODToken,
  successLoadVODToken,
  loadThumbnail,
  successLoadThumbnail,
  loadEventVODList,
  successLoadEventVODList,
  loadEventThumbnail,
  deleteEventVODFile,
  successDeleteEventVODFile,
  doneDeleteEventVODFiles,
  moveEventVODFiles,
  uploadVODFile,
  loadVODUploadList,
  successLoadVODUploadList,
  deleteVODUploadList,
  successDelUploadList,
  failLoadVODUploadList,
  clearLoading,
  downloadVOD,
  updateVOD,
  clearDownloadVOD,
  clearBuffer,
  setLoading,
  loadHashtags,
  successLoadHashtags,
  setSelectedHashtags,
  setRequestThumb,
  setCloudLimit,
} = slice.actions;
export const VOD = slice.name;
export default slice.reducer;
