import { useMediaQuery } from "@material-ui/core";
import { makeStyles, Theme, useTheme } from "@material-ui/core/styles";
import { Modal, Typography } from "@thingsw/pitta-design-system";
import { Webviewer } from "@thingsw/pitta-modules";
import moment from "moment";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RootState } from "../../features/store";
import { THEME } from "../../features/Theme/slice";
import clsx from "clsx";

const useStyles = makeStyles((theme: Theme) => ({
  modalDiv: {
    maxWidth: 500,
  },
  modalAppDiv: {
    border: "none",
    maxWidth: 500,
    "& > :first-child": {
      display: "none",
    },
  },
  modalContentDiv: {
    border: "none",
    padding: "58px 16px 0",
    textAlign: "center",
    alignSelf: "center",
    [theme.breakpoints.up("md")]: {
      padding: "38px 24px 20px",
      alignSelf: "normal",
    },
  },

  contentCard: {
    width: "100%",
    maxWidth: 454,
    maxHeight: 205,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    borderRadius: 12,
    padding: "24px 0 24px",
    marginBottom: 13,
    [theme.breakpoints.up("sm")]: {
      width: 454,
    },
  },

  contentTop: {
    margin: "16px 0px 24px",
    [theme.breakpoints.up("sm")]: {
      margin: "16px 0px 25px",
    },
  },

  contentBottom: {
    padding: "0px 26px 6px",

    [theme.breakpoints.up("sm")]: {
      padding: "0px 64px 6px",
    },
    [theme.breakpoints.up("md")]: {
      padding: "0px 80px 6px",
    },
  },

  link: {
    cursor: "pointer",
    color: (props: any) => props.colors.primary["7"],
    "&:hover": {
      color: (props: any) => props.colors.primary["8"],
    },
    "&:visited": {
      color: (props: any) => props.colors.primary["7"],
    },
  },

  modalBtn: {
    padding: "14px 14px 16px 0px",
    [theme.breakpoints.up("md")]: {
      alignSelf: "center",
      padding: "0px 22px 24px 26px",
    },
  },

  modalImg: {
    marginBottom: 12,
    [theme.breakpoints.up("md")]: {},
  },
}));

// start 점검 시작 시간, end 점검 끝나는 시간
interface MaintenanceProps {
  start: moment.Moment;
  end: moment.Moment;
  open: boolean;
  close?: boolean;
  onClose: () => void;
  app?: boolean;
}

export const ServerMaintenanceModal = (props: MaintenanceProps) => {
  const { start, end, close, onClose, open, app } = props;
  const theme = useTheme() as Theme;
  const { t } = useTranslation();
  const mobile = useMediaQuery(theme.breakpoints.down(Webviewer.mobile));
  const { colors, color } = useSelector((state: RootState) => state[THEME]);
  const classes = useStyles({ ...props, colors });

  const tz = useMemo(() => moment.tz.guess(), []);

  const dateMarkup = useMemo(() => {
    const s = moment(start).tz(tz);
    const e = moment(end).tz(tz);
    if (s.isSame(e, "D")) {
      return (
        <Typography
          category="Default"
          variant="BodyBold"
          htmlColor={colors.primary["1"]}
        >
          {moment(start).tz(tz).format("dddd, MMMM D, YYYY")}
        </Typography>
      );
    } else {
      return (
        <>
          <Typography
            category="Default"
            variant="BodyBold"
            htmlColor={colors.primary["1"]}
          >
            {moment(start).tz(tz).format("dddd, MMMM D, YYYY")}
          </Typography>
          <br />
          <Typography
            category="Default"
            variant="BodyBold"
            htmlColor={colors.primary["1"]}
          >
            - {moment(end).tz(tz).format("dddd, MMMM D, YYYY")}
          </Typography>
        </>
      );
    }
  }, [colors.primary, end, start, tz]);

  return (
    <Modal
      className={clsx({
        [classes.modalDiv]: !app,
        [classes.modalAppDiv]: app,
      })}
      contentClassName={classes.modalContentDiv}
      open={open}
      onClose={onClose}
      content={
        <div>
          <div>
            <Typography
              category="Default"
              variant="H3"
              htmlColor={colors.primary["1"]}
            >
              {t("Cloud Server Maintenance")}
            </Typography>
          </div>
          <div className={classes.contentTop}>
            <div>
              <Typography
                category="Default"
                variant="Body"
                htmlColor={colors.primary["1"]}
              >
                {t("BlackVue Cloud Service_")}
              </Typography>
            </div>
          </div>
          <div
            className={classes.contentCard}
            style={{
              backgroundColor:
                color === "light"
                  ? "rgba(231, 245, 252, 1)"
                  : "rgba(55, 183, 255, 0.15)",
            }}
          >
            <div>
              <Typography
                category="Default"
                variant="Body"
                htmlColor={colors.primary["7"]}
              >
                {t("We are currently_")}
              </Typography>
            </div>
            <div
              style={{
                margin: "20px 0",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <svg
                width="44"
                height="32"
                viewBox="0 0 44 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11 32C7.96667 32 5.37533 30.95 3.226 28.85C1.07533 26.75 0 24.1833 0 21.15C0 18.55 0.783333 16.2333 2.35 14.2C3.91667 12.1667 5.96667 10.8667 8.5 10.3C9.33333 7.23333 11 4.75 13.5 2.85C16 0.950001 18.8333 0 22 0C25.9 0 29.208 1.358 31.924 4.074C34.6413 6.79133 36 10.1 36 14C38.3 14.2667 40.2087 15.258 41.726 16.974C43.242 18.6913 44 20.7 44 23C44 25.5 43.1253 27.6253 41.376 29.376C39.6253 31.1253 37.5 32 35 32H11ZM11 28H35C36.4 28 37.5833 27.5167 38.55 26.55C39.5167 25.5833 40 24.4 40 23C40 21.6 39.5167 20.4167 38.55 19.45C37.5833 18.4833 36.4 18 35 18H32V14C32 11.2333 31.0253 8.87467 29.076 6.924C27.1253 4.97467 24.7667 4 22 4C19.2333 4 16.8753 4.97467 14.926 6.924C12.9753 8.87467 12 11.2333 12 14H11C9.06667 14 7.41667 14.6833 6.05 16.05C4.68333 17.4167 4 19.0667 4 21C4 22.9333 4.68333 24.5833 6.05 25.95C7.41667 27.3167 9.06667 28 11 28Z"
                  fill="#2EA8E5"
                />
                <path
                  d="M28.7254 19.2585C28.337 18.9649 28.163 18.4867 28.163 17.9998C28.163 17.517 28.34 17.0306 28.7238 16.7378L29.8377 15.8878C29.9855 15.7598 30.0347 15.5518 29.9362 15.3758L28.2944 12.6078C28.1958 12.4318 27.9824 12.3678 27.7854 12.4318L26.4771 12.9458C26.0248 13.1236 25.5223 13.0443 25.1009 12.8023C24.6596 12.5489 24.3078 12.1356 24.2334 11.6322L24.0419 10.3358C24.0255 10.1438 23.8449 9.99976 23.6479 9.99976H20.3641C20.1671 9.99976 19.9865 10.1438 19.9536 10.3358L19.7594 11.6508C19.6863 12.1457 19.3536 12.5562 18.9164 12.7993C18.4824 13.0408 17.9647 13.1212 17.5024 12.9395L16.2102 12.4318C16.0296 12.3678 15.8161 12.4318 15.7012 12.6078L14.0593 15.3758C13.9608 15.5518 13.9936 15.7598 14.1578 15.8878L15.2733 16.7389C15.66 17.034 15.849 17.5133 15.849 17.9998C15.849 18.4862 15.66 18.9655 15.2733 19.2606L14.1578 20.1118C14.0101 20.2398 13.9608 20.4478 14.0593 20.6238L15.7012 23.3918C15.7997 23.5678 16.0131 23.6318 16.2102 23.5678L17.5185 23.0537C17.9708 22.8759 18.4733 22.9553 18.8947 23.1972C19.3359 23.4506 19.6878 23.8639 19.7621 24.3673L19.9536 25.6638C19.9865 25.8558 20.1507 25.9998 20.3641 25.9998H23.6479C23.8449 25.9998 24.0255 25.8558 24.0583 25.6638L24.2547 24.3344C24.3265 23.8484 24.6582 23.4438 25.0869 23.2039C25.5197 22.9617 26.0326 22.8781 26.4946 23.0582L27.8018 23.5678C27.9824 23.6318 28.1959 23.5678 28.3108 23.3918L29.9527 20.6238C30.0512 20.4478 30.0019 20.2398 29.8541 20.1118L28.7254 19.2585ZM22.006 20.3998C20.6432 20.3998 19.5432 19.3278 19.5432 17.9998C19.5432 16.6718 20.6432 15.5998 22.006 15.5998C23.3687 15.5998 24.4688 16.6718 24.4688 17.9998C24.4688 19.3278 23.3687 20.3998 22.006 20.3998Z"
                  fill="#2EA8E5"
                />
              </svg>
            </div>
            <div style={{ margin: "0 0 15px" }}>
              <div>
                <Typography
                  category="Default"
                  variant="BodyBold"
                  htmlColor={colors.primary["1"]}
                >
                  {dateMarkup}
                </Typography>
              </div>
              <div style={{ padding: "6px 0px 3px" }}>
                <Typography
                  category="Default"
                  variant="H3"
                  htmlColor={colors.primary["1"]}
                >
                  {moment(start).tz(tz).format("HH:mm A")} -{" "}
                  {moment(end).tz(tz).format("HH:mm A")}
                </Typography>
              </div>
              <div>
                <Typography
                  category="Default"
                  variant="Caption"
                  htmlColor={colors.primary["1"]}
                >
                  ({start.format("HH:mm")} - {end.format("HH:mm")} UTC)
                </Typography>
              </div>
            </div>
          </div>
          <div>
            <div className={classes.contentBottom}>
              <div>
                <Typography
                  category="Default"
                  variant="Body"
                  htmlColor={colors.primary["1"]}
                >
                  {t("We apologize for_")}
                </Typography>
              </div>
              <div>
                <Typography
                  category="Default"
                  variant="Body"
                  htmlColor={colors.primary["1"]}
                >
                  {t("Thank you for_2")}
                </Typography>
              </div>
            </div>
          </div>
        </div>
      }
      heading=""
      close={close !== false}
      fullSize={mobile}
      actionClassName={classes.modalBtn}
    />
  );
};
