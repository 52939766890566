import { makeStyles, Theme } from '@material-ui/core';
import { Button, Typography } from '@thingsw/pitta-design-system';
import { LightColors, Webviewer } from '@thingsw/pitta-modules';
import clsx from 'clsx';
import React from 'react';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: '100vh',
    overflowY: 'auto',
  },
  bg: {
    width: '100%',
    height: '83vh',
    backgroundImage: `url('/images/fleeta-landing-bg.png')`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat ',
    backgroundPosition: 'center -80px',
    position: 'relative',
    [theme.breakpoints.down(Webviewer.mobile)]: {
      height: '640px',
    },
    [theme.breakpoints.down(1441)]: {
      backgroundPosition: 'center',
    },
  },
  logo: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: 64,
  },
  bgText: {
    position: 'absolute',
    bottom: 0,
  },
  paddingX: {
    [theme.breakpoints.up(1440)]: {
      padding: '0 234px',
    },
    [theme.breakpoints.down(1441)]: {
      padding: '0 86px',
    },
    [theme.breakpoints.down(969)]: {
      padding: '0 74px',
    },
    [theme.breakpoints.down(Webviewer.mobile)]: {
      padding: '0 32px',
    },
  },
  title: {
    color: 'white',
    fontSize: 48,
    fontWeight: 600,
    lineHeight: '56.25px',
    marginBottom: 4,
    [theme.breakpoints.down(Webviewer.mobile)]: {
      fontSize: 25,
      fontWeight: 500,
      lineHeight: '29.3px',
    },
  },
  sub: {
    color: 'white',
    fontSize: 22,
    fontWeight: 600,
    lineHeight: '22.78px',
    marginBottom: 8,
    [theme.breakpoints.down(Webviewer.mobile)]: {
      fontSize: 16,
      fontWeight: 500,
      lineHeight: '24px',
    },
  },
  bottomText: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: '17vh',
    [theme.breakpoints.down(Webviewer.mobile)]: {
      flexDirection: 'column',
      gap: 24,
      height: 'auto',
      marginTop: 32,
      marginBottom: 32,
    },
  },
}));

const FleetaLanding = () => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.bg}>
        {/* 상단 FLEETA 로고 */}
        <div className={classes.logo}>
          <svg
            width='210'
            height='32'
            viewBox='0 0 210 32'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <g clip-path='url(#clip0_3185_281696)'>
              <path
                d='M-0.000221602 1.17829C-0.000221602 0.845666 0.112534 0.569415 0.343683 0.338266C0.569193 0.112755 0.828531 0 1.1217 0H26.238C26.5706 0 26.8356 0.112755 27.0442 0.338266C27.2528 0.563777 27.3543 0.845666 27.3543 1.17829V3.10078C27.3543 3.4334 27.2528 3.70965 27.0442 3.93517C26.8356 4.16068 26.5706 4.27907 26.238 4.27907H4.83699V30.8837C4.83699 31.1712 4.72423 31.4306 4.49308 31.6617C4.26757 31.8872 4.00823 32.0056 3.71507 32.0056H1.11606C0.828531 32.0056 0.569193 31.8929 0.338045 31.6617C0.112534 31.4362 -0.00585938 31.1769 -0.00585938 30.8837V1.17829H-0.000221602ZM8.24784 15.876C8.24784 15.5433 8.3606 15.2784 8.59174 15.0698C8.81725 14.8612 9.09914 14.7597 9.42613 14.7597H24.2478C24.5805 14.7597 24.8567 14.8612 25.0879 15.0698C25.3134 15.2784 25.4261 15.5433 25.4261 15.876V17.7364C25.4261 18.0691 25.3134 18.3453 25.0879 18.5708C24.8624 18.7963 24.5805 18.9147 24.2478 18.9147H9.42613C9.09351 18.9147 8.81725 18.802 8.59174 18.5708C8.36623 18.3453 8.24784 18.0634 8.24784 17.7364V15.876Z'
                fill='white'
              />
              <path
                d='M35.5352 1.11628C35.5352 0.828753 35.6479 0.569415 35.8734 0.338266C36.0989 0.112755 36.3752 0 36.7022 0H39.211C39.538 0 39.8142 0.112755 40.0397 0.338266C40.2652 0.563777 40.378 0.823115 40.378 1.11628V27.7209H61.0292C61.3618 27.7209 61.638 27.8337 61.8636 28.0592C62.0891 28.2847 62.2075 28.5666 62.2075 28.8992V30.8217C62.2075 31.1543 62.0947 31.4306 61.8636 31.6561C61.638 31.8816 61.3562 32 61.0292 32H36.7022C36.3752 32 36.0989 31.8872 35.8734 31.6561C35.6479 31.4306 35.5352 31.1487 35.5352 30.8217V1.11628Z'
                fill='white'
              />
              <path
                d='M76.8355 27.7209H98.851C99.1385 27.7209 99.3979 27.8337 99.629 28.0592C99.8545 28.2847 99.9673 28.5666 99.9673 28.8992V30.8837C99.9673 31.1712 99.8545 31.4306 99.629 31.6617C99.4035 31.8872 99.1442 32.0056 98.851 32.0056H73.1766C72.8891 32.0056 72.6297 31.8929 72.3986 31.6617C72.1731 31.4362 72.0547 31.1769 72.0547 30.8837V1.17829C72.0547 0.845666 72.1674 0.569415 72.3986 0.338266C72.6297 0.107118 72.8834 0 73.1766 0H98.5409C98.8736 0 99.1498 0.112755 99.381 0.338266C99.6065 0.563777 99.7249 0.845666 99.7249 1.17829V3.16279C99.7249 3.45032 99.6121 3.70965 99.381 3.9408C99.1555 4.16631 98.8736 4.28471 98.5409 4.28471H76.8355V27.7266V27.7209ZM80.2464 14.3256C80.2464 13.993 80.3479 13.728 80.5564 13.5194C80.765 13.3108 81.03 13.2093 81.3627 13.2093H96.9285C97.2161 13.2093 97.4754 13.3108 97.7066 13.5194C97.9321 13.728 98.0448 13.993 98.0448 14.3256V16.2481C98.0448 16.5807 97.9321 16.8457 97.7066 17.0543C97.481 17.2629 97.2217 17.3643 96.9285 17.3643H81.3627C81.03 17.3643 80.765 17.2629 80.5564 17.0543C80.3479 16.8457 80.2464 16.5807 80.2464 16.2481V14.3256Z'
                fill='white'
              />
              <path
                d='M116.216 27.7209H138.232C138.519 27.7209 138.779 27.8337 139.01 28.0592C139.235 28.2847 139.348 28.5666 139.348 28.8992V30.8837C139.348 31.1712 139.235 31.4306 139.01 31.6617C138.784 31.8872 138.525 32.0056 138.232 32.0056H112.557C112.27 32.0056 112.011 31.8929 111.779 31.6617C111.554 31.4362 111.436 31.1769 111.436 30.8837V1.17829C111.436 0.845666 111.548 0.569415 111.779 0.338266C112.011 0.107118 112.264 0 112.557 0H137.922C138.254 0 138.531 0.112755 138.756 0.338266C138.982 0.563777 139.1 0.845666 139.1 1.17829V3.16279C139.1 3.45032 138.987 3.70965 138.756 3.9408C138.531 4.16631 138.249 4.28471 137.922 4.28471H116.216V27.7266V27.7209ZM119.627 14.3256C119.627 13.993 119.729 13.728 119.937 13.5194C120.146 13.3108 120.411 13.2093 120.744 13.2093H136.309C136.597 13.2093 136.856 13.3108 137.087 13.5194C137.313 13.728 137.431 13.993 137.431 14.3256V16.2481C137.431 16.5807 137.319 16.8457 137.087 17.0543C136.862 17.2629 136.603 17.3643 136.309 17.3643H120.744C120.411 17.3643 120.146 17.2629 119.937 17.0543C119.729 16.8457 119.627 16.5807 119.627 16.2481V14.3256Z'
                fill='white'
              />
              <path
                d='M144 1.11628C144 0.828753 144.101 0.569415 144.31 0.338266C144.519 0.112755 144.784 0 145.116 0H174.078C174.41 0 174.675 0.112755 174.884 0.338266C175.092 0.563777 175.194 0.823115 175.194 1.11628V3.10077C175.194 3.88443 174.822 4.27907 174.078 4.27907H145.116C144.784 4.27907 144.519 4.16631 144.31 3.93517C144.101 3.70965 144 3.42777 144 3.10077V1.11628ZM157.147 8.8062C157.147 8.47357 157.26 8.2086 157.486 8C157.711 7.7914 157.993 7.68992 158.326 7.68992H160.806C161.139 7.68992 161.404 7.7914 161.612 8C161.821 8.2086 161.922 8.47357 161.922 8.8062V30.8837C161.922 31.2163 161.821 31.4813 161.612 31.6899C161.404 31.8985 161.139 32 160.806 32H158.326C157.993 32 157.717 31.8985 157.486 31.6899C157.26 31.4813 157.147 31.2163 157.147 30.8837V8.8062Z'
                fill='white'
              />
              <path
                d='M209.736 31.0077C209.821 31.2558 209.86 31.4024 209.86 31.4419C209.86 31.814 209.652 32 209.24 32H205.767C205.434 32 205.186 31.8365 205.023 31.5039L201.488 24.124H186.604C186.317 24.124 186.17 23.9774 186.17 23.6899C186.17 23.5659 186.193 23.4644 186.232 23.3798L187.597 20.6512C187.76 20.2396 188.07 20.031 188.527 20.031H199.504L191.938 3.72093L178.418 31.5039C178.255 31.8365 178.007 32 177.674 32H174.573C174.162 32 173.953 31.814 173.953 31.4419C173.953 31.4024 173.993 31.2558 174.077 31.0077L188.775 1.17829C188.938 0.845666 189.175 0.569415 189.485 0.338266C189.795 0.112755 190.117 0 190.449 0H193.364C193.697 0 194.018 0.112755 194.328 0.338266C194.638 0.563777 194.875 0.845666 195.044 1.17829L209.742 31.0077H209.736Z'
                fill='white'
              />
            </g>
            <defs>
              <clipPath id='clip0_3185_281696'>
                <rect width='209.86' height='32' fill='white' />
              </clipPath>
            </defs>
          </svg>
        </div>
        {/* 이미지 내 문구 */}
        <div className={clsx(classes.bgText, classes.paddingX)}>
          <p className={classes.title}>{t('Meet FLEETA')}</p>
          <p className={classes.sub}>{t('FLEETA, Simplicity in Tracking')}</p>
          <Typography htmlColor={LightColors.primary['0']} variant='Body'>
            {t(
              "FLEETA is a complete fleet management solution providing comprehensive fleet monitoring and security through BlackVue's advanced dashcam technology"
            )}
          </Typography>
          <div
            style={{
              margin: '32px 0 40px 0',
            }}
          >
            <Button
              onClick={() => {
                window.location.href = 'https://fleeta.io/';
              }}
              variant='contained'
              style={{
                backgroundColor: '#61007D',
                width: 180,
                height: 48,
              }}
              endIcon={
                <svg
                  width='20'
                  height='24'
                  viewBox='0 0 20 24'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M8.32031 7L13.3203 12L8.32031 17L7.14844 15.8281L10.9766 12L7.14844 8.17188L8.32031 7Z'
                    fill='white'
                  />
                </svg>
              }
            >
              <Typography
                htmlColor={LightColors.primary['0']}
                variant='BodyBold'
              >
                {t('Visit FLEETA')}
              </Typography>
            </Button>
          </div>
        </div>
      </div>
      {/* 하단 여백 문구, 버튼 */}
      <div className={clsx(classes.paddingX, classes.bottomText)}>
        <div
          style={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            gap: 8,
            justifyContent: 'center',
          }}
        >
          <Typography variant='Body'>
            {t(
              "To learn more about BlackVue Cloud's consumer plans and features, click the button on the right."
            )}
          </Typography>
          <Typography variant='Small'>
            {t(
              'Note: Free, Lite and Smart Plan users can access all Cloud features through the BlackVue App.'
            )}
          </Typography>
        </div>
        <div>
          <Button
            onClick={() => {
              window.open(
                'https://www.blackvue.com/products-cloud/#cloud',
                '_blank'
              );
            }}
            endIcon={
              <svg
                width='20'
                height='25'
                viewBox='0 0 20 25'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M11.6797 5H17.5V10.8203H15.8203V7.85156L7.65625 16.0156L6.48437 14.8438L14.6484 6.67969H11.6797V5ZM15.8203 18.3203V12.5H17.5L17.5 18.3203C17.5 18.763 17.3307 19.1536 16.9922 19.4922C16.6536 19.8307 16.263 20 15.8203 20L4.17969 20C3.71094 20 3.30729 19.8438 2.96875 19.5312C2.65625 19.1927 2.5 18.7891 2.5 18.3203L2.5 6.67969C2.5 6.21094 2.65625 5.82031 2.96875 5.50781C3.30729 5.16927 3.71094 5 4.17969 5H10V6.67969H4.17969L4.17969 18.3203L15.8203 18.3203Z'
                  fill='#13131C'
                />
              </svg>
            }
            variant='outlined'
            style={{ width: 220, height: 48 }}
          >
            <Typography variant='BodyBold'>
              {t('About BlackVue Cloud')}
            </Typography>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default FleetaLanding;
