import { Typography } from '@thingsw/pitta-design-system';
import {
  BatteryChangeSettingResult,
  BatteryManualCommand,
  BatteryManualStatus,
  BatteryModel,
  DarkColors,
  ICameraInfo,
  LightColors,
  Webviewer,
} from '@thingsw/pitta-modules';
import React, { useEffect, useRef, useState } from 'react';
import TempStatus from './TempStatus';
import { useTranslation } from 'react-i18next';

import { Doughnut } from 'react-chartjs-2';
import { gaugeColor } from '../../utils/gaugeColor';

import { useMediaQuery } from '@material-ui/core';
import CloudIcon from '@material-ui/icons/Cloud';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Theme, makeStyles, useTheme } from '@material-ui/core';

import { DoughnutBackgroundImagePlugin } from '../../utils/ChartPlugin';
import { useDispatch, useSelector } from 'react-redux';
import { BATTERY, loadBatteryManual } from '../../features/Battery/slice';
import { RootState } from '../../features/store';
import BatteryChargingInfo from './BatteryChargingInfo';
import BatteryChargingStatusText from './BatteryChargingStatusText';
import BatteryAlert from './BatteryAlert';
import useCalculateBatteryPercent from '../../hooks/useCalculateBatteryPercent';
import { THEME } from '../../features/Theme/slice';

const useStyles = makeStyles((theme: Theme) => ({
  batteryPrimaryInfo: {
    padding: '12px 12px 28px 12px',
    backgroundColor: (props: any) => props.colors.primary['0'],
  },
  modelInfoDiv: {
    display: 'flex',
    alignItems: 'center',
    gap: 4,
    marginBottom: 8,
  },
  statusInfoDiv: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: 4,
  },
  smallBorder: {
    border: `1px solid ${DarkColors.primary['1']}`,
    borderRadius: 4,
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
  },
  infoDiv: {
    borderRadius: 12,
    border: `1px solid ${DarkColors.primary['1']}`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 12,
  },
  batteryChargingInfoDiv: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: 20,
  },
  batteryDoughnutDiv: {
    width: 316,
    height: 316,
    position: 'relative',
  },

  modal: {
    maxWidth: 311,
    padding: '24px 16px',
  },
  modalButton: {
    marginTop: 16,
    height: 48,
  },
  chargingCompleteDiv: {
    display: 'flex',
    justifyContent: 'center',
    gap: 6,
    height: 24,
    marginBottom: 20,
  },
  singleVoltage: {
    padding: 12,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: 48,
  },
  inputPowerCutOffAlert: {
    position: 'absolute',
    top: 22,
    left: 16,
    width: 284,
    height: 284,
    borderRadius: 142,
    border: `3px solid ${LightColors.secondary['11']}`,
  },
  dimSupportDiv: {
    position: 'fixed',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: 50,
    [theme.breakpoints.down(Webviewer.mobile)]: {
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
  },
  dimDiv: {
    position: 'absolute',
    top: 72,
    left: 251,
    width: 375,
    height: 'calc(100% - 160px)',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    borderRadius: 8,
    zIndex: 60,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    ...(theme.direction === 'rtl' ? { right: 251 } : { left: 251 }),
  },
}));

const BATTERY_CONNECTING_INTERVAL = 5 * 1000;
let BATTERY_DATA_UPDATE_INTERVAL = 30 * 1000;
if (process.env.NODE_ENV === 'development') {
  BATTERY_DATA_UPDATE_INTERVAL = 10 * 1000;
}

interface BatteryChargingStatusProps {
  currentCam: ICameraInfo | undefined;
  isCelcius: boolean;
  setIsCelcius: React.Dispatch<React.SetStateAction<boolean>>;
}

const preventEvent = (e: any) => {
  e.preventDefault();
};

const opts: AddEventListenerOptions & EventListenerOptions = {
  passive: false,
};

const BatteryChargingStatus = (props: BatteryChargingStatusProps) => {
  const { currentCam, isCelcius, setIsCelcius } = props;
  const theme = useTheme() as Theme;
  const mobile = useMediaQuery(theme.breakpoints.down(Webviewer.mobile));
  const dispatch = useDispatch();
  const { colors, color } = useSelector((state: RootState) => state[THEME]);
  const { batteryInfo, batterySettings, currentCam: loadedCam } = useSelector(
    (state: RootState) => state[BATTERY]
  );
  const { manualStatus } = batteryInfo;
  const classes = useStyles({ ...props, colors });
  const intervalRef = useRef<NodeJS.Timeout | null>(null);
  const [isBatteryManualStart, setIsBatteryManualStart] = useState(
    batteryInfo.manualStatus !== BatteryManualStatus.NONE
  );
  const { t } = useTranslation();

  const calculateBatteryPercent = useCalculateBatteryPercent({
    battery: currentCam?.battery,
    batteryInfo,
  });

  const currentCapacity = () => {
    if (batteryInfo.manualStatus === BatteryManualStatus.SUCCESS) {
      return batteryInfo.percent;
    }

    if (
      (currentCam?.battery.state === 'latest' ||
        currentCam?.battery.state === 'lateup1') &&
      isBatteryManualStart
    ) {
      return calculateBatteryPercent;
    }

    return null;
  };

  // 딤처리 시 스크롤 이벤트 차단
  useEffect(() => {
    if (
      !isBatteryManualStart ||
      (batteryInfo.loading &&
        batteryInfo.manualStatus === BatteryManualStatus.SUCCESS)
    ) {
      window.addEventListener('scroll', preventEvent, opts);
      window.addEventListener('touchmove', preventEvent, opts);
    } else {
      window.removeEventListener('scroll', preventEvent, opts);
      window.removeEventListener('touchmove', preventEvent, opts);
    }
  }, [batteryInfo.loading, batteryInfo.manualStatus, isBatteryManualStart]);

  useEffect(() => {
    if (
      (currentCam || loadedCam) &&
      batterySettings.changeSettingResult === BatteryChangeSettingResult.SUCCESS
    ) {
      dispatch(
        loadBatteryManual({
          camera: currentCam || loadedCam!,
          command: BatteryManualCommand.MANUAL,
        })
      );
    }
  }, [batterySettings.changeSettingResult, currentCam, dispatch, loadedCam]);

  const isLoading = batteryInfo.manualStatus !== BatteryManualStatus.SUCCESS;

  useEffect(() => {
    if (
      (!currentCam && !loadedCam) ||
      manualStatus === BatteryManualStatus.NONE
    ) {
      if (intervalRef.current) clearInterval(intervalRef.current);
      return;
    }

    if (manualStatus === BatteryManualStatus.START) {
      setIsBatteryManualStart(true);
    }

    // batteryManualStart 성공 후 5초마다 데이터 요청
    if (
      manualStatus !== BatteryManualStatus.SUCCESS &&
      manualStatus !== BatteryManualStatus.IN_USE
    ) {
      if (intervalRef.current) clearInterval(intervalRef.current);

      intervalRef.current = setInterval(() => {
        dispatch(
          loadBatteryManual({
            camera: currentCam || loadedCam!,
            command: BatteryManualCommand.MANUAL,
          })
        );
      }, BATTERY_CONNECTING_INTERVAL);
    }

    // 배터리 연결 완료 후 30초마다 데이터 요청
    if (manualStatus === BatteryManualStatus.SUCCESS) {
      if (intervalRef.current) clearInterval(intervalRef.current!);

      intervalRef.current = setInterval(() => {
        dispatch(
          loadBatteryManual({
            camera: currentCam || loadedCam!,
            command: BatteryManualCommand.MANUAL,
          })
        );
      }, BATTERY_DATA_UPDATE_INTERVAL);
    }

    if (
      batteryInfo.isConnectFail ||
      batteryInfo.isNotStarted ||
      batteryInfo.isPowerOff ||
      batteryInfo.manualStatus === BatteryManualStatus.IN_USE
    ) {
      if (intervalRef.current) clearInterval(intervalRef.current);
    }

    return () => {
      if (intervalRef.current) clearInterval(intervalRef.current);
    };
  }, [manualStatus, currentCam, dispatch, batteryInfo, loadedCam]);

  return (
    <div className={classes.batteryPrimaryInfo}>
      <BatteryAlert
        isCharging={batteryInfo.isCharging}
        isOutputPowerCutOff={batteryInfo.isOutputPowerCutOff}
        isHighTemperature={batteryInfo.isHighTemperature}
        isLowTemperature={batteryInfo.isLowTemperature}
        isLowCapacity={batteryInfo.isLowCapacity}
        isHighVoltage={batteryInfo.isHighVoltage}
        isLowVoltage={batteryInfo.isLowVoltage}
        isLoading={isLoading}
        isDisplayData={
          (currentCam?.battery.state === 'latest' ||
            currentCam?.battery.state === 'lateup1') &&
          isBatteryManualStart
            ? true
            : false
        }
      />
      <div className={classes.modelInfoDiv}>
        <CloudIcon htmlColor={colors.primary['7']} />
        <Typography
          style={{
            wordBreak: 'break-all',
            whiteSpace: 'nowrap',
            marginBottom: 4,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
          variant='BodyBold'
          htmlColor={colors.primary['1']}
        >
          {currentCam?.dev_name || loadedCam?.dev_name}
        </Typography>
      </div>
      <div className={classes.statusInfoDiv}>
        {/* USB 상태 마크 */}
        {batteryInfo.model !== BatteryModel.B124X &&
          batteryInfo.isUSBConnected && (
            <Typography
              className={classes.smallBorder}
              variant='Caption'
              htmlColor={colors.primary['1']}
            >
              USB
            </Typography>
          )}

        {/* 연결 방법 마크 */}
        {/* 12V DC, 24V DC, Fuse, Cigar */}
        {batteryInfo.model !== BatteryModel.B124X &&
          batteryInfo.isCharging &&
          !batteryInfo.isInputPowerCutOff &&
          batteryInfo.inputVoltage > 0 && (
            <Typography
              className={classes.smallBorder}
              variant='Caption'
              htmlColor={colors.primary['1']}
            >
              {batteryInfo.chargeSystem}/
              {batteryInfo.chargingCurrent >= 9000 ? 'Fuse' : 'Cigar'}
            </Typography>
          )}
        {/* 온도 마크 */}
        {batteryInfo.manualStatus === BatteryManualStatus.SUCCESS && (
          <div
            className={classes.smallBorder}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              gap: 2,
              cursor: 'pointer',
            }}
            onClick={() => setIsCelcius(!isCelcius)}
          >
            <TempStatus
              temp={batteryInfo.temperature}
              isCelcius={isCelcius}
              colors={colors}
            />
          </div>
        )}
      </div>
      <div className={classes.batteryChargingInfoDiv}>
        <div className={classes.batteryDoughnutDiv}>
          <BatteryChargingInfo
            isCharging={batteryInfo.isCharging}
            isFullCharge={batteryInfo.isFullCharge}
            isLessThanOnePercent={batteryInfo.isLessThanOnePercent}
            isInputPowerCutOff={batteryInfo.isInputPowerCutOff}
            isLoading={isLoading}
            remainingTime={batteryInfo.remainingTime}
            percent={batteryInfo.percent}
          />
          {/* 배터리 도넛 차트 */}
          <Doughnut
            type={'doughnut'}
            plugins={[
              DoughnutBackgroundImagePlugin(
                color === 'dark'
                  ? '/images/gauge-bg-dark.png'
                  : '/images/gauge-bg.png'
              ),
            ]}
            style={{
              transform: 'scaleX(-1)',
            }}
            options={{
              cutout: '72%',
              layout: {
                padding: 28,
              },
              animation: false
                ? {
                    duration: 500,
                    animationRotate: true,
                  }
                : false,
            }}
            data={(canvas: any) => {
              const ctx = canvas.getContext('2d');
              const bgGradient =
                color === 'dark'
                  ? ctx.createLinearGradient(255, 255, 255, 252)
                  : ctx.createLinearGradient(0, 0, 0, 252);
              const gradient = ctx.createRadialGradient(
                158,
                158,
                100,
                158,
                158,
                130
              );

              const { first, second } = gaugeColor(
                batteryInfo.percent,
                batteryInfo.isCharging && !batteryInfo.isInputPowerCutOff
                  ? 'charging'
                  : 'none'
              );

              gradient.addColorStop(0, first);
              gradient.addColorStop(1, second);

              return {
                datasets:
                  (batteryInfo.manualStatus === BatteryManualStatus.SUCCESS ||
                    currentCam?.battery.state === 'latest' ||
                    currentCam?.battery.state === 'lateup1') &&
                  isBatteryManualStart
                    ? [
                        {
                          data: [
                            currentCapacity() || 0,
                            100 - (currentCapacity() || 0),
                          ],
                          backgroundColor: [gradient, bgGradient],
                          borderWidth: 0,
                          borderRadius: [
                            currentCapacity() === 100 ? 0 : 100,
                            0,
                          ],
                        },
                      ]
                    : [],
              };
            }}
          />
          {/* 배터리 도넛 차트 얼럿 */}
          {(batteryInfo.isInputPowerCutOff ||
            batteryInfo.isHighTemperature ||
            batteryInfo.isOutputPowerCutOff) && (
            <div className={classes.inputPowerCutOffAlert}></div>
          )}
        </div>
      </div>
      {/* 충전/방전 상태 문구*/}
      <div className={classes.chargingCompleteDiv}>
        <BatteryChargingStatusText
          isCharging={batteryInfo.isCharging}
          isFullCharge={batteryInfo.isFullCharge}
          isLoading={isLoading}
          isLowBattery={batteryInfo.isLessFiveBatteryCapacity}
          isLowTemperature={batteryInfo.isLowTemperature}
          isInputPowerCutOff={batteryInfo.isInputPowerCutOff}
          isOutputPowerCutOff={batteryInfo.isOutputPowerCutOff}
          chargingTime={batteryInfo.chargingTime}
        />
      </div>
      <div>
        {/* 배터리 용량 */}
        <div
          className={classes.infoDiv}
          style={{
            height: 60,
            marginBottom: 12,
          }}
        >
          <Typography variant='Body' htmlColor={colors.primary['1']}>
            {t('Current Capacity')}
          </Typography>
          <Typography variant='H6' htmlColor={colors.primary['1']}>
            {currentCapacity() || '- - '}%
          </Typography>
        </div>

        {/* B130X 배터리 충전 중에는 충전 전압만 표시 */}
        {batteryInfo.model === BatteryModel.B130X &&
          batteryInfo.inputVoltage !== 0 &&
          !batteryInfo.isInputPowerCutOff && (
            <div className={classes.infoDiv}>
              <div className={classes.singleVoltage}>
                <Typography variant='Body' htmlColor={colors.primary['1']}>
                  {t('Charging Voltage')}
                </Typography>
                <div>
                  <Typography variant='H6' htmlColor={colors.primary['1']}>
                    {`${
                      batteryInfo.inputVoltage > 0
                        ? batteryInfo.inputVoltage
                        : '--'
                    } V `}
                  </Typography>
                  <Typography variant='Small' htmlColor={colors.primary['2']}>
                    /{' '}
                    {batteryInfo.chargingCurrent
                      ? (batteryInfo.chargingCurrent / 1000).toFixed(2) + ' Ah'
                      : '- Ah'}
                  </Typography>
                </div>
              </div>
            </div>
          )}

        {/* B130X 배터리 방전/대기 중에는 충전 전압/방전 전압 표시 */}
        {/* B130X 배터리 Input power cut off 상태일 때도 충전 전압/방전 전압 표시 */}

        {batteryInfo.model === BatteryModel.B130X &&
          (batteryInfo.isInputPowerCutOff ||
            batteryInfo.inputVoltage === 0) && (
            <div
              style={{
                display: 'flex',
                gap: 12,
                justifyContent: 'center',
                marginTop: 12,
              }}
            >
              <div
                className={classes.infoDiv}
                style={{
                  flexDirection: 'column',
                  flex: 1,
                  height: 84,
                  padding: 12,
                }}
              >
                <div
                  style={{
                    alignSelf: 'flex-start',
                  }}
                >
                  <Typography variant='Body' htmlColor={colors.primary['1']}>
                    {t('Charging Voltage')}
                  </Typography>
                </div>
                <div
                  style={{
                    alignSelf: 'flex-end',
                  }}
                >
                  <Typography variant='H6' htmlColor={colors.primary['1']}>
                    {`${
                      batteryInfo.inputVoltage > 0
                        ? batteryInfo.inputVoltage
                        : '--'
                    } V `}
                  </Typography>
                  <Typography variant='Small' htmlColor={colors.primary['2']}>
                    {`/ ${
                      batteryInfo.chargingCurrent > 0
                        ? (batteryInfo.chargingCurrent / 1000).toFixed(2)
                        : '-'
                    } Ah`}
                  </Typography>
                </div>
              </div>
              <div
                className={classes.infoDiv}
                style={{
                  flex: 1,
                  flexDirection: 'column',
                  height: 84,
                  padding: 12,
                }}
              >
                <div
                  style={{
                    alignSelf: 'flex-start',
                  }}
                >
                  <Typography variant='Body' htmlColor={colors.primary['1']}>
                    {t('Output Voltage')}
                  </Typography>
                </div>

                <div
                  style={{
                    alignSelf: 'flex-end',
                  }}
                >
                  <Typography variant='H6' htmlColor={colors.primary['1']}>
                    {`${
                      batteryInfo.outputVoltage > 0
                        ? batteryInfo.outputVoltage
                        : '--'
                    } V `}
                  </Typography>
                  <Typography variant='Small' htmlColor={colors.primary['2']}>
                    {`/ ${
                      batteryInfo.dischargeCurrent > 0
                        ? (batteryInfo.dischargeCurrent / 1000).toFixed(2)
                        : '-'
                    } Ah`}
                  </Typography>
                </div>
              </div>
            </div>
          )}

        {/* B124X 모델은 충전, 방전 정보 모두 표시 */}
        {batteryInfo.model === BatteryModel.B124X && (
          <div
            style={{
              display: 'flex',
              gap: 12,
              justifyContent: 'center',
              marginTop: 12,
            }}
          >
            <div
              className={classes.infoDiv}
              style={{
                flexDirection: 'column',
                flex: 1,
                height: 84,
                padding: 12,
              }}
            >
              <div
                style={{
                  alignSelf: 'flex-start',
                }}
              >
                <Typography variant='Body' htmlColor={colors.primary['1']}>
                  {t('Charging Voltage')}
                </Typography>
              </div>
              <div
                style={{
                  alignSelf: 'flex-end',
                }}
              >
                <Typography variant='H6' htmlColor={colors.primary['1']}>
                  {`${
                    batteryInfo.inputVoltage > 0
                      ? batteryInfo.inputVoltage
                      : '--'
                  } V `}
                </Typography>
                <Typography variant='Small' htmlColor={colors.primary['2']}>
                  {`/ ${
                    batteryInfo.chargingCurrent > 0
                      ? (batteryInfo.chargingCurrent / 1000).toFixed(2)
                      : '-'
                  } Ah`}
                </Typography>
              </div>
            </div>
            <div
              className={classes.infoDiv}
              style={{
                flex: 1,
                flexDirection: 'column',
                height: 84,
                padding: 12,
              }}
            >
              <div
                style={{
                  alignSelf: 'flex-start',
                }}
              >
                <Typography variant='Body' htmlColor={colors.primary['1']}>
                  {t('Output Voltage')}
                </Typography>
              </div>

              <div
                style={{
                  alignSelf: 'flex-end',
                }}
              >
                <Typography variant='H6' htmlColor={colors.primary['1']}>
                  {`${
                    batteryInfo.outputVoltage > 0
                      ? batteryInfo.outputVoltage
                      : '--'
                  } V `}
                </Typography>
                <Typography variant='Small' htmlColor={colors.primary['2']}>
                  {`/ ${
                    batteryInfo.dischargeCurrent > 0
                      ? (batteryInfo.dischargeCurrent / 1000).toFixed(2)
                      : '-'
                  } Ah`}
                </Typography>
              </div>
            </div>
          </div>
        )}
      </div>
      {/* 배터리 수동 연결 start용 딤 */}
      {!isBatteryManualStart && !mobile && (
        <div className={classes.dimSupportDiv}>
          <div className={classes.dimDiv}></div>
        </div>
      )}
      {!isBatteryManualStart && mobile && (
        <div className={classes.dimSupportDiv}></div>
      )}
      {/* 배터리 수동 연결 데이터 로딩용 딤&스피너 */}
      {batteryInfo.loading &&
        batteryInfo.manualStatus === BatteryManualStatus.SUCCESS &&
        !mobile && (
          <div className={classes.dimSupportDiv}>
            <div className={classes.dimDiv}>
              <CircularProgress />
            </div>
          </div>
        )}
      {batteryInfo.loading &&
        batteryInfo.manualStatus === BatteryManualStatus.SUCCESS &&
        mobile && (
          <div className={classes.dimSupportDiv}>
            <CircularProgress />
          </div>
        )}
    </div>
  );
};

export default BatteryChargingStatus;
