import { jwtAxiosInst } from "@thingsw/pitta-modules";

/* DMS Report Api 통합 수정 (2024.05.07 hongcs) */
// export const getMonthlyReport = (
//   email: string,
//   userToken: string,
//   psn: string,
//   startTime: string,
//   endTime: string
// ) => {
//   return axios.get(
//     `${API_GATEWAY_URI}/Stats/GetDrivingAvgPerDay?email=${email}&userToken=${userToken}&tokenType=web&psn=${psn}&startTime=${startTime}&endTime=${endTime}`
//   );
// };

// export const getDailyReport = (
//   email: string,
//   userToken: string,
//   psn: string,
//   startTime: string,
//   endTime: string
// ) => {
//   return axios.get(
//     `${API_GATEWAY_URI}/Stats/GetDrivingAvgPerHour?email=${email}&userToken=${userToken}&tokenType=web&psn=${psn}&startTime=${startTime}&endTime=${endTime}`
//   );
// };

// export const getMonthlyScore = (
//   email: string,
//   userToken: string,
//   psn: string,
//   month: number
// ) => {
//   return axios.get(
//     `${API_GATEWAY_URI}/Stats/GetDrivingMonthlyReport?email=${email}&userToken=${userToken}&tokenType=web&psn=${psn}&month=${month}`
//   );
// };

// export const getDailyLog = (
//   email: string,
//   userToken: string,
//   psn: string,
//   startTime: string,
//   endTime: string
// ) => {
//   return jwtAxiosInst.get(
//     `/BCS/dailyLogGet.php?email=${email}&user_token=${userToken}&psn_list=${psn}&start_date=${startTime}&end_date=${endTime}&token_type=web&date_type=utc`
//   );
// };

export const getDrivingReport = (
  email: string,
  userToken: string,
  psn: string,
  date: string,
  interval: number,
  period: string
) => {
  return jwtAxiosInst.get(
    `/report/dms?email=${email}&psn=${psn}&date=${date}&interval=${interval}&period=${period}&user_token=${userToken}`
  );
};
